import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/models/usuario';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-usuario-list',
  templateUrl: './usuario-list.component.html',
  styleUrls: ['./usuario-list.component.css']
})

export class UsuarioListComponent implements OnInit {

  usuarios: Usuario[];
  name = '';
  public loading:boolean = false;

  constructor(
    private usuarioService: UsuarioService, 
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.readUsuarios();
  }

  readUsuarios(): void {

    this.loading = true;
    this.usuarioService.listAll()
      .subscribe(
        usuarios => {
          this.loading = false;
          this.usuarios = usuarios;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao listar Usuário!'); 
        });
  }

  refresh(): void {
    this.readUsuarios();
  }

  editCurrentUsuario(usuario): void {
    this.router.navigateByUrl('/usuario/form/' + usuario.id);
  }

  delete(usuarios): void {
    this.deleteCurrentUsuario(usuarios);
    this.usuarios = this.usuarios.filter(item => item.id != usuarios.id);
  }

  deleteCurrentUsuario(usuario): void {

    this.loading = true;
    this.usuarioService.delete(usuario.id)
    .subscribe(
      retorno => {
        this.toastr.success('Usuário Excluído!', 'Sucesso!');
        this.readUsuarios();
      },
      error => {
        this.loading = false;
        console.log(error);
        this.toastr.error(error.message, 'Erro ao excluir Usuário!'); 
      });
  }

  createUsuario(): void {
    this.router.navigateByUrl('/usuario/form');
  }



}
