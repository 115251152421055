import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '@services/layout.service';
import { Layout } from 'src/app/models/layout';
import { ToastrService } from 'ngx-toastr';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-layout-list',
  templateUrl: './layout-list.component.html',
  styleUrls: ['./layout-list.component.css']
})
export class LayoutListComponent implements OnInit {

  layoutsRest: Layout[];
  layouts: Layout[];
  layoutId:string;
  sucesso:boolean;
  filtroForm: UntypedFormGroup;
  loading:boolean = false;

  constructor(
    private layoutService:LayoutService,
    private router: Router,
    private formBuilder : UntypedFormBuilder,
    private toastr: ToastrService) {
      this.filtroForm = new UntypedFormGroup({
        filtro: new UntypedFormControl(null, null)
      });
    }

  deParaTipo(id:number | null):string {
    if (id) {
      switch (id) {
        case 1:
          return "PDF";
        case 2:
          return "JPEG";
        case 3:
          return "HTML";
            }
    }
    return "";
  }

  ngOnInit(): void {
    this.readLayout();
  }

  createLayout(): void {
    this.router.navigateByUrl('/layout/form');
  }

  readLayout(): void {

    this.loading = true;
    this.router.navigateByUrl('/layout/list');
    this.layoutService.listAllAdm()
      .subscribe(
        layouts => {
          this.loading = false;
          this.layouts = layouts;
          this.layoutsRest = layouts;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao listar Layout!');
        });
  }

  executaFiltro(): void {
    if (this.filtroForm.get('filtro').value == "") {
      this.layouts = this.layoutsRest;
    } else {
      this.layouts = [];
      this.layoutsRest.forEach(element => {
        console.log(element.empNomeFantasia);
        if (element.empNomeFantasia != null &&
          element.empNomeFantasia.toUpperCase().indexOf(this.filtroForm.get('filtro').value.toUpperCase()) >=0 ) {
          this.layouts.push(element);
        }
      });
    }
  }

  editLayout(layout): void {
    this.router.navigateByUrl('/layout/form/' + layout.id);
  }

  delete(layout): void {
    this.deleteCurrentLayout(layout);
    this.layouts = this.layouts.filter(item => item.id != layout.id);
  }

  deleteCurrentLayout(layout): void {

    this.loading = true;
    this.layoutService.deleteAdm(layout.id)
    .subscribe(
      retorno => {
        this.toastr.success('Layout Excluído!', 'Sucesso!');
        this.readLayout();
      },
      error => {
        this.loading = false;
        console.log(error);
        this.toastr.error(error.message, 'Erro ao excluir Layout!');
      });
  }



}
