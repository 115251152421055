<div>
    <nz-page-header class="site-page-header" nzTitle="Definição de Produtos" nzSubtitle="Upload de arquivo de produtos">
    </nz-page-header>

    <div class="acompanhamento">
      <div class="acompanhamento__header">
        <div class="acompanhamento__infos">
          <div class="acompanhamento__left">
            <p class="acompanhamento__title">Nome da Campanha: <span class="acompanhamento__description">{{ cmpNome }}</span></p>
            <p class="acompanhamento__title">Nome da Loja: <span class="acompanhamento__description">{{ lojNomeFantasia }}</span></p>
            <p class="acompanhamento__title">Nome do Encarte: <span class="acompanhamento__description">{{encDescricao}}</span></p>
            <p class="acompanhamento__title">Data/Hora Limite: <span class="acompanhamento__description">{{cmpDataProduto | date: 'dd/MM/yyyy HH:mm'}}</span></p>
          </div>
        </div>
      </div>
    </div>
      <ng-container class="edit-form"  #customLoadingTemplate>
        <form nz-form [formGroup]="form">
            <div *ngIf="campanhaencarteId">
                    <nz-card>
                                <input
                                    type="file"
                                    (change)="uploadArquivoXlx($event.target.files, $event)"
                                    style="margin-bottom: 1rem; border-style:none; display:none"
                                    id="excel"
                                    class="custom-file-input"
                                    accept=".csv">
                    
                                    <label *ngIf="!form.value.cmaArquivo" class="labelButtonUpload" for="excel">
                                        Upload Arquivo CSV
                                    </label>
                                    <label *ngIf="form.value.cmaArquivo" class="labelButtonUploadUpdate" for="excel">
                                        {{nameFileUpload}}
                                    </label>
                                  &nbsp;
                                <button nz-button nzType="primary" 
                                  class="button__custom" style="height: 49px;"
                                    (click)="downloadData()"
                                    >
                                  <span>Download</span>
                                </button>


                                    <div>
                                        <div class="labelButtonUploadMessage">
                                            * Arquivo deve ser criado com separação de ponto-e-vírgula
                                        </div>
                                        <div class="labelButtonUploadMessage">
                                            * Arquivo deve ser exportado no Excel como CSV UTF-8
                                        </div>
                                    </div>
                    </nz-card>
            </div>

            <nz-modal (nzOnCancel)="handleOk()" [nzClosable]="true" nzWidth="100%" [(nzVisible)]="modalProductsIsVisible"
            nzTitle="Produtos da campanha" (nzOnOk)="handleOk()">
            <ng-container *nzModalContent>
              <nz-table  [nzFrontPagination]="false"
              [nzShowPagination]="false" [nzData]="arquivoFormArray.controls" #rowSelectionTable [nzScroll]="{ y:'240px' }"
                id='table-select'>
                <thead>
                  <th style="width: 40px;" class="title" nzLeft="0px">&nbsp;</th>
                  <ng-container *ngFor="let coluna of cabecalho.colunas;let i = index;">
                  <th style="width: 250px;"  class="title" *ngIf="i <= 1" [nzLeft]="(40 + i*250) + 'px'">
                    <b>
                      {{coluna.conteudo.replace("_", " ")}}
                    </b>
                  </th>
                  <th style="width: 250px;"  class="title" *ngIf="i > 1">
                    <b>
                      {{coluna.conteudo.replace("_", " ")}}
                    </b>
                  </th>
                  </ng-container>
                </thead>
                <tbody *ngFor="let linha of arquivoFormArray.controls;let i = index;">
                  <tr tabindex="1">
                    <td style="width: 40px;" nzLeft="0px">
                      <i
                          (click)="sobeLinha(i)"
                          *ngIf="i > 0"
                          class="iconUpOrder"
                          nz-icon nzType="caret-up"
                          nzTheme="outline"
                          nz-tooltip nzTooltipTitle="Enviar para cima"
                          nzTooltipPlacement="bottomRight">
                      </i>

                      <i
                          (click)="desceLinha(i)"
                          *ngIf="i < arquivoFormArray.controls.length - 1"
                          class="iconUpOrder"
                          nz-icon nzType="caret-down"
                          nzTheme="outline"
                          nz-tooltip nzTooltipTitle="Enviar para baixo"
                          nzTooltipPlacement="bottomRight">
                      </i>
                    </td>
                    <ng-container *ngFor="let coluna of formGroupToArray(linha);let j = index">
                    <td style="width: 250px;" *ngIf="j <= 1" [nzLeft]="(40 + j*250) + 'px'">
                      <form nz-form [formGroup]="linha">
                        <img style="max-width:75px;max-height:75px"  [src]="imagemArray[i]" *ngIf="cabecalho.colunas[j].conteudo.toUpperCase() == 'FOTO' && imagemArray[i] != null" >
                        <input *ngIf="cabecalho.colunas[j].conteudo.toUpperCase() == 'FOTO'"
                        (blur)="atualizaListaImagem($event, i)"
                        nz-input
                        formControlName="col_{{j}}"
                         />
                         <input *ngIf="cabecalho.colunas[j].conteudo.toUpperCase() != 'FOTO'"
                         nz-input
                         formControlName="col_{{j}}"
                          />
                       </form>
                    </td>
                    <td style="width: 250px;" *ngIf="j > 1">
                      <form nz-form [formGroup]="linha">
                        <img style="max-width:75px;max-height:75px"  [src]="imagemArray[i]" *ngIf="cabecalho.colunas[j].conteudo.toUpperCase() == 'FOTO' && imagemArray[i] != null" >
                        <input *ngIf="cabecalho.colunas[j].conteudo.toUpperCase() == 'FOTO'"
                        (blur)="atualizaListaImagem($event, i)"
                        nz-input
                        formControlName="col_{{j}}"
                         />
                         <input *ngIf="cabecalho.colunas[j].conteudo.toUpperCase() != 'FOTO'"
                         nz-input
                         formControlName="col_{{j}}"
                          />
                       </form>
                    </td>

                  </ng-container>

                  </tr>
                </tbody>
              </nz-table>
            </ng-container>
            <div *nzModalFooter>
              <button class="button__custom__modal" nz-button nzType="primary" (click)="handleSalvarArquivo()">Salvar</button>&nbsp;
              <button class="button__custom__modal" nz-button nzType="cancel" (click)="handleOk()">Fechar</button>
            </div>
          </nz-modal>
          <nz-modal (nzOnCancel)="handleOk()" [nzClosable]="true" nzWidth="100%" [(nzVisible)]="modalLogsIsVisible"
                nzTitle="Histórico da Campanha" (nzOnOk)="handleOkLog()">
                <ng-container *nzModalContent>
                  <nz-table [nzData]="campanhaLogs" #rowSelectionTable [nzScroll]="{ x: 95 * linhas[0]?.colunas.length + 'px' }"
                    id='table-select'>
                    <thead>
                      <th  class="title title_tm">
                        <b>
                         Data
                        </b>
                      </th>
                      <th  class="title title_tm">
                        <b>
                         Operação
                        </b>
                      </th>
                      <th  class="title title_desc">
                        <b>
                         Usuário
                        </b>
                      </th>
                      <th  class="title title_desc">
                        <b>
                         Comentários
                        </b>
                      </th>
                    <tbody>
                      <tr *ngFor="let logs of rowSelectionTable.data" tabindex="1">
                        <td>{{logs.celData | date: 'dd/MM/YYYY HH:MM'}}</td>
                        <td>{{tipOperacaos[logs.celOperacao] }}</td>
                        <td style="text-align:left">{{logs.celUsuNome}}</td>
                        <td style="text-align:left">{{logs.celDetalhe}}</td>
                      </tr>
                    </tbody>
                  </nz-table>
                </ng-container>
                <div *nzModalFooter>
                  <button class="button__custom" nz-button nzType="primary" (click)="handleOkLog()">OK</button>
                </div>
              </nz-modal>

<br>
<div align="right">
  <button class="form-button" nz-button nzType="link" (click)="cancelar()">Cancelar</button>&nbsp;
  <span style="border-style: solid;border-width: 1px;border-color:red; padding:7px">
                <a nz-popconfirm style="color:red;" nzPopconfirmTitle="Deseja excluir essa campanha?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="deleteCampanha()">Excluir</a>
  </span>
                &nbsp;
              <button class="form-button" nz-button nzType="primary" 
                  (click)="enviaArquivosDownload()">Gerar&nbsp;Arquivo</button>&nbsp;
                  <button nz-button nzType="primary"
                  class="button__custom"
                  (click)="atualizaImagens()"
                  >
              <span>Atualiza imagens</span>
              </button>&nbsp;
              <button nz-button nzType="primary"
                  class="button__custom"
                  (click)="getArquivoJson()"
                  >
              <span>Ver produtos</span>
              </button>&nbsp;
              <button nz-button nzType="primary"
                  class="button__custom"
                  (click)="readCampanhaLog()"
                  >
              <span>Ver histórico</span>
              </button>&nbsp;
              <button class="form-button" nz-button nzType="primary" (click)="aprovar()">Enviar para Aprovação</button>&nbsp;
              <ng-container *ngIf="form.value.cmaArquivo">
              <button nz-button nzType="primary"
                      class="ButtonDefault ButtonDefaultSendPDF"
                      (click)="enviaArquivosUpload()">
                          Upload
              </button>&nbsp;
            </ng-container>

              </div>
        </form>
    </ng-container>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

</div>
