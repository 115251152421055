import { Component, OnInit } from '@angular/core';
import { GrupoUsuarioService } from 'src/app/services/grupousuario.service';
import { GrupoUsuario } from 'src/app/models/grupousuario';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-grupousuario-list',
  templateUrl: './grupousuario-list.component.html',
  styleUrls: ['./grupousuario-list.component.css']
})

export class GrupoUsuarioListComponent implements OnInit {

  grupousuarios: GrupoUsuario[];

  public loading:boolean = false;

  constructor(
    private grupousuarioService: GrupoUsuarioService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.readGrupoUsuarios();

  }


  readGrupoUsuarios(): void {

    this.loading = true;
    this.grupousuarioService.listAll()
      .subscribe(
        grupousuarios => {
          this.loading = false;
          this.grupousuarios = grupousuarios;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao enviar!');
        });
  }

  refresh(): void {
    this.readGrupoUsuarios();
  }

  editCurrentGrupoUsuario(grupousuario): void {
    this.router.navigateByUrl('/grupousuario/form/' + grupousuario.id);
  }

  delete(grupousuarios): void {
    this.deleteCurrentGrupoUsuario(grupousuarios);
    this.grupousuarios = this.grupousuarios.filter(item => item.id != grupousuarios.id);
  }

  deleteCurrentGrupoUsuario(grupousuario): void {

    this.loading = true;
    this.grupousuarioService.delete(grupousuario.id)
    .subscribe(
      retorno => {
        if (retorno.error)
          this.toastr.error(retorno.message, 'Erro ao Excluir!');
        this.toastr.success('Grupo de usuário excluído!', 'Sucesso!');
        this.readGrupoUsuarios();
      },
      error => {
        this.loading = false;
        console.log(error);
      });
  }

  createGrupoUsuario(): void {
    this.router.navigateByUrl('/grupousuario/form');
  }



}
