<nz-page-header class="site-page-header" nzTitle="Encartes" nzSubtitle="Encartes cadastrados">
</nz-page-header>
<ng-container  #customLoadingTemplate>
    <form nz-form [formGroup]="form">
        <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
            <nz-form-label [nzSpan]="5">Buscar</nz-form-label>
            <nz-form-control [nzSpan]="20">
                <input
                        style="width: 100%;"
                        nz-input
                        formControlName="filtro"
                        name="filtro"
                        (ngModelChange)="buscar()"
                        />
            </nz-form-control>
            <nz-form-control [nzSpan]="5">
              <label nz-checkbox (ngModelChange)="buscarArquivado()" formControlName="arquivado">
                  Arquivado
                </label>
              </nz-form-control>
        </nz-form-item>
    </form>

    <nz-table [nzData]="encartes" #rowSelectionTable>
        <thead>
            <tr>
                <th width="60%" nzShowSort="true" [nzSortFn]="sortColunaEncDescricao">Nome do Encarte</th>
                <th width="20%" nzShowSort="true" [nzSortFn]="sortColunaSlaId">Mídia</th>
                <th width="150px"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let encarte of rowSelectionTable.data;">
                <td>{{encarte.encDescricao}}</td>
                <td>{{deParaTipo(encarte.slaId)}}</td>
                <td>
                    <a (click)="editCurrentEncarte(encarte)">Editar</a>&nbsp;
                    <a nz-popconfirm nzPopconfirmTitle="Deseja remover esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="delete(encarte)">Remover</a>&nbsp;
                    <a nz-popconfirm nzPopconfirmTitle="Deseja arquivar esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="arquivar(encarte)">Arquivar</a>
                </td>
            </tr>
        </tbody>
    </nz-table>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</ng-container>
<br>
<p align="right">
    <button class="form-button" nz-button nzType="primary" (click)="createEncarte()">Novo</button>
</p>

<!-- <td>
                <a (click)="editCurrentEncarte(departamento)">Editar</a>&nbsp;
                <a nz-popconfirm nzPopconfirmTitle="Deseja remover esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="deleteCurrentEncarte(departamento)">Remover</a>
            </td> -->
