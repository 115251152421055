import { Component, OnInit } from '@angular/core';
import { LojaService } from 'src/app/services/loja.service';
import { Loja } from 'src/app/models/loja';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-loja-list',
  templateUrl: './loja-list.component.html',
  styleUrls: ['./loja-list.component.css']
})

export class LojaListComponent implements OnInit {

  lojas: Loja[];

  public loading:boolean = false;

  constructor(
    private lojaService: LojaService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.readLojas();

  }


  readLojas(): void {

    this.loading = true;
    this.lojaService.listAtivas()
      .subscribe(
        lojas => {
          this.loading = false;
          this.lojas = lojas;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao enviar!');
        });
  }

  refresh(): void {
    this.readLojas();
  }

  editCurrentLoja(loja): void {
    this.router.navigateByUrl('/loja/form/' + loja.id);
  }

  delete(lojas): void {
    this.deleteCurrentLoja(lojas);
    this.lojas = this.lojas.filter(item => item.id != lojas.id);
  }

  deleteCurrentLoja(loja): void {

    this.loading = true;
    this.lojaService.delete(loja.id)
    .subscribe(
      retorno => {
        this.readLojas();
        this.toastr.success('Loja Excluída!', 'Sucesso!');
      },
      error => {
        this.loading = false;
        console.log(error);
      });
  }

  createLoja(): void {
    this.router.navigateByUrl('/loja/form');
  }



}
