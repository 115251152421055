<div>
    <nz-page-header class="site-page-header" nzTitle="Alterar a senha" nzSubtitle="Preencha com sua nova senha">
    </nz-page-header>
    <ng-container class="edit-form" #customLoadingTemplate>
        <form *ngIf="!sucesso" nz-form [formGroup]="form">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Senha atual</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroSenhaAtual">
                    <input nz-input type="password" formControlName="senhaAtual" name="senhaAtual" size="32" />
                    <ng-template #erroSenhaAtual let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Nova senha</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroSenha">
                    <input nz-input type="password" formControlName="password" name="password" size="32" />
                    <ng-template #erroSenha let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('complexity')">
                            A senha deve conter letras maiúsculas, miniscúlas, números e no mínimo 8 caracteres<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Confirme a senha</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroSenha2">
                    <input nz-input type="password" formControlName="password2" name="password2" size="32" />
                    <ng-template #erroSenha2 let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('confirm')">
                            Confirme com a mesma senha informada<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <p align="right">
                <label style="color:#ff4d4f">{{ passwordError }}</label> &nbsp;
                <button class="form-button" [disabled]="!form.valid" nz-button nzType="primary" (click)="atualiza()">Alterar</button>
            </p>
        </form>
        <nz-result
            *ngIf="sucesso"
            nzStatus="success"
            nzTitle="Senha atualizada com sucesso"
        >
        </nz-result>
    </ng-container>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>