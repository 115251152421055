<div>
    <nz-page-header class="site-page-header" nzTitle="Alterar" nzSubtitle="Tipo da Página">
    </nz-page-header>
    <ng-container class="edit-form"  #customLoadingTemplate>
        <form nz-form [formGroup]="form">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <input nz-input type="text" formControlName="tpgDescricao" name="tpgDescricao" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Midia</nz-form-label>
                      <nz-form-control [nzSpan]="12" >
                        <nz-select
                            formControlName="slaId"
                            nzPlaceHolder="Tipo Midia"
                            style="width:50%"
                            name="slaId">
                            <nz-option
                            *ngFor="let items of tipoMidia" [nzValue]="items.id"
                                [nzLabel]="items.descricao">
                            </nz-option>
                        </nz-select>
                      </nz-form-control>
                  </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Altura</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <input nz-input type="number" formControlName="tpgAltura" name="tpgAltura" /> {{form.get("slaId").value == 1 ? "mm" : "px"}}
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Largura</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <input nz-input type="text" formControlName="tpgLargura" name="tpgLargura" /> {{form.get("slaId").value == 1 ? "mm" : "px"}}
                </nz-form-control>
            </nz-form-item>
    
            <p align="right">
                <button class="form-button" nz-button nzType="link" (click)="cancelar()">Cancelar</button>
                <button class="form-button" [disabled]="!form.valid" nz-button nzType="primary" (click)="salvarTipoPagina()">Salvar</button>
            </p>
        </form>
        <nz-result
            *ngIf="sucesso"
            nzStatus="success"
            nzTitle="Senha atualizada com sucesso"
        >
        </nz-result>
    </ng-container>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

</div>