import { Component, OnInit } from '@angular/core';
import { SetorService } from 'src/app/services/setor.service';
import { Setor } from 'src/app/models/setor';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-setor-list',
  templateUrl: './setor-list.component.html',
  styleUrls: ['./setor-list.component.css']
})

export class SetorListComponent implements OnInit {

  setores: Setor[];

  public loading:boolean = false;

  constructor(
    private setorService: SetorService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.readSetores();

  }


  readSetores(): void {

    this.loading = true;
    this.setorService.listAll()
      .subscribe(
        setores => {
          this.loading = false;
          this.setores = setores;
          console.log({"dps":this.setores});
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao enviar!');
        });
  }

  refresh(): void {
    this.readSetores();
  }

  editCurrentSetor(setor): void {
    this.router.navigateByUrl('/setor/form/' + setor.id);
  }

  delete(setores): void {
    this.deleteCurrentSetor(setores);
    this.setores = this.setores.filter(item => item.id != setores.id);
  }

  deleteCurrentSetor(setor): void {

    this.loading = true;
    this.setorService.delete(setor.id)
    .subscribe(
      retorno => {
        this.readSetores();
        this.toastr.success('Setor Excluído!', 'Sucesso!');
      },
      error => {
        this.loading = false;
        console.log(error);
      });
  }

  createSetor(): void {
    this.router.navigateByUrl('/setor/form');
  }



}
