import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Encarte } from '@models/encarte';
import { ConfigFiles } from '@models/config_files';
import { map, catchError } from 'rxjs/operators';

const baseURL = `${environment.api_url}/empresa/v1/encarte`;

@Injectable({
  providedIn: 'root'
})
export class EncarteService {

  constructor(private http: HttpClient){}

  listAll(): Observable<Encarte[]> {
    return this.http.get<Encarte[]>(`${baseURL}/lista`);
  }

  listArquivado(): Observable<Encarte[]> {
    return this.http.get<Encarte[]>(`${baseURL}/lista-arquivado`);
  }


  listPorTipo(tipo : number): Observable<Encarte[]> {
    return this.http.get<Encarte[]>(`${baseURL}/lista-tipo/${tipo}`);
  }


  read(id: string): Observable<Encarte> {
    return this.http.get<Encarte>(`${baseURL}/recupera/${id}`);
  }

  pdfBlob(): Observable<any> {
    return this.http.get(`${baseURL}/downloadPdf/`, {responseType:'blob'})
  }


  pdfJson(id: string): Observable<any> {
    return this.http.get(`${baseURL}/pdf-campanha/${id}`)
  }


  pdfJsonPreview(id: string): Observable<any> {
    return this.http.get(`${baseURL}/pdf-campanha-preview/${id}`)
  }

  create(data: Encarte): Observable<any> {
    return this.http.post(`${baseURL}/novo`, data);
  }

  update(data: Partial<Encarte>): Observable<any> {
    return this.http.put(`${baseURL}/atualiza`, data);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${baseURL}/remove/${id}`);
  }

  arquivar(id: string): Observable<any> {
    return this.http.put(`${baseURL}/arquivar/${id}`, null);
  }

}
