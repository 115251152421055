import { Component, OnInit } from '@angular/core';
import { EmpresaService } from 'src/app/services/empresa.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Empresa } from 'src/app/models/empresa';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-empresa-form',
  templateUrl: './admin-empresa-form.component.html',
  styleUrls: ['./admin-empresa-form.component.css']
})
export class EmpresaFormComponent implements OnInit {
  empresaId = null;
  empRazaoSocial = null;
  empNomeFantasia = null;
  empCNPJMatriz = null;
  empNumeroMatriz = null;
  empComplementoMatriz = null;
  logCidade = null;
  empRua = null;
  logUF = null;
  logId = null;
  empDDDMatriz = null;
  empNumTelefoneMatriz = null;
  empStatus = null;

  form = new UntypedFormGroup({
    empLoginSimplus: new UntypedFormControl(null,[Validators.maxLength(128)]),
    empSenhaSimplus: new UntypedFormControl(null,[Validators.maxLength(128)]),
  });

  public loading:boolean = false;

  constructor(
    private empresaService: EmpresaService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') != undefined)
      this.getEmpresa(this.route.snapshot.paramMap.get('id'));
  }

  getEmpresa(id): void {

    this.loading = true;
    this.empresaService.read(id)
      .subscribe(
        empresa => {
          this.empresaId = empresa.id;
          this.empRazaoSocial = empresa.empRazaoSocial;
          this.empNomeFantasia = empresa.empNomeFantasia;
          this.empCNPJMatriz = empresa.empCNPJMatriz;
          this.empRua = empresa.empRua;
          this.empNumeroMatriz = empresa.empNumeroMatriz;
          this.empComplementoMatriz = empresa.empComplementoMatriz;
          this.logCidade = empresa.logCidade;
          this.logUF = empresa.logUF;
          this.logId = empresa.logId;
          this.empDDDMatriz = empresa.empDDDMatriz;
          this.empNumTelefoneMatriz = empresa.empNumTelefoneMatriz;
          this.empStatus = empresa.empStatus;
          this.form.get("empLoginSimplus").setValue(empresa.empLoginSimplus);
          this.form.get("empSenhaSimplus").setValue(empresa.empSenhaSimplus);
          this.loading = false;

        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }



  cancelar(): void {
    if (this.empStatus == 'P')
      this.router.navigateByUrl('/empresa/list/inativas');
    else
      this.router.navigateByUrl('/empresa/list/ativas');
  }


ativarEmpresa(): void {

  this.loading = true;
  this.empresaService.ativa(this.empresaId)
    .subscribe(
      response => {
        this.loading = false;
        if (this.empStatus == 'P')
          this.router.navigateByUrl('/empresa/list/inativas');
        else
          this.router.navigateByUrl('/empresa/list/ativas');
      },
      error => {
        this.loading = false;
        console.log(error);
      });

  }

  salvar(): void {

    var data = {
      id: this.empresaId,
      empLoginSimplus: this.form.get("empLoginSimplus").value,
      empSenhaSimplus: this.form.get("empSenhaSimplus").value,
    }

    this.loading = true;
    this.empresaService.salvarSimplus(data)
      .subscribe(
        response => {
          this.loading = false;
          this.router.navigateByUrl('/empresa/list/ativas');
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  
    }
    

  inativarEmpresa(): void {

    this.loading = true;
    this.empresaService.inativa(this.empresaId)
      .subscribe(
        response => {
          this.loading = false;
          if (this.empStatus == 'P')
            this.router.navigateByUrl('/empresa/list/inativas');
          else
            this.router.navigateByUrl('/empresa/list/ativas');
        },
        error => {
          this.loading = false;
          console.log(error);
        });

    }



}
