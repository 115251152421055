import { Component, OnInit } from '@angular/core';
import { SvgEncarte } from '@models/svg-encarte';
import { FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,  Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SvgService } from '@services/svg.service';

@Component({
  selector: 'app-encart-svg-list',
  templateUrl: './encart-svg-list.component.html',
  styleUrls: ['./encart-svg-list.component.css']
})
export class EncartSvgListComponent implements OnInit {
  public listaEncartes:Array<SvgEncarte> = [];
  listOfPages:Array<String> = [];
  public loading:boolean = false;
  isVisible:boolean = false;
  paginaAtual:number = 0;

  encartePreFiltro: any[];

  millimeters_in_an_inch:number = 25.4;
  default_dpi:number = 100;
  getScreenWidth: any;
  getScreenHeight: any;

  form: UntypedFormGroup;
  formBusca: UntypedFormGroup;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private http: HttpClient,
    private SvgService:SvgService,
  ) { }

  ngOnInit(): void 
  {
    this.form = this.formBuilder.group({
      pageSize: new UntypedFormControl(null),
      setPageSize: new UntypedFormControl(null),
      stpNome: new UntypedFormControl(null, [Validators.required]),
    });

    this.formBusca = this.formBuilder.group({
      filtro:null,
      arquivado:false
    });

    this.getEncarteList();
  }

  arquivar(encarte): void 
  {
    this.arquivarCurrentEncarte(encarte);
    this.listaEncartes = this.listaEncartes.filter(item => item.id != encarte.id);
  }

  arquivarCurrentEncarte(encarte): void 
  {
    this.loading = true;

    this.SvgService.arquivarEncarte(encarte.id)
    .subscribe(
      retorno => 
      {
        this.loading = false;
        if (retorno.error)
          this.toastr.error(retorno.message, 'Erro ao Arquivar!');
        else
          this.toastr.success('Encarte Arquivado!', 'Sucesso!');
        this.getEncarteList();
      },
      error => 
      {
        this.loading = false;
        this.toastr.error(error.message, 'Erro ao Arquivar!');
        this.getEncarteList();
      });
  }


  buscar(): void 
  {
    if (this.listaEncartes == null)
      this.listaEncartes = this.encartePreFiltro;

    var filtro = this.formBusca.get('filtro').value;
   
    if (filtro == null)
      filtro = "";

    this.listaEncartes = this.encartePreFiltro.filter(item => item.senDescricao.toUpperCase().includes(filtro.toUpperCase()));
  }


  buscarArquivado(): void 
  {
    this.getEncarteList();
  }

  getEncarteList()
  {
    this.loading = true;
    if (!this.formBusca.get("arquivado").value) {
      this.SvgService.listaEncartes().subscribe(
      {
        next:(res:any) => 
        {
          this.listaEncartes = res;
          this.encartePreFiltro = res;
          this.buscar();
          this.loading = false;
        },
        error:(error) =>
        {
          this.loading = false;
          this.toastr.error(error.message, 'Erro ao Listar!');
        }
      })
    }
    else
    {
      this.SvgService.listEncartesArquivados().subscribe(
      {
        next:(res:any) => 
        {
          this.listaEncartes = res;
          this.encartePreFiltro = res;
          this.buscar();
          this.loading = false;
        },
        error:(error) =>
        {
          this.loading = false;
          this.toastr.error(error.message, 'Erro ao Listar!');
        }
      })
    }
  }

  editEncarte(id_encarte:string): void 
  {
    this.router.navigateByUrl('/svg/encarte/' + id_encarte);
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  deleteEncarte(id_encarte:string): void 
  {
    this.loading = true;
    this.SvgService.excluirEncarte(id_encarte).subscribe(
    {
      next:(res) =>
      {
        this.toastr.success('Card excluído com sucesso');
        this.getEncarteList();
      },
      error:(error) =>
      {
        this.toastr.error('Erro ao excluir card');
        this.loading = false;
      }
    })
  }

  criarEncarte(): void 
  {
    this.isVisible = true;
  }

  calcDpi()
  {
    let dpi;
    if (window.matchMedia("(min-resolution: 10dpi)").matches) {
      for(var i=650; i>=1; i = (i-1)) {
        if (window.matchMedia("(min-resolution: " + i + "dpi)").matches) {
          dpi = i;
          break;
        }
      }
    }
    dpi = 150;
    return dpi;
  }

  conversorMedidasPxAndMm(value:number, type:string = 'px')
  {
    let converter = (type === 'px') ? Math.round(value*(this.calcDpi()/this.millimeters_in_an_inch)) : Math.round(value/(this.calcDpi()/this.millimeters_in_an_inch));
    //let converter = (type === 'px') ? Math.round(value*(this.default_dpi/this.millimeters_in_an_inch)) : Math.round(value/(this.default_dpi/this.millimeters_in_an_inch));
    return converter;
  }

  setSizes(width:number | null = null, height:number | null = null, convertSize:boolean = false):void
  {
    if(convertSize)
    {
      let w = this.conversorMedidasPxAndMm(width);
      let h = this.conversorMedidasPxAndMm(height);
      this.getScreenWidth = (w) ? w : h;
      this.getScreenHeight = (h) ? h : w;
    }
  }


  save():void
  {
    let sizes!:any;

    if(this.form.get('pageSize').value.toLowerCase().includes('x'))
    {
      sizes = this.form.get('pageSize').value.toLowerCase().split('x');

      if(sizes.length > 0)
      {
        let error = false;
        sizes.forEach((n =>
        {
          let medida = parseInt(n)
          if(isNaN(medida))
          {
            let msg = (n !== '') ? `O ${n} não é um valor de medida válido.` : 'Por favor, informe a medida que falta.'
            this.toastr.error(msg);
            error = true;
            return;
          }
        }))
        
        if(error)
          return;
      }
      else
      {
        this.toastr.error(`Por favor, informe a medida do encarte.`);
      }

    }
    else
    {
      this.toastr.error('Utilize o "x" para separar largura e altura');
      return;
    }

    this.setSizes(sizes[0], sizes[1], true);

    let page = `<svg width="${this.getScreenWidth}" height="${this.getScreenHeight}" dpi="${this.calcDpi()}"
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlns:svg="http://www.w3.org/2000/svg" 
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <!-- Created with SVG-edit - https://github.com/SVG-Edit/svgedit-->
                  <g class="layer">
                    <title>${this.form.get('stpNome').value}</title>
                  </g>
                </svg>`;

    /*let page = `<svg width="${this.getScreenWidth}" height="${this.getScreenHeight}" dpi="${this.default_dpi}"
                xmlns="http://www.w3.org/2000/svg" 
                xmlns:svg="http://www.w3.org/2000/svg" 
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <!-- Created with SVG-edit - https://github.com/SVG-Edit/svgedit-->
                <g class="layer">
                  <title>${this.form.get('stpNome').value}</title>
                </g>
              </svg>`;*/

    this.listOfPages[this.paginaAtual] = page;
    let paginas = JSON.stringify(this.listOfPages);

    let data:SvgEncarte = 
    {
      id: null,
      senDescricao: this.form.get('stpNome').value,
      sarId:null, //implementar essa parte. 
      senConteudo:paginas
    }

    let service = this.SvgService.criaEncarte(data);
    this.loading = true;

    service.subscribe(
    {
      next: (res) =>
      {
        let msg = 'Encarte criado com sucesso';
        
        if(res.id)
        {
          this.router.navigateByUrl('/svg/encarte/' + res.id);
        }
        
        this.toastr.success(msg);
        this.loading = false;
      },
      error: (error) =>
      {
        let msg = 'Erro ao criar encarte.';
        this.toastr.error(msg);
        this.loading = false;
      }
    })
  }

}
