import { Component, OnInit } from '@angular/core';
import { GrupoUsuarioService } from 'src/app/services/grupousuario.service';
import { PerfilService } from 'src/app/services/perfil.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GrupoUsuario } from 'src/app/models/grupousuario';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-grupousuario-form',
  templateUrl: './grupousuario-form.component.html',
  styleUrls: ['./grupousuario-form.component.css']
})
export class GrupoUsuarioFormComponent implements OnInit {

  grupousuarioId = null;
  perfis = null;

  form = new UntypedFormGroup({
    gusNome: new UntypedFormControl(null, [Validators.required,  Validators.maxLength(64), Validators.minLength(5)])
  });

  public loading:boolean = false;

  constructor(
    private grupousuarioService: GrupoUsuarioService,
    private perfilService: PerfilService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') != undefined)
      this.getGrupoUsuario(this.route.snapshot.paramMap.get('id'));
    else
      this.readPerfil(null);


  }

  getGrupoUsuario(id): void {

    this.loading = true;
    this.grupousuarioService.read(id)
      .subscribe(
        grupousuario => {
          this.loading = false;
          this.grupousuarioId = grupousuario.id;
          this.form.get('gusNome').setValue(grupousuario.gusNome);
          this.readPerfil(grupousuario.perfis);
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao enviar!');
        });
  }



  cancelar(): void {
    this.router.navigateByUrl('/grupousuario/list');
  }


salvarGrupoUsuario(): void {

  var gus = new GrupoUsuario();
  gus.gusNome = this.form.get('gusNome').value;

  var perfisSelected = new Array();
  this.perfis.forEach(item => {
    if (this.form.get(item.name).value == true) {
      perfisSelected.push({ id: item.aenId });
    }
  });
  gus.perfis = perfisSelected;


  if (this.grupousuarioId != null) {
    gus.id = this.grupousuarioId;

    this.loading = true;
    this.grupousuarioService.update(gus)
      .subscribe(
        response => {
          this.loading = false;
          this.toastr.success('Grupo de usuario Salvo!', 'Gravado com Sucesso!');
          this.router.navigateByUrl('/grupousuario/list');
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao enviar!');
        });
    } else {
      this.grupousuarioService.create(gus)
      .subscribe(
        response => {
          this.loading = false;
          this.toastr.success('Grupo de usuario Salvo!', 'Gravado com Sucesso!');
          this.router.navigateByUrl('/grupousuario/list');
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao enviar!');
        });
    }

  }



  readPerfil(perfisAtivos): void {

    this.loading = true;
    this.perfilService.listAll()
      .subscribe(
        perfis => {
          this.loading = false;
          var i = 1;

          var checkPerfis = new Array;

          perfis.forEach(perfil => {
            var found = false;
            if (perfisAtivos != null) {
              perfisAtivos.forEach(perfilAtivo => {
                if (perfil.id == perfilAtivo.id) {
                  found = true;
                }
              });
            }
            var item = { name: 'formPerfil' + (i++), aenDescricao: perfil.aenDescricao, aenId: perfil.id, checked: found };
            var formControl = new UntypedFormControl(item.checked, null);
            this.form.addControl(item.name, formControl);
            checkPerfis.push(item);
          });

          this.perfis = checkPerfis;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao buscar Perfis!');
        });
  }
}
