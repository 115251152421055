
<div *ngIf="!esqueciSenha">
  <div class="login">
    <div class="login__title">Login</div>
    <form nz-form [formGroup]="form" class="login__body">
      <div class="login__left">
        <nz-form-item>
          <nz-form-label>Email</nz-form-label>
          <nz-form-control [nzErrorTip]="erroUsername">
            <input class="login__input" nz-input formControlName="username" name="username" />
            <ng-template #erroUsername let-control>
              <ng-container *ngIf="control.hasError('required')">
                Campo obrigatório<br>
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label>Senha</nz-form-label>
          <nz-form-control [nzErrorTip]="erroSenha">
            <input class="login__input" nz-input type="password" formControlName="password" name="password" />
            <ng-template #erroSenha let-control>
              <ng-container *ngIf="control.hasError('required')">
                Campo obrigatório<br>
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <button class="login__button" type="submit" nz-button nzDanger (click)="doLogin()">Entrar</button>
      </div>
      <div class="login__right">
        <p>
          Ainda não possui uma conta?<br /><a href="/registro" style="color: red; font-weight: bold;">Clique aqui</a>
          para se cadastrar.
        </p>
        <button style="display: block; padding-left: 0; color: red; font-weight: bold;" nzType="link" nz-button
          (click)="forgot()">
          Esqueci minha senha
        </button>
      </div>
    </form>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>




<nz-result class="result" *ngIf="esqueciSenha" nzStatus="success" nzTitle="Enviamos o link para reset de senha. Verifique seu email.">
</nz-result>
