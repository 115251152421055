import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arquivo-svg-list',
  templateUrl: './arquivo-svg-list.component.html',
  styleUrls: ['./arquivo-svg-list.component.css']
})
export class ArquivoSvgListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
