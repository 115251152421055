import { Component, OnInit } from '@angular/core';
import { ImagemService } from 'src/app/services/imagem.service';
import { Imagem } from 'src/app/models/imagem';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-imagem-list',
  templateUrl: './imagem-list.component.html',
  styleUrls: ['./imagem-list.component.css']
})

export class ImagemListComponent implements OnInit {

  imagens: Imagem[];
  imagensPreFiltro: Imagem[];
  public imagemForm: UntypedFormGroup;

  public loading:boolean = false;

  constructor(
    private imagemService: ImagemService,
    private router: Router,
    private modal: NzModalService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService) {

      this.imagemForm = this.formBuilder.group({
        base64Zip: null,
        filtro:null,
        removerFundo:false
      });

    }

  ngOnInit(): void {

    this.readImagens();

  }


  readImagens(): void {
    this.imagens = [];
    this.loading = true;
    this.imagemService.listAll()
      .subscribe(
        imagens => {
          this.imagens = imagens;
          this.loading = false;

          imagens.forEach( i => {
            this.verCurrentImagemList(i);
          })

        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  buscar(): void {
    if (this.imagensPreFiltro == null)
      this.imagensPreFiltro = this.imagens;

    var nome = this.imagemForm.get('filtro').value;
    if (nome == null)
      nome = "";
    this.imagens = this.imagensPreFiltro.filter(item => item.imgNome.toUpperCase().includes(nome.toUpperCase()));
  }

  refresh(): void {
    this.readImagens();
  }

  verCurrentImagem(imagem): void {
    this.imagemService.read(imagem.imgNome, 0)
      .subscribe(
        response => {
          this.modal.info(
            {
              nzTitle:response.imgNome,
              nzContent:"<img class=\"imgContentCss\" src=\"" + response.imgConteudo + "\">",
              nzWidth:700
            }


          );
          //this.modalIsVisible = true;
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao buscar Imagem!');
        });

  }


  verCurrentImagemList(imagem): void {

    if (imagem.base64Zip != null)
      return imagem.base64Zip;
    imagem.base64Zip = "";

    this.imagemService.read(imagem.imgNome, 1)
      .subscribe(
        response => {
          imagem.base64Zip = response.imgConteudo;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }


  removeImagem(imagem): void {
    this.loading = true;
    this.imagemService.delete(imagem.imgNome)
      .subscribe(
        response => {
          this.imagens = this.imagens.filter(item => item.imgNome != imagem.imgNome);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao buscar Imagem!');
        });
  }


    uploadArquivoZip (files:any) {

      this.loading = true;

      const uploaded = files[0];

      const reader = new FileReader();
      reader.onload = () => {

        var imagem = new Imagem();
        //this.imagemForm.get("base64Zip").setValue(reader.result)
        //imagem.base64Zip = this.imagemForm.get('base64Zip').value;

        imagem.base64Zip = reader.result;

        this.imagemService.upload(imagem, this.imagemForm.get('removerFundo').value)
          .subscribe(
            response => {
              this.loading = false;
              this.toastr.success('Arquivo ZIP', 'Enviado com sucesso!');
              this.refresh();
            },
            error => {
              this.loading = false;
              console.log(error);
              this.toastr.error(error.message, 'Erro ao enviar ZIP!');
            });

      };

      reader.readAsDataURL(uploaded);
    }


    sortColunaImgNome(a : any, b : any) : boolean {
      return (a.imgNome.localeCompare(b.imgNome));
    }


}
