import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EncarteService } from '@services/encarte.service';
import { Encarte } from 'src/app/models/encarte';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-encarte-list',
  templateUrl: './encarte-list.component.html',
  styleUrls: ['./encarte-list.component.css']
})

export class EncarteListComponent implements OnInit {

  encartes: Encarte[];
  encartesPreFiltro: Encarte[];
  public loading:boolean = false;
  public form: UntypedFormGroup;

  constructor(
    private encarteService:EncarteService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService) {

      this.form = this.formBuilder.group({
        filtro:null,
        arquivado:false
      });

    }

  ngOnInit(): void {
    this.readEncartes();
  }


  deParaTipo(id:number | null):string {
    if (id) {
      switch (id) {
        case 1:
          return "PDF";
        case 2:
          return "JPEG";
        case 3:
          return "HTML";
            }
    }
    return "";
  }

  buscar(): void {
    console.log(this.encartesPreFiltro)
    if (this.encartes == null)
      this.encartes = this.encartesPreFiltro;

    var filtro = this.form.get('filtro').value;
    if (filtro == null)
      filtro = "";
    this.encartes = this.encartesPreFiltro.filter(item => item.encDescricao.toUpperCase().includes(filtro.toUpperCase()));
  }

  buscarArquivado(): void {
    this.readEncartes();
  }

  readEncartes(): void {
    this.loading = true;
    if (!this.form.get("arquivado").value) {
      this.encarteService.listAll()
        .subscribe(
          encartes => {
            this.loading = false;
            this.encartesPreFiltro = encartes;
            this.encartes = encartes;
            this.buscar();
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao Listar!');
          });
    } else {
      this.encarteService.listArquivado()
        .subscribe(
          encartes => {
            this.loading = false;
            this.encartesPreFiltro = encartes;
            this.encartes = encartes;
            this.buscar();
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao Listar!');
          });

    }
  }

  createEncarte(): void {
    this.router.navigateByUrl('/encarte/form/');
  }

  editCurrentEncarte(encarte): void {
    this.router.navigateByUrl('/encarte/form/' + encarte.id);
  }

  delete(encartes): void {
    this.deleteCurrentEncarte(encartes);
    this.encartes = this.encartes.filter(item => item.id != encartes.id);
  }

  arquivar(encartes): void {
    this.arquivarCurrentEncarte(encartes);
    this.encartes = this.encartes.filter(item => item.id != encartes.id);
  }


  deleteCurrentEncarte(encarte): void {

    this.loading = true;
    this.encarteService.delete(encarte.id)
    .subscribe(
      retorno => {
        this.loading = false;
        if (retorno.error)
          this.toastr.error(retorno.message, 'Erro ao Excluir!');
        else
          this.toastr.success('Encarte Excluído!', 'Sucesso!');
        this.readEncartes();
      },
      error => {
        this.loading = false;
        console.log(error);
        this.toastr.error(error.message, 'Erro ao Excluir!');
        this.readEncartes();
      });
  }

  arquivarCurrentEncarte(encarte): void {

    this.loading = true;
    this.encarteService.arquivar(encarte.id)
    .subscribe(
      retorno => {
        this.loading = false;
        if (retorno.error)
          this.toastr.error(retorno.message, 'Erro ao Arquivar!');
        else
          this.toastr.success('Encarte Arquivado!', 'Sucesso!');
        this.readEncartes();
      },
      error => {
        this.loading = false;
        console.log(error);
        this.toastr.error(error.message, 'Erro ao Arquivar!');
        this.readEncartes();
      });

  }

  sortColunaEncDescricao(a : any, b : any)  {
    return (a.encDescricao.localeCompare(b.encDescricao));
  }


  sortColunaSlaId(a : any, b : any)  {
    return b.slaId - a.slaId;
  }


}
