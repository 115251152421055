import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Loja } from '@models/loja';

const baseURLEmpresa = `${environment.api_url}/empresa/v1/loja`;
const baseURLAdmin = `${environment.api_url}/admin/empresa/v1/loja`;

@Injectable({
  providedIn: 'root'
})
export class LojaService {

  constructor(private httpClient: HttpClient) { }

  listAtivas(): Observable<Loja[]> {
    return this.httpClient.get<Loja[]>(`${baseURLEmpresa}/listaAtiva`);
  }

  read(id: string): Observable<Loja> {
    return this.httpClient.get<Loja>(`${baseURLEmpresa}/recupera/${id}`);
  }

  create(data: Loja): Observable<any> {
    console.info(data);
    return this.httpClient.post(`${baseURLEmpresa}/novo`, data);
  }
  // create(data: Omit<Loja, 'id'>): Observable<any> {
  //   return this.httpClient.post(`${baseURLEmpresa}/novo`, data);
  // }

  update(data: Partial<Loja>): Observable<any> {


    console.info(data);
    return this.httpClient.put(`${baseURLEmpresa}/atualiza`, data);
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${baseURLEmpresa}/remove/${id}`);
  }
}
