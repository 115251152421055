<nz-page-header class="site-page-header" nzTitle="Loja" nzSubtitle="Lojas cadastradas">
</nz-page-header>
<div>
    <ng-container #customLoadingTemplate>
        <div>
            <nz-table [nzData]="lojas" #rowSelectionTable>
                <thead>
                    <tr>
                        <th>Nome da Loja</th>
                        <th width="150px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let loja of rowSelectionTable.data;">
                        <td>{{loja.lojNomeFantasia}}</td>
                        <td>
                            <a (click)="editCurrentLoja(loja)">Editar</a>&nbsp;
                            <a nz-popconfirm nzPopconfirmTitle="Deseja remover esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="delete(loja)">Remover</a>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </ng-container>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<br>
<p align="right">
    <button class="form-button" nz-button nzType="primary" (click)="createLoja()">Novo</button>
</p>
