import { EncarteService } from '@services/encarte.service';
import { LojaService } from 'src/app/services/loja.service';
import { Component, OnInit } from '@angular/core';
import { CampanhaService } from 'src/app/services/campanha.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidationErrors, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { Campanha } from 'src/app/models/campanha';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-campanha-form',
  templateUrl: './campanha-form.component.html',
  styleUrls: ['./campanha-form.component.css'],
})

export class CampanhaFormComponent implements OnInit {
  campanhas: Campanha[];
  cmpId = null;
  campanha =null;
  loja = null;
  encarte = null;

  public loading:boolean = false;
  public campanhaForm: UntypedFormGroup;

  tipoMidia = [
    {"id":1, "descricao": "PDF"},
    {"id":2, "descricao": "JPEG"},
    {"id":3, "descricao": "HTML"}
  ];

  constructor(
    private campanhaService: CampanhaService,
    private lojaService: LojaService,
    private encarteService: EncarteService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router) {

      this.campanhaForm = this.formBuilder.group({
          id:null,
          cmpNome: new UntypedFormControl(null,[Validators.required,Validators.maxLength(128)]),
          cmpDataInicio: new UntypedFormControl(null,[Validators.required]),
          cmpDataFim: new UntypedFormControl(null,[Validators.required]),
          cmpDataProduto: new UntypedFormControl(null,[Validators.required]),
          cmpDataGerencia: new UntypedFormControl(null,[Validators.required]),
          cmpDataDiretoria: new UntypedFormControl(null,[Validators.required]),
          cmpDataImpressao: new UntypedFormControl(null,[Validators.required]),
          cmpAprovarGrupo: new UntypedFormControl(null,[Validators.required]),
          //campanhasEncarte: this.formBuilder.array([this.formBuilder.group({lojId: '', encId:'', cmeQuantidade:''})])
          slaId: new UntypedFormControl(null,[Validators.required]),

          campanhasEncarte: this.formBuilder.array([this.newCampanhaEncarte()])

      });

    }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') != undefined)
      this.getCampanha(this.route.snapshot.paramMap.get('id'));

    for (var f in this.campanhaForm.controls) {
      this.campanhaForm.controls[f].markAsTouched();
    }
    this.readLoja();
    //this.readEncarte();

  }

  get campanhaencarteArray(): UntypedFormArray{
    return <UntypedFormArray>this.campanhaForm.get('campanhasEncarte');
  }

  isEdit() {
    return this.campanhaForm.get('id').value != null;
  }

  duplicar() {
    this.cmpId = null;
    this.campanhaForm.get('id').setValue(null);
    this.campanhaForm.get('cmpNome').setValue("Cópia de " + this.campanhaForm.get('cmpNome').value);
    this.toastr.success('Campanha duplicada!', 'Sucesso');
    this.campanhaForm.get('cmpDataInicio').setValue(null);
    this.campanhaForm.get('cmpDataFim').setValue(null);
    this.campanhaForm.get('cmpDataProduto').setValue(null);
    this.campanhaForm.get('cmpDataGerencia').setValue(null);
    this.campanhaForm.get('cmpDataDiretoria').setValue(null);
    this.campanhaForm.get('cmpDataImpressao').setValue(null);
    this.campanhaForm.get('cmpAprovarGrupo').setValue(null);

  }

   getCampanha(id): void {

    this.loading = true;
    this.campanhaService.read(id)
      .subscribe(
        campanha => {
          this.loading = false;
          this.readCampanhaJsonToForm(campanha)


        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao recuperar Campanha!');
        });
  }
  private newCampanhaEncarte(): UntypedFormGroup{
    return this.formBuilder.group({id:null, lojId: '', encId:'', cmeQuantidade:''});
  }
  readCampanhaJsonToForm(campanha): void{
    this.cmpId = campanha.id;
    this.campanhaForm.get('id').setValue(campanha.id);
    this.campanhaForm.get('cmpNome').setValue(campanha.cmpNome);
    this.campanhaForm.get('slaId').setValue(campanha.slaId);
    this.campanhaForm.get('cmpDataInicio').setValue(campanha.cmpDataInicio);
    this.campanhaForm.get('cmpDataFim').setValue(campanha.cmpDataFim);
    this.campanhaForm.get('cmpDataProduto').setValue(campanha.cmpDataProduto);
    this.campanhaForm.get('cmpDataGerencia').setValue(campanha.cmpDataGerencia);
    this.campanhaForm.get('cmpDataDiretoria').setValue(campanha.cmpDataDiretoria);
    this.campanhaForm.get('cmpDataImpressao').setValue(campanha.cmpDataImpressao);
    this.campanhaForm.get('cmpAprovarGrupo').setValue(campanha.cmpAprovarGrupo);
    this.campanhaencarteArray.removeAt(0);

    campanha.campanhasEncarte && campanha.campanhasEncarte
    .map((item) => {
      this.campanhaencarteArray.push(
        <UntypedFormGroup>this.formBuilder.group({
          id: item.id,
          encId: item.encId,
          lojId: item.lojId,
          cmpId: item.cmpId,
          cmeQuantidade: item.cmeQuantidade,
        })
      )
    })

  }

  deleteCampanhaEncarte(indexCampanhaEncarte): void{
    this.campanhaencarteArray.removeAt(indexCampanhaEncarte);
  }
  addCampanhaEncarte(): void{
    this.campanhaencarteArray.push(this.newCampanhaEncarte());
  }

  cancelar(): void {
    this.router.navigateByUrl('campanha/list');
  }

  readLoja():void{
    this.loading=true;
    this.lojaService.listAtivas()
    .subscribe(
      loja =>{
        this.loading=false;
        this.loja = loja;
      },
      error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao buscar Lojas!');
          });
  }

  filtraEncarte(id:number) {

    this.readEncarte(id);

    this.campanhaencarteArray.controls.forEach(element => {
      element.get("encId").setValue(null);
    });

  }

  readEncarte(id:number):void{
    this.loading=true;
    this.encarteService.listPorTipo(id)
    .subscribe(
      encarte =>{
        this.loading=false;
        this.encarte = encarte;
      },
      error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao buscar Encartes!');
          });
  }

  salvarCampanha(): void {
    this.loading = true;

    if (this.cmpId != null) {

      this.campanhaService.update(this.campanhaForm.value)
        .subscribe(
          response => {
            this.loading = false;
            this.router.navigateByUrl('/campanha/list');
            this.toastr.success('Campanha Salva!', 'Gravada com Sucesso!');
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao salvar Campanha!');

          });

    } else {

      this.campanhaService.create(this.campanhaForm.value)
      .subscribe(
        response => {
          this.loading = false;
          this.toastr.success('Campanha Salva!', 'Gravada com Sucesso!');
          this.router.navigateByUrl('/campanha/list');
        },
         error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao salvar Campanha!');
        });
    }
  }
}
