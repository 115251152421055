import { Encarte } from './../models/encarte';
import { Linha } from './../models/linha';
import { Arquivo } from './../models/arquivo';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { CampanhaEncarte } from '@models/campanhaencarte';
import { ConfigFiles } from '@models/config_files';
import { map, catchError } from 'rxjs/operators';

const baseURL = `${environment.api_url}/empresa/v1/campanha-encarte`;


@Injectable({
  providedIn: 'root'
})
export class CampanhaEncarteService {

  constructor(private http: HttpClient) { }

  uploadCsv(body: any): Observable<any> {
    return this.http.post(`${baseURL}/upload/`, body)
  }

  visualizarCsv(id: string): Observable<CampanhaEncarte> {
    return this.http.get<CampanhaEncarte>(`${baseURL}/visualizar-arquivo/${id}`);
  }

  atualizaImagens(id: string): Observable<any> {
    return this.http.get<any>(`${baseURL}/atualiza-imagens/${id}`);
  }


  visualizarCsvTable(id: string): Observable<Arquivo> {
    return this.http.get<Arquivo>(`${baseURL}/visualizar-arquivo/${id}`);
  }

  visualizarCSVEncarte(id: string): Observable<Arquivo> {
    return this.http.get<Arquivo>(`${baseURL}/visualizar-arquivo-encarte/${id}`);
  }

  visualizarCsvTableColuna(id: string): Observable<Linha> {
    return this.http.get<Linha>(`${baseURL}/visualizar-arquivo/${id}`);
  }

  read(id: string): Observable<CampanhaEncarte> {
    return this.http.get<CampanhaEncarte>(`${baseURL}/recupera/${id}`);
  }

  listAll(): Observable<CampanhaEncarte[]> {
    return this.http.get<CampanhaEncarte[]>(`${baseURL}/lista`);
  }

  download(id: string): Observable<any> {
    return this.http.get<any>(`${baseURL}/downloadArquivo/${id}`);
  }

  listaStatusProduto(): Observable<CampanhaEncarte[]> {
    return this.http.get<CampanhaEncarte[]>(`${baseURL}/lista-status-produto`);
  }

  listaStatusGerencia(): Observable<CampanhaEncarte[]> {
    return this.http.get<CampanhaEncarte[]>(`${baseURL}/lista-status-gerencia`);
  }
  listaStatusDiretoria(): Observable<CampanhaEncarte[]> {
    return this.http.get<CampanhaEncarte[]>(`${baseURL}/lista-status-diretoria`);
  }
  listaStatusImpressao(): Observable<CampanhaEncarte[]> {
    return this.http.get<CampanhaEncarte[]>(`${baseURL}/lista-status-impressao`);
  }

  aprovarArquivo(body: any): Observable<any> {
    return this.http.post(`${baseURL}/aprovar-arquivo`, body);
  }

  reprovarArquivo(body: any): Observable<any> {
    return this.http.post(`${baseURL}/reprovar-arquivo`, body);
  }

  listCampanhaLog(): Observable<any> {
    return this.http.get(`${baseURL}/listaAll`);
  }


  finaliza(id: string): Observable<any> {
    return this.http.get(`${baseURL}/finaliza/${id}`)
  }
}
