import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { Setor } from '@models/setor';

const baseURL = `${environment.api_url}/empresa/v1/setor`;


@Injectable({
  providedIn: 'root'
})
export class SetorService {

  constructor(private httpClient: HttpClient) { }

  listAll(): Observable<Setor[]> {
    return this.httpClient.get<Setor[]>(`${baseURL}/lista`);
  }

  read(id: string): Observable<Setor> {
    return this.httpClient.get<Setor>(`${baseURL}/recupera/${id}`);
  }

  create(data: Omit<Setor, 'id'>): Observable<any> {
    return this.httpClient.post(`${baseURL}/novo`, data);
  }

  update(data: Partial<Setor>): Observable<any> {
    return this.httpClient.put(`${baseURL}/atualiza`, data);
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${baseURL}/remove/${id}`);
  }

}
