import { NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { LoginService } from '@services/login.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isLandingPage = true;
  isCollapsed = true;

  constructor(private loginService: LoginService, private router: Router) {}

  checkPermissao(key) {
    var roles = this.loginService.currentRole;
    return roles.includes(key);
  }

  isUserLoggedIn() {
    return this.loginService.isLoggedIn;
  }

  

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(({ url }: any) => {
        if (url != '/') {
          this.isLandingPage = false;
        } else {
          this.isLandingPage = true;
        }
      });
  }

}
