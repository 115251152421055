import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';


const baseURL = `${environment.api_url}/empresa/v1/campanha-log`;


@Injectable({
  providedIn: 'root'
})
export class CampanhaEncarteLogService {

  constructor(private http: HttpClient) { }

  // listCampanhaLog(): Observable<any> {
  //   return this.http.get(`${baseURL}/listaAll`);
  // }

  listCampanhaLog(id: string): Observable<any> {
    return this.http.get(`${baseURL}/listAll/${id}`);
  }


}
