import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  
  constructor(private http: HttpClient, private router: Router ) {
  }

  private domain : string = "";
  private endpoint : string = "";
  private isRefreshing : boolean = false;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.domain = req.url.substring(0,req.url.indexOf("/", req.url.indexOf("//")+2));
    this.endpoint = req.url.substring(this.domain.length);

    if (this.endpoint.indexOf("/login") < 0 && this.endpoint.indexOf("/refresh") < 0 && sessionStorage.getItem('access_token')) {
      var reqAuth = this.addAuthHeader(req);
      return next.handle(reqAuth).pipe(catchError( (error) => {
        return this.handleResponseError(error, req, next);
      }));;
    } else {
      return next.handle(req);
    }

  }


  addAuthHeader(request: HttpRequest<any>) {
    var authHeader = sessionStorage.getItem('access_token');
    if (authHeader) {
        return request.clone({
            setHeaders: {
                "Authorization": `Bearer ${authHeader}`
            }
        });
    }
    return request;
  }
  
  handleResponseError(error : any, req: HttpRequest<any>, next: HttpHandler) {
    
    if (error.status === 401) {// Invalid token error
      if (!this.isRefreshing)
        this.isRefreshing = true;

      return this.refreshToken().pipe(
        switchMap( (res) => {
          var auth: string | null = res.headers.get('Authorization');
          var refresh: string | null = res.headers.get('Refresh');
  
          if (auth && refresh) {
            sessionStorage.setItem('access_token',auth);
            sessionStorage.setItem('refresh_token',refresh);
          }
  
          this.isRefreshing = false;
          var reqAuth = this.addAuthHeader(req);
          return next.handle(reqAuth);
        }),catchError( (e) => {
          this.isRefreshing = false;
          this.router.navigateByUrl("/login");
          return throwError(() => e);
        })
      )
      

    } else if (error.status === 403) {// Access denied error
      this.router.navigateByUrl("/login");
      return throwError(() => null);
    } else {
      var reqAuth = this.addAuthHeader(req);
      return next.handle(reqAuth);
    }


  }


  refreshToken() {
    //console.log("Executando refresh");
    //console.log(sessionStorage.getItem('refresh_token'));


    return this.http.post(`${environment.api_url}/refresh`,{}, {observe:'response',headers:{Authorization: `Bearer ${sessionStorage.getItem('refresh_token')}`}});
  }
  
  
}

  export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];
