<nz-page-header class="site-page-header" nzTitle="Aprovações Pendentes" nzSubtitle="Usuários pendentes de aprovação">
</nz-page-header>
<div>
    <ng-container #customLoadingTemplate>
        <div>
            <nz-table [nzData]="pendenciasCampanha" #rowSelectionTable>
                <thead>
                    <tr>
                        <th>Nome da Loja</th>
                        <th>Nome do Setor</th>
                        <th>Nome do Usuário</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let pendenciaCampanha of rowSelectionTable.data;">
                        <td>{{pendenciaCampanha.lojNomeFantasia}}</td>
                        <td>{{pendenciaCampanha.depDescricao}}</td>
                        <td>{{pendenciaCampanha.usuNome}}</td>
                        <td>{{ descricaoStatus(pendenciaCampanha.cmeStatus) }}</td>
                    </tr>
                </tbody>
            </nz-table>
            <div class="button-group-footer">
              <button nz-button nzType="secondary" class="button__custom" (click)="cancelar()">
                <span>Voltar</span>
              </button>
            </div>
        </div>
    </ng-container>
</div>
