import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UsuarioFormComponent } from './components/usuario-form/usuario-form.component';
import { UsuarioListComponent } from './components/usuario-list/usuario-list.component';

import { SetorFormComponent } from './components/setor-form/setor-form.component';
import { SetorListComponent } from './components/setor-list/setor-list.component';

import { GrupoUsuarioFormComponent } from './components/grupousuario-form/grupousuario-form.component';
import { GrupoUsuarioListComponent } from './components/grupousuario-list/grupousuario-list.component';

import { LojaFormComponent } from './components/loja-form/loja-form.component';
import { LojaListComponent } from './components/loja-list/loja-list.component';

import { CampanhaFormComponent } from './components/campanha-form/campanha-form.component';
import { CampanhaListComponent } from './components/campanha-list/campanha-list.component';
import { CampanhaAcompanhamentoListComponent } from '@components/campanha-acompanhamento-list/campanha-acompanhamento-list.component';

import { CampanhaPendenciaListComponent } from './components/campanha-pendencia-list/campanha-pendencia-list.component';

import { CampanhaEncarteProdutoListComponent } from './components/campanha-produto-list/campanha-produto-list.component';
import { CampanhaEncarteGerenciaListComponent } from './components/campanha-gerencia-list/campanha-gerencia-list.component';
import { CampanhaEncarteProdutoArquivoComponent } from './components/campanha-produto-arquivo/campanha-produto-arquivo.component';
import { CampanhaEncarteGerenciaArquivoComponent } from './components/campanha-gerencia-arquivo/campanha-gerencia-arquivo.component';
import { CampanhaEncarteDiretoriaListComponent } from './components/campanha-diretoria-list/campanha-diretoria-list.component';
import { CampanhaEncarteDiretoriaArquivoComponent } from '@components/campanha-diretoria-arquivo/campanha-diretoria-arquivo.component';

import { LoginComponent } from './components/login/login.component';
import { ResetComponent } from './components/reset/reset.component';
import { AlterarSenhaComponent } from './components/alterar-senha/alterar-senha.component';

import { ImagemListComponent } from './components/imagem-list/imagem-list.component';

import { RegistroFormComponent } from './components/registro-form/registro-form.component';
import { EmpresaListComponent } from './components/admin-empresa-list/admin-empresa-list.component';
import { EmpresaFormComponent } from './components/admin-empresa-form/admin-empresa-form.component';
import { IndexComponent } from './components/index/index.component';
import { LayoutListComponent } from './components/layout-list/layout-list.component';
import { LayoutFormComponent } from './components/layout-form/layout-form.component';
import { TiposPaginaListComponent } from './components/tipos-pagina-list/tipos-pagina-list.component';
import { TiposPaginaFormComponent } from './components/tipos-pagina-form/tipos-pagina-form.component';
import { EncarteFormComponent } from './components/encarte-form/encarte-form.component';
import { EncarteListComponent } from './components/encarte-list/encarte-list.component';
import { ConfigFilesComponent } from './components/config-files/config-files.component';

import { SVGComponent } from '@components/svg/svg.component';


import { environment } from '../environments/environment';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { pt_BR } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzTableModule } from 'ng-zorro-antd/table';
import { LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { AttachmentComponent } from './shared/attachment/attachment.component';
import { NgxLoadingModule } from 'ngx-loading';
import { ToastrModule } from 'ngx-toastr';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CampanhaAcompanhamentoViewComponent } from './components/campanha-acompanhamento-view/campanha-acompanhamento-view.component';

import { httpInterceptorProviders } from './helpers/HttpRequestInterceptor';

import { NgxColorsModule } from 'ngx-colors';
import { CardSvgListComponent } from './components/card-svg-list/card-svg-list.component';
import { EncartSvgListComponent } from './components/encart-svg-list/encart-svg-list.component';
import { ArquivoSvgListComponent } from './components/arquivo-svg-list/arquivo-svg-list.component';


registerLocaleData(ptBr);


const domains = `${environment.jwt_domain}`;


@NgModule({
  declarations: [
    AppComponent,
    UsuarioFormComponent,
    UsuarioListComponent,
    SetorFormComponent,
    SetorListComponent,
    GrupoUsuarioFormComponent,
    GrupoUsuarioListComponent,
    LojaFormComponent,
    LojaListComponent,
    CampanhaFormComponent,
    CampanhaAcompanhamentoListComponent,
    CampanhaListComponent,
    CampanhaPendenciaListComponent,
    CampanhaEncarteProdutoArquivoComponent,
    CampanhaEncarteProdutoListComponent,
    CampanhaEncarteGerenciaListComponent,
    CampanhaEncarteGerenciaArquivoComponent,
    CampanhaEncarteDiretoriaListComponent,
    CampanhaEncarteDiretoriaArquivoComponent,
    LoginComponent,
    ResetComponent,
    AlterarSenhaComponent,
    RegistroFormComponent,
    EmpresaListComponent,
    EmpresaFormComponent,
    ImagemListComponent,
    IndexComponent,
    LayoutListComponent,
    LayoutFormComponent,
    TiposPaginaListComponent,
    TiposPaginaFormComponent,
    EncarteFormComponent,
    EncarteListComponent,
    AttachmentComponent,
    ConfigFilesComponent,
    CampanhaAcompanhamentoViewComponent,
    SVGComponent,
    CardSvgListComponent,
    EncartSvgListComponent,
    ArquivoSvgListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxColorsModule,
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({}),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    /*
    JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
          return sessionStorage.getItem('access_token');
        },
        allowedDomains: [domains],
        disallowedRoutes: [`${environment.api_url}/login`]
      }
    }),
    */
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzListModule,
    NzFormModule,
    NzModalModule,
    NzButtonModule,
    FormsModule,
    ReactiveFormsModule,
    NzPageHeaderModule,
    NzRadioModule,
    NzTableModule,
    NzInputNumberModule,
    NzDatePickerModule,
    NzUploadModule,
    NzDividerModule,
    NzPopconfirmModule,
    NzResultModule,
    NzSelectModule,
    NzCheckboxModule,
    NzCardModule,
    NzToolTipModule,
    NzPopoverModule,
    NzAlertModule,
    NzPaginationModule,
    NzDropDownModule,
    NzIconModule,
    NgxMaskModule.forRoot(),
    
  ],
  providers: [{ provide: NZ_I18N, useValue: pt_BR }, { provide: LOCALE_ID, useValue: 'pt-BR' }, DatePipe, httpInterceptorProviders],

  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(public datepipe: DatePipe) {

  }

}
