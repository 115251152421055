<nz-page-header class="site-page-header" nzTitle="Layouts" nzSubtitle="Layouts cadastrados">
</nz-page-header>
<ng-container #customLoadingTemplate>
    <nz-table [nzData]="typePages" #rowSelectionTable>
        <thead>
            <tr>
                <th>Nome do Tipo de Página</th>
                <th>Altura mm / px</th>
                <th>Largura mm / px</th>
                <th width="150px"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let typePage of rowSelectionTable.data;">
                <td>{{typePage.tpgDescricao}}</td>
                <td>{{typePage.tpgAltura}}</td>
                <td>{{typePage.tpgLargura}}</td>
                <td>
                    <a (click)="editTipoPagina(typePage)">Editar</a>&nbsp;
                    <a nz-popconfirm nzPopconfirmTitle="Deseja remover esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="delete(typePage)" >Remover</a>
                </td>
            </tr>
        </tbody>
    </nz-table>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</ng-container>
<br>
<p align="right">
    <button class="form-button" nz-button nzType="primary" (click)="createTipoPagina()">Novo</button>
</p>
