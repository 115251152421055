import { CampanhaEncarteLog } from './../../models/campanha-encarte-log';
import { Coluna } from '@models/coluna';
import { Linha } from './../../models/linha';
import { Arquivo } from './../../models/arquivo';
import { Encarte } from '../../models/encarte';
import { CampanhaEncarteService } from '@services/campanhaencarte.service';
import { CampanhaEncarte } from '@models/campanhaencarte';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormControl, UntypedFormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CampanhaEncarteArquivo } from '@models/campanha-encarte-arquivo';
import { CampanhaEncarteArquivoService } from '@services/campanha-encarte-arquivo.service';
import { EncarteService } from '@services/encarte.service';
import { ToastrService } from 'ngx-toastr';
import { ImagemService } from 'src/app/services/imagem.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-campanha-gerencia-arquivo',
  templateUrl: './campanha-gerencia-arquivo.component.html',
  styleUrls: ['./campanha-gerencia-arquivo.component.css']
})
export class CampanhaEncarteGerenciaArquivoComponent implements OnInit {
  linhas = [];
  campanhaencarteId = null;
  lojNomeFantasia = null;
  cmpNome = null;
  idCampanha = null;
  encDescricao = null;
  cmpDataGerencia = null;
  celDescricao=null;
  campanhalog: CampanhaEncarteLog;
  public loading: boolean = false;
  public form: UntypedFormGroup;

  private httpClient: HttpClient;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private campanhaEncarteService: CampanhaEncarteService,
    private imagemService: ImagemService,
    private modal: NzModalService,
    private campanhaEncarteArquivoService: CampanhaEncarteArquivoService,
    private encarteService: EncarteService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    http: HttpClient
  ) {

    this.httpClient = http;
    this.form = this.formBuilder.group({
      cmaArquivo: null,
      lojNomeFantasia: null,
      cmpNome: null,
      cmpDataGerencia: null,
      celDescricao: null
    })

  }
  get campanhaencarteArray(): UntypedFormArray {
    return <UntypedFormArray>this.form.get('campanhasEncarte');
  }

  ngOnInit(): void {
    this.campanhaencarteId = this.route.snapshot.paramMap.get('id');
    if (!!this.campanhaencarteId)
      this.getCampanhaEncarte(this.campanhaencarteId);
    this.getArquivoJson(this.campanhaencarteId);

  }

  getCampanhaEncarte(id): void {

    this.loading = true;
    this.campanhaEncarteService.read(id)
      .subscribe(
        campanhaencarte => {
          this.loading = false;
          this.idCampanha = campanhaencarte.id;
          this.lojNomeFantasia = campanhaencarte.lojNomeFantasia;
          this.cmpNome = campanhaencarte.cmpNome;
          this.cmpDataGerencia = campanhaencarte.cmpDataGerencia;
          this.encDescricao = campanhaencarte.encDescricao;

        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao recuperar Campanha Encarte!');
        });
  }


  getArquivoJson(id): void {
    this.loading = true;
    this.campanhaEncarteService.visualizarCsvTable(id)
      .subscribe(
        arquivo => {
          this.loading = false;
          this.linhas = arquivo.linhas.filter(l => l.colunas.filter(c => c.conteudos));
          var moveCabecalho = this.linhas[0];
          this.linhas.shift();
          this.linhas.push(moveCabecalho);
          let i = 0;
          this.linhas.forEach(linha => {
            linha.idx = i;
            i++;
          });
        });
  }


  aprovarCurrentGerencia(): void {
    var campanhaencartelog = new CampanhaEncarteLog();
    campanhaencartelog.id = this.campanhaencarteId;
    campanhaencartelog.celDetalhe = this.form.get('celDescricao').value;
   // this.campanhaEncarteService.aprovarArquivo({id: id, celDescricao: this.form.get('celDescricao').value})
   this.campanhaEncarteService.aprovarArquivo(campanhaencartelog)
      .subscribe(
        response => {
          this.loading = false;
          this.router.navigateByUrl('campanha-ger/list');
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao Aprovar Arquivo!');
        });
  }

  reprovarCurrentGerencia(): void {
    var campanhaencartelog = new CampanhaEncarteLog();
    campanhaencartelog.id = this.campanhaencarteId;
    campanhaencartelog.celDetalhe = this.form.get('celDescricao').value;
   // this.campanhaEncarteService.reprovarArquivo({id: id, celDescricao: this.form.get('celDescricao').value})
   this.campanhaEncarteService.reprovarArquivo(campanhaencartelog)
      .subscribe(
        response => {
          this.loading = false;
          this.router.navigateByUrl('campanha-ger/list');
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao Aprovar Arquivo!');
        });
  }


  cancelar(): void {
    this.router.navigateByUrl('/campanha-ger/list');
  }

  verCurrentImagem(imagem): void {
    this.loading = true;
    this.imagemService.read(imagem,0)
      .subscribe(
        response => {
          this.modal.info(
            {
              nzTitle: response.imgNome,
              nzContent: "<img class=\"imgContentCss\" src=\"" + response.imgConteudo + "\">",
              nzWidth: 700
            }


          );
          //this.modalIsVisible = true;
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao buscar Imagem!');
        });
  }


  enviaArquivosDownload(id) {

    this.loading = true;
    this.encarteService.pdfJsonPreview(id)
      .subscribe(
        response => {

          if (response.error) {

            this.toastr.error(response.message, 'Erro ao gerar arquivo!');
            this.loading = false;

          } else {

            this.loading = false;
            this.toastr.success('PDF Gerado!', 'Iniciando o download. Por favor, aguarde.');
            this.processaArquivoBlob();

          }

        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Algo saiu errado!');
        });

  }

  processaArquivoBlob() {
    this.loading = true;

    this.encarteService.pdfBlob()
      .subscribe(
        response => {
          saveAs(response, this.cmpNome+'_'+this.lojNomeFantasia+'.zip');
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao processar realizar o download.');
        });
  }



}
