//import { logging } from 'protractor';
export class Loja {
  id: string;
  lojRazaoSocial: string;
  lojNomeFantasia: string;
  lojNomeGerente: string;
  lojCnpj: string;
  lojNumero: string;
  lojComplemento: string;
  lojDdd: string;
  lojTelefone: string;
  logId: string;
  lojRua: string;
}
