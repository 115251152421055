<nz-page-header class="site-page-header" nzTitle="Cards" nzSubtitle="Cards cadastrados">
</nz-page-header>
<div>
    <ng-container #customLoadingTemplate>
        <form nz-form [formGroup]="form">
            <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
                <nz-form-label [nzSpan]="5">Buscar</nz-form-label>
                <nz-form-control [nzSpan]="20">
                    <input
                            style="width: 100%;"
                            nz-input
                            formControlName="filtro"
                            name="filtro"
                            (ngModelChange)="buscar()"
                            />
                </nz-form-control>
                <nz-form-control [nzSpan]="5">
                  <label nz-checkbox (ngModelChange)="buscarArquivado()" formControlName="arquivado">
                      Arquivado
                    </label>
                  </nz-form-control>
            </nz-form-item>
        </form>
        <div>
            <nz-table [nzData]="listaTemplates" #rowSelectionTable>
                <thead>
                    <tr>
                        <th>Nome do card</th>
                        <th width="280px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let card of rowSelectionTable.data;">
                        <td>{{card.stpNome}}</td>
                        <td>
                            <a (click)="editCard(card.id)">Editar</a>&nbsp;
                            <a nz-popconfirm nzPopconfirmTitle="Deseja criar uma cópia deste item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="duplicarCard(card.id)">Duplicar</a>&nbsp;
                            <a nz-popconfirm nzPopconfirmTitle="Deseja remover esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="deleteCard(card.id)">Remover</a>&nbsp;
                            <a nz-popconfirm nzPopconfirmTitle="Deseja arquivar esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="arquivar(card)">Arquivar</a>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </ng-container>
</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Nome do card" (nzOnCancel)="handleCancel()" (nzOnOk)="saveCard()">
    <ng-container *nzModalContent>
        <form [formGroup] = "cardName" style="width: 100%;">
            <nz-form-item style="margin-bottom: 3px;">
                <nz-form-label [nzSpan]="24" nzFor="stpNome" style="margin-bottom: -15px;">Nome do card</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="Informe o nome do card" nzSize="large" formControlName="stpNome" style="width: 100%;"/>
                    <ng-template #userErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">Campo obrigatório</ng-container>
                      </ng-template>
                </nz-form-control>
            </nz-form-item >   
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzFor="pagseSize" style="margin-bottom: -15px;">Tamanho do card em (mm)</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="Tamanho do card: 300x500" nzSize="large" formControlName="cardSize" style="width: 100%;"/>
                    <ng-template #userErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">Campo obrigatório</ng-container>
                      </ng-template>
                </nz-form-control>
            </nz-form-item >             
   
        </form>
    </ng-container>
</nz-modal>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<br>
<p align="right">
    <button class="form-button" nz-button nzType="primary" (click)="criarCard()">Novo</button>
</p>
