import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {

  private token:string;
  public tokenError:string;

  constructor(private loginService: LoginService, private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.loginService.logout();
    this.token = this.route.snapshot.paramMap.get('token');
  }


  atualiza(): void {

    var password = this.form.get('password').value;


    this.loginService.resetPassword(this.token, password)
      .subscribe(
        response => {
          this.router.navigateByUrl('/login');
        },
        response_error => {
          this.tokenError = response_error.error.mensagem;
        });
  }

  confirmationValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null && this.form.get('password2').value !== this.form.get('password').value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  complexityValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null) {
      var password = this.form.get('password').value;
      var hasLength = (password != null && password.length > 7);
      var hasUpperCase = /[A-Z]/.test(password);
      var hasLowerCase = /[a-z]/.test(password);
      var hasNumbers = /\d/.test(password);
      //var hasNonalphas = /\W/.test(password);
      if (!hasUpperCase || !hasLowerCase || !hasNumbers || !hasLength)
        return { complexity: true, error: true };
      }
      return {};
  };


  form = new UntypedFormGroup({
    password: new UntypedFormControl(null, [Validators.required, this.complexityValidator]),
    password2: new UntypedFormControl(null, [Validators.required, this.confirmationValidator])
  });

}
