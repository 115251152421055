import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LayoutService } from '@services/layout.service';
import { EmpresaService } from '@services/empresa.service';
import { ToastrService } from 'ngx-toastr';
import { Layout } from 'src/app/models/layout';

@Component({
  selector: 'app-layout-form',
  templateUrl: './layout-form.component.html',
  styleUrls: ['./layout-form.component.css']
})
export class LayoutFormComponent implements OnInit {

  layouts: Layout[];
  layoutId:string;
  sucesso:boolean;
  imageError: string;
  empresas= null;
  isImageSaved: boolean = false;
  tipoMidia = [
    {"id":1, "descricao": "PDF"},
    {"id":2, "descricao": "JPEG"},
    {"id":3, "descricao": "HTML"}
  ];

  public loading:boolean = false;

  public layoutForm: UntypedFormGroup;

   constructor(
    private layoutService:LayoutService,
    private empresaService:EmpresaService,
    private formBuilder : UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService) {

      this.layoutForm = this.formBuilder.group({
        id:null,
        layAltura: new UntypedFormControl(null),
        layDescricao: new UntypedFormControl(null, [Validators.required]),
        layExemplo: new UntypedFormControl(null),
        empId: new UntypedFormControl(null),
        slaId: new UntypedFormControl(1, null),
        combinacoes: this.formBuilder.array([this.newCombinacao()])
      });
    }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') != undefined)
    this.getLayout(this.route.snapshot.paramMap.get('id'));
    this.readEmpresas();
    this.sucesso = false;
  }

  get combinacaoArray(): UntypedFormArray {

    return <UntypedFormArray>this.layoutForm.get('combinacoes');

  }

  private newCombinacao(): UntypedFormGroup {

    return this.formBuilder.group({
      id: null,
      comHtml:"",
      comQuantidadeItem: "",
    });

  }

  readEmpresas(): void {
      this.empresaService.listAtivas()
        .subscribe(
          empresas => {
            this.empresas = empresas;
            empresas.unshift({"id":null, "empNomeFantasia":"Nenhuma"});
          },
          error => {
            console.log(error);
          });
    }

  getLayout(id): void {

    this.loading = true;
    this.layoutService.readAdm(id)
      .subscribe(
        layout => {
          this.loading = false;
          this.readLayoutJsonToForm(layout)
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao recuperar Layout!');
        });
  }

  readLayoutJsonToForm (layout): void {

    this.layoutForm.get("id").setValue(layout.id);
    this.layoutForm.get("layAltura").setValue(layout.layAltura);
    this.layoutForm.get("layDescricao").setValue(layout.layDescricao);
    this.layoutForm.get("layExemplo").setValue(layout.layExemplo);
    this.layoutForm.get("slaId").setValue(layout.slaId);
    this.layoutForm.get("empId").setValue(layout.empId);
    this.combinacaoArray.removeAt(0);

    layout.combinacoes && layout.combinacoes
    .map((item) => {

      this.combinacaoArray.push(
        <UntypedFormGroup>this.formBuilder.group({
          id: item.id,
          comHtml: item.comHtml,
          comQuantidadeItem: item.comQuantidadeItem,
        })
      )

    })

  }


  deleteCombinacao(indexCombinacao): void {

    this.combinacaoArray.removeAt(indexCombinacao);

  }


  addCombinacao(): void {

    this.combinacaoArray.push(this.newCombinacao());

  }

  salvarLayout(): void {

    this.loading = true;

    if (this.route.snapshot.paramMap.get('id') != undefined) {

      this.layoutService.updateAdm(this.layoutForm.value)
        .subscribe(
          response => {
            this.loading = false;
            this.router.navigateByUrl('/layout/list');
            this.toastr.success('Layout Salvo!', 'Gravado com Sucesso!');
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao salvar Layout!');

          });

    } else {

      this.layoutService.createAdm(this.layoutForm.value)
      .subscribe(
        response => {
          this.loading = false;
          this.toastr.success('Layout Salvo!', 'Gravado com Sucesso!');
          this.router.navigateByUrl('/layout/list');
        },
         error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao salvar Layout!');
        });
    }
  }

  cancelar(): void {
    this.router.navigateByUrl('/layout/list');
  }

  fileChangeEvent(files: any) {

    this.loading = true;

    const uploaded = files[0];
    if (!uploaded) {
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.layoutForm.get("layExemplo").setValue(reader.result)
    };

    reader.readAsDataURL(uploaded);
    this.loading = false;

  }

}
