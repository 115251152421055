import { SetorService } from './../../services/setor.service';
import { LojaService } from './../../services/loja.service';
import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { GrupoUsuarioService } from 'src/app/services/grupousuario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/models/usuario';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.css']
})
export class UsuarioFormComponent implements OnInit {

  usuarioId = null;
  usuarioEmailEdit = "";
  grupousuario = null;
  setores = null;
  lojas = null;
  public loading:boolean = false;

  constructor(
    private usuarioService: UsuarioService,
    private grupoUsuarioService: GrupoUsuarioService,
    private setoresService: SetorService,
    private lojasService: LojaService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.readGrupoUsuario();

    if (this.route.snapshot.paramMap.get('id') != undefined) {
      this.getUsuario(this.route.snapshot.paramMap.get('id'));
    } else {
      this.readSetores(null);
      this.readLojas(null);
    }

  }

  getUsuario(id): void {

    this.loading = true;
    this.usuarioService.read(id)
      .subscribe(
        usuario => {
          this.loading = false;
          this.usuarioId = usuario.id;
          this.usuarioEmailEdit = usuario.usuLogin;
          this.form.get("usuNome").setValue(usuario.usuNome);
          this.form.get("usuLogin").setValue(usuario.usuLogin);
          this.form.get("usuStatus").setValue(usuario.usuStatus);
          this.form.get("gusId").setValue(usuario.gusId);
          this.readSetores(usuario.setores);
          this.readLojas(usuario.lojas);
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao recuperar Usuário!');
        });
  }

  cancelar(): void {
    this.router.navigateByUrl('/usuario/list');
  }


  validaEmail = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {

      if (this.usuarioEmailEdit != this.form.get('usuLogin').value && this.form.get('usuLogin').value != "") {
        this.usuarioService.check(this.form.get('usuLogin').value).subscribe(
          usuario => {
            if (usuario.disponivel=="N") {
              observer.next({ duplicated: true, error: true});
              observer.complete();
            } else
              observer.next(null);
              observer.complete();
          },
          error => {
            observer.next(null);
            observer.complete();
          });

        } else {
          observer.next(null);
          observer.complete();
        }

  });

  salvarUsuario(): void {

    var usu = new Usuario();
    usu.usuNome = this.form.get('usuNome').value;
    usu.usuLogin = this.form.get('usuLogin').value;
    usu.usuStatus = this.form.get("usuStatus").value;
    usu.gusId = this.form.get("gusId").value;

    var setorSelected = new Array();
    this.setores.forEach(item => {
      if (this.form.get(item.name).value == true) {
        setorSelected.push({ id: item.depId });
      }
    });
    usu.setores = setorSelected;

    var lojaSelected = new Array();
    this.lojas.forEach(item => {
      if (this.form.get(item.name).value == true) {
        lojaSelected.push({ id: item.lojId });
      }
    });
    usu.lojas = lojaSelected;

    this.loading = true;

    if (this.usuarioId != null) {
      usu.id = this.usuarioId;

      this.loading = true;
      this.usuarioService.update(usu)
        .subscribe(
          response => {
            this.loading = false;
            this.router.navigateByUrl('/usuario/list');
            this.toastr.success('Usuário Salvo!', 'Gravado com Sucesso!');
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao salvar Usuário!');
          });
      } else {
        this.usuarioService.create(usu)
        .subscribe(
          response => {
            this.loading = false;
            this.router.navigateByUrl('/usuario/list');
            this.toastr.success('Usuário Salvo!', 'Gravado com Sucesso!');
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao salvar Usuário!');
          });
      }

    }

    form = new UntypedFormGroup({
      usuNome: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
      usuLogin: new UntypedFormControl(null, [Validators.required, Validators.maxLength(128), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")],[this.validaEmail]),
      gusId: new UntypedFormControl(null, [Validators.required]),
      usuStatus: new UntypedFormControl(null, null)
    });


    readGrupoUsuario(): void {

      this.loading = true;
      this.grupoUsuarioService.listAll()
        .subscribe(
          grupousuario => {
            this.loading = false;
            this.grupousuario = grupousuario;
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao buscar Grupos de Usuário!');
          });
    }

    readSetores(setoresAtivos): void {

      this.loading = true;
      this.setoresService.listAll()
        .subscribe(
          setores => {
            this.loading = false;
            //this.setor = setor;
            var i =1;
            var checkSetores = new Array();

            setores.forEach(setor => {
              var found = false;
              if (setoresAtivos != null) {
                setoresAtivos.forEach(setorAtivo => {
                  if (setor.id == setorAtivo.id) {
                    found = true;
                  }
                });
              }
              var item = {name: 'formSetor' + (i++), depDescricao: setor.depDescricao, depId: setor.id, checked: found};
              var formControl = new UntypedFormControl(item.checked, null);
              this.form.addControl(item.name, formControl);
              checkSetores.push(item);
            });
            this.setores = checkSetores;
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao buscar Setores!');
          });
    }

    readLojas(lojasAtivas): void {

      this.loading = true;
      this.lojasService.listAtivas()
        .subscribe(
          lojas => {
            this.loading = false;
            //this.loja = loja;
            var i =1;
            var checkLojas = new Array();

            lojas.forEach(loja => {
              var found = false;
              if (lojasAtivas != null) {
                lojasAtivas.forEach(lojaAtiva => {
                  if (loja.id == lojaAtiva.id) {
                    found = true;
                  }
                });
              }
              var item = {name: 'formLoja' + (i++), lojNomeFantasia: loja.lojNomeFantasia, lojId: loja.id, checked: found};
              var formControl = new UntypedFormControl(item.checked, null);
              this.form.addControl(item.name, formControl);
              checkLojas.push(item);
            });
            this.lojas = checkLojas;
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao buscar Lojas!');
          });
    }



}
