import { CampanhaEncarteArquivo } from './../models/campanha-encarte-arquivo';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CampanhaEncarte } from '@models/campanhaencarte';

const baseURL = `${environment.api_url}/empresa/v1/campanha-encarte`;

@Injectable({
  providedIn: 'root'
})
export class CampanhaEncarteArquivoService {

  constructor(private http: HttpClient){}

  listAll(): Observable<CampanhaEncarte[]> {
    return this.http.get<CampanhaEncarte[]>(`${baseURL}/lista`);
  }

  read(id: string): Observable<CampanhaEncarte> {
    return this.http.get<CampanhaEncarte>(`${baseURL}/recupera/${id}`);
  }

  readCsv(id: string): Observable<CampanhaEncarte> {
    return this.http.get<CampanhaEncarte>(`${baseURL}/recuperarCsv/${id}`);
  }

  upload(id: string): Observable<CampanhaEncarte> {
    return this.http.get<CampanhaEncarte>(`${baseURL}/upload/${id}`);
  }

  uploadCSVTeste(body:any): Observable<any> {
    return this.http.post(`${baseURL}/uploadCSVTeste/`, body, {responseType:'json'})
  }
  create(data: CampanhaEncarte): Observable<any> {
    return this.http.post(`${baseURL}/novo`, data);
  }

  update(data: Partial<CampanhaEncarte>): Observable<any> {
    return this.http.put(`${baseURL}/atualiza`, data);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(`${baseURL}/remove/${id}`);
  }

  aprovarArquivo(body: any): Observable<any> {
    return this.http.post(`${baseURL}/aprovar-arquivo`, body);
  }
}
