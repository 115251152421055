import { Component, OnInit } from '@angular/core';
import { CampanhaService } from 'src/app/services/campanha.service';
import { Campanha } from 'src/app/models/campanha';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-campanha-acompanhamento-list',
  templateUrl: './campanha-acompanhamento-list.component.html',
  styleUrls: ['./campanha-acompanhamento-list.component.css']
})

export class CampanhaAcompanhamentoListComponent implements OnInit {

  campanhas: Campanha[];

  public loading: boolean = false;

  constructor(
    private campanhaService: CampanhaService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.readCampanhas();

  }


  readCampanhas(): void {

    this.loading = true;
    this.campanhaService.listAll()
      .subscribe(
        campanhas => {
          this.loading = false;
          this.campanhas = campanhas;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao enviar!');
        });
  }

  refresh(): void {
    this.readCampanhas();
  }

  viewDetails(campanha) {
    this.router.navigateByUrl('/campanha/acompanhamento-detalhes/' + campanha.id);
  }

  editCurrentCampanha(campanha): void {
    this.router.navigateByUrl('/campanha/form/' + campanha.id);
  }

  pendenciasCurrentCampanha(campanha): void {
    this.router.navigateByUrl('/campanha/list-pendencia/' + campanha.id);
  }

  delete(campanhas): void {
    this.deleteCurrentCampanha(campanhas);
    this.campanhas = this.campanhas.filter(item => item.id != campanhas.id);
  }

  deleteCurrentCampanha(campanha): void {

    this.loading = true;
    this.campanhaService.delete(campanha.id)
      .subscribe(
        retorno => {
          this.readCampanhas();
          this.toastr.success('Campanha Excluída!', 'Sucesso!');
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  createCampanha(): void {
    this.router.navigateByUrl('/campanha/form');
  }

  sortColunaCmpNome(a : any, b : any) : boolean {
    return (a.cmpNome.localeCompare(b.cmpNome));
  }



}
