import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TipoPaginaService } from '@services/tipo-pagina.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tipos-pagina-form',
  templateUrl: './tipos-pagina-form.component.html',
  styleUrls: ['./tipos-pagina-form.component.css']
})
export class TiposPaginaFormComponent implements OnInit {

  tipoMidia = [
    {"id":1, "descricao": "PDF"},
    {"id":2, "descricao": "JPEG"},
    {"id":3, "descricao": "HTML"}
  ];


  sucesso:boolean;
  tipoPaginaId:string;
  form = new UntypedFormGroup({
    tpgDescricao: new UntypedFormControl(null, [Validators.required]),
    tpgAltura: new UntypedFormControl(null),
    tpgLargura: new UntypedFormControl(null),
    slaId: new UntypedFormControl(null),
  });

  public loading:boolean = false;
 
  constructor(
    private tipoPaginaService: TipoPaginaService, 
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

    
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') != undefined)
      this.getTipoPagina(this.route.snapshot.paramMap.get('id'));
      this.sucesso = false;
  }

  getTipoPagina(id): void {

    this.loading = true;
    this.tipoPaginaService.readAdm(id)
      .subscribe(
        descricao => {
          this.loading = false;
          this.tipoPaginaId = descricao.id;
          this.form.get('tpgDescricao').setValue(descricao.tpgDescricao);
          this.form.get('tpgLargura').setValue(descricao.tpgLargura);
          this.form.get('tpgAltura').setValue(descricao.tpgAltura);
          this.form.get('slaId').setValue(descricao.slaId);
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao recuperar Tipo de Página!');
        });
  }

  salvarTipoPagina(): void {

    var tpgDescricao = this.form.get('tpgDescricao').value;
    var tpgLargura = this.form.get('tpgLargura').value;
    var tpgAltura = this.form.get('tpgAltura').value;
    var slaId = this.form.get('slaId').value;

    const body = {
      id: this.tipoPaginaId,
      tpgDescricao,
      tpgAltura,
      tpgLargura,
      slaId
    }
  
    this.loading = true;

    if (this.tipoPaginaId != null) {
  
      this.tipoPaginaService.updateAdm(body)
      .subscribe(
        response => {
          this.loading = false;
          this.sucesso = true;
          this.router.navigateByUrl('/paginas/list');
          this.toastr.success('Tipo de Página Salvo!', 'Sucesso!');
        },
        response_error => {
          this.loading = false;
        });
      } else {
        delete body.id;
        this.tipoPaginaService.createAdm(body)
        .subscribe(
          response => {
            this.loading = false;
            this.router.navigateByUrl('/paginas/list');
            this.toastr.success('Tipo de Página Salvo!', 'Sucesso!');
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Erro ao salvar Tipo de Página!');
          });
      }
  
    }

    cancelar(): void {
      this.router.navigateByUrl('/paginas/list');
    }

}
