
<div>
  <nz-page-header class="site-page-header" nzTitle="Loja" nzSubtitle="Dados da Lojas"></nz-page-header>

  <ng-container class="edit-form"  #customLoadingTemplate>
    <form nz-form [formGroup]="form">
        <!-- <nz-divider nzText="Dados da Loja" nzOrientation="left"></nz-divider> -->
        <nz-form-item>
            <nz-form-label [nzSpan]="5">Razão Social</nz-form-label>
            <nz-form-control [nzSpan]="12" [nzErrorTip]="erroRazaoSocial">
                <input nz-input formControlName="lojRazaoSocial" name="lojRazaoSocial" class="input-field" placeholder="Razão social" size="64"/>
                <ng-template #erroRazaoSocial let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        Campo obrigatório<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        Esse campo não permite mais que 256 caracteres<br>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="5">Nome Fantasia</nz-form-label>
            <nz-form-control [nzSpan]="12" [nzErrorTip]="erroNome">
                <input nz-input formControlName="lojNomeFantasia" name="lojNomeFantasia" class="input-field" placeholder="Nome fantasia" size="64"/>
                <ng-template #erroNome let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        Campo obrigatório<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        Esse campo não permite mais que 256 caracteres<br>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
         <nz-form-item>
            <nz-form-label [nzSpan]="5">CNPJ</nz-form-label>
            <nz-form-control [nzSpan]="12" [nzErrorTip]="erroCNPJ">
                <input nz-input formControlName="lojCnpj" name="lojCnpj" size="24" placeholder="CNPJ" mask="00.000.000/0000-00" />
                <ng-template #erroCNPJ let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        Campo obrigatório<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        Esse campo não permite mais que 18 caracteres<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('minlength')">
                        Preencha o CNPJ completo<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('invalidCnpj')">
                        CNPJ inválido<br>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="5">CEP</nz-form-label>
            <nz-form-control [nzSpan]="12" [nzErrorTip]="erroCEP">
                <input nz-input formControlName="logId" name="logId" size="15" placeholder="CEP" mask="00000-000"/>
                <ng-template #erroCEP let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        Campo obrigatório<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        Esse campo não permite mais que 9 caracteres<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('minlength')">
                        Preencha o CEP completo<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('cepInvalid')">
                        CEP inválido ou inexistente<br>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="5">Endereço</nz-form-label>
            <nz-form-control [nzSpan]="12" [nzErrorTip]="erroRua">
                <input nz-input formControlName="lojRua" name="lojRua" class="input-field"  placeholder="Rua da loja"  size="64"/>
                <ng-template #erroRua let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        Campo obrigatório<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        Esse campo não permite mais que 255 caracteres<br>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="5">Número</nz-form-label>
            <nz-form-control [nzSpan]="12" [nzErrorTip]="erroEndereco">
                <input nz-input formControlName="lojNumero" name="lojNumero" placeholder="Número" style="width:100px"/><br>
                <ng-template #erroEndereco let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        Campo obrigatório<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        Esse campo não permite mais que 32 caracteres<br>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="5">Cidade</nz-form-label>
            <nz-form-control [nzSpan]="12">
                <div  class="endereco">{{ cidadeUF }}</div>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="5">Complemento</nz-form-label>
            <nz-form-control [nzSpan]="12" [nzErrorTip]="erroComplemento">
                <input nz-input formControlName="lojComplemento" name="lojComplemento" class="input-field" placeholder="Complemento do endereço, se houver"  size="64"/>
                <ng-template #erroComplemento let-control>
                    <ng-container *ngIf="control.hasError('maxlength')">
                        Esse campo não permite mais que 64 caracteres<br>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="5">Telefone</nz-form-label>
              <nz-form-control [nzSpan]="12" [nzErrorTip]="erroTelefone" >
                  <input nz-input mask="(00) 00000-0000||(00) 00000-0000" formControlName="lojNumTelefone" name="lojNumTelefone" placeholder="Telefone"  />
                  <ng-template #erroTelefone let-control>
                      <ng-container *ngIf="control.hasError('requiredTelefone')">
                          Telefone é um campo obrigatório<br>
                      </ng-container>
                      <ng-container *ngIf="control.hasError('patternTelefone')">
                          Digite o telefone no formato correto<br>
                      </ng-container>
                  </ng-template>
              </nz-form-control>
        </nz-form-item>
          <p align="right">
              <button class="form-button" nz-button nzType="link" (click)="cancelar()">Cancelar</button>
              <button class="form-button" [disabled]="!form.valid" nz-button nzType="primary" (click)="salvarLoja()">Gravar</button>
          </p>
      </form>

  </ng-container>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

</div>
