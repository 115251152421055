import { Component, OnInit } from '@angular/core';
import { LoginService } from '@services/login.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form = new UntypedFormGroup({
    username: new UntypedFormControl(null, [Validators.required]),
    password: new UntypedFormControl(null, [Validators.required])
  });

  svgCanvas: any;
  esqueciSenha = false;
  public loading = false;

  constructor(
    private loginService: LoginService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    
    if (this.loginService.currentUserValue != null) {
      this.loginService.logout();
    }

  }

  forgot(): void {
    const username = this.form.get('username').value;
    this.loginService.forgotPassword(username)
      .subscribe(
        response => {
          this.loading = false;
          this.esqueciSenha = true;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  doLogin(): void {

    const username = this.form.get('username').value;
    const password = this.form.get('password').value;

    this.loading = true;

    this.loginService.login(username, password)
      .subscribe(
        response => {
          this.loading = false;

          if (this.loginService.currentRole.includes('ROLE_ADMIN')) {
            this.router.navigateByUrl('/empresa/list/ativas');
          } else {
            this.router.navigateByUrl('/campanha/list');
          }
        },
        error => {
          this.loading = false;
          console.log(error)
          this.toastr.error('Tente novamente!', 'Login inválido!');
          console.log(error);
        });
  }


}
