<div>
  <nz-page-header class="site-page-header" nzTitle="Campanha" nzSubtitle="Dados da Campanha"></nz-page-header>

  <ng-container class="edit-form" #customLoadingTemplate>
    <form nz-form [formGroup]="campanhaForm">
      <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
        <nz-form-label [nzSpan]="5">Nome da Campanha</nz-form-label>
        <nz-form-control [nzSpan]="10" [nzErrorTip]="erroCmpNome">
          <input nz-input formControlName="cmpNome" name="cmpNome" class="input-field" placeholder="Nome campanha"
            size="30" />
          <ng-template #erroCmpNome let-control>
            <ng-container *ngIf="control.hasError('required')">
              Campo obrigatório<br>
            </ng-container>
            <ng-container *ngIf="control.hasError('maxlength')">
              Esse campo não permite mais que 256 caracteres<br>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">Data de Início</nz-form-label>
        <nz-form-control [nzSpan]="10" [nzErrorTip]="erroDataInicio">
          <nz-date-picker formControlName="cmpDataInicio" name="cmpDataInicio" nzFormat="dd/MM/yyyy">
          </nz-date-picker>
          <ng-template #erroDataInicio let-control>
            <ng-container *ngIf="control.hasError('required')">
              Campo obrigatório<br>
            </ng-container>
          </ng-template>
        </nz-form-control>
        <nz-form-label [nzSpan]="5">Data de Término</nz-form-label>
        <nz-form-control [nzSpan]="12" [nzErrorTip]="erroDataFim">
          <nz-date-picker formControlName="cmpDataFim" name="cmpDataFim" nzFormat="dd/MM/yyyy">
          </nz-date-picker>
          <ng-template #erroDataFim let-control>
            <ng-container *ngIf="control.hasError('required')">
              Campo obrigatório<br>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
        <nz-form-label [nzSpan]="5">Mídia</nz-form-label>
              <nz-form-control [nzSpan]="20" >
                <nz-select
                    formControlName="slaId"
                    nzPlaceHolder="Tipo Midia"
                    style="width:50%"
                    name="slaId"
                    (ngModelChange)="filtraEncarte($event)"
                    >
                    <nz-option
                    *ngFor="let items of tipoMidia" [nzValue]="items.id"
                        [nzLabel]="items.descricao">
                    </nz-option>
                </nz-select>
              </nz-form-control>
          </nz-form-item>
      <!--Inicio-->

      <div *ngIf="campanhaencarteArray.controls.length > 0" style="background: #ECECEC;padding:8px;">
        <div *ngFor="let campanhasencarte of campanhaencarteArray.controls; let i = index;"
          formArrayName="campanhasEncarte">
          <nz-card [formGroupName]='i'>
            <div class="flexDisplay centralizaVertical">
              <div class="containerOrder">
                <div class="containerOrder">
                  <nz-form-item>
                    <nz-form-label [nzSpan]="3">Loja</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                      <nz-select style="width: 500px;" formControlName="lojId" nzPlaceHolder="Loja" name="lojId">
                        <nz-option *ngFor="let items of loja" [nzValue]="items.id" [nzLabel]="items.lojNomeFantasia">
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label [nzSpan]="3">Encarte</nz-form-label>
                    <nz-form-control [nzSpan]="12">
                      <nz-select style="width: 500px;" formControlName="encId" nzPlaceHolder="Encarte" name="encId">
                        <nz-option *ngFor="let items of encarte" [nzValue]="items.id" [nzLabel]="items.encDescricao">
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label [nzSpan]="5">Quantidade de folhetos</nz-form-label>
                    <nz-form-control [nzSpan]="12" [nzErrorTip]="errocmeQuantidade">
                      <input nz-input formControlName="cmeQuantidade" name="cmeQuantidade" class="input-field"
                        placeholder="00" size="5" />
                      <ng-template #errocmeQuantidade let-control>
                        <ng-container *ngIf="control.hasError('required')">
                          Campo obrigatório<br>
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <button nz-button nzType="link" style="color:red" (click)="deleteCampanhaEncarte(i)">Remover
                  Loja</button>
              </div>
            </div>
          </nz-card>
        </div>

      </div>
      <p></p>
      <p align="center">
        <button nz-button nzType="secondary" (click)="addCampanhaEncarte()">Adicionar Loja</button>
      </p>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">Seleção de Produtos</nz-form-label>
        <nz-form-control [nzSpan]="12" [nzErrorTip]="erroDataInicio">
          <nz-date-picker nzShowTime nzFormat="dd/MM/yyyy HH:mm" formControlName="cmpDataProduto" name="cmpDataProduto">
          </nz-date-picker>
          <ng-template #erroDataInicio let-control>
            <ng-container *ngIf="control.hasError('required')">
              Campo obrigatório<br>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">Aprovação Gerentes</nz-form-label>
        <nz-form-control [nzSpan]="12" [nzErrorTip]="erroDataInicio">
          <nz-date-picker nzShowTime nzFormat="dd/MM/yyyy HH:mm" formControlName="cmpDataGerencia"
            name="cmpDataGerencia">
          </nz-date-picker>
          <ng-template #erroDataInicio let-control>
            <ng-container *ngIf="control.hasError('required')">
              Campo obrigatório<br>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">Aprovação Diretoria</nz-form-label>
        <nz-form-control [nzSpan]="12" [nzErrorTip]="erroDataInicio">
          <nz-date-picker nzShowTime nzFormat="dd/MM/yyyy HH:mm" formControlName="cmpDataDiretoria"
            name="cmpDataDiretoria">
          </nz-date-picker>
          <ng-template #erroDataInicio let-control>
            <ng-container *ngIf="control.hasError('required')">
              Campo obrigatório<br>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">Envio para Impressão</nz-form-label>
        <nz-form-control [nzSpan]="12" [nzErrorTip]="erroDataInicio">
          <nz-date-picker nzShowTime nzFormat="dd/MM/yyyy HH:mm" formControlName="cmpDataImpressao"
            name="cmpDataImpressao">
          </nz-date-picker>
          <ng-template #erroDataInicio let-control>
            <ng-container *ngIf="control.hasError('required')">
              Campo obrigatório<br>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">Aprovar lojas em grupo</nz-form-label>
        <nz-form-control [nzSpan]="12" [nzErrorTip]="erroGrupo">
          <nz-select
              formControlName="cmpAprovarGrupo"
              style="width:50%"
              name="cmpAprovarGrupo">
              <nz-option nzValue="S" nzLabel="Sim" ></nz-option>
              <nz-option nzValue="N" nzLabel="Não" ></nz-option>
          </nz-select>
          <ng-template #erroGrupo let-control>
            <ng-container *ngIf="control.hasError('required')">
              Campo obrigatório<br>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <p align="right">
        <button *ngIf="isEdit()" class="form-button" nz-button nzType="link" (click)="duplicar()">Duplicar
          Campanha</button>
        <button class="form-button" nz-button nzType="link" (click)="cancelar()">Cancelar</button>
        <button class="form-button" [disabled]="!campanhaForm.valid" nz-button nzType="primary"
          (click)="salvarCampanha()">Gravar</button>
      </p>
    </form>
  </ng-container>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

</div>
