<nz-page-header class="site-page-header" nzTitle="Encartes" nzSubtitle="Encartes cadastrados">
</nz-page-header>
<div>
    <ng-container #customLoadingTemplate>
        <form nz-form [formGroup]="formBusca">
            <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
                <nz-form-label [nzSpan]="5">Buscar</nz-form-label>
                <nz-form-control [nzSpan]="20">
                    <input
                            style="width: 100%;"
                            nz-input
                            formControlName="filtro"
                            name="filtro"
                            (ngModelChange)="buscar()"
                            />
                </nz-form-control>
                <nz-form-control [nzSpan]="5">
                  <label nz-checkbox (ngModelChange)="buscarArquivado()" formControlName="arquivado">
                      Arquivado
                    </label>
                  </nz-form-control>
            </nz-form-item>
        </form>
        <div>
            <nz-table [nzData]="listaEncartes" #rowSelectionTable>
                <thead>
                    <tr>
                        <th>Nome do encarte</th>
                        <th width="200px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let encarte of rowSelectionTable.data;">
                        <td>{{encarte.senDescricao}}</td>
                        <td>
                            <a (click)="editEncarte(encarte.id)">Editar</a>&nbsp;
                            <a nz-popconfirm nzPopconfirmTitle="Deseja remover esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="deleteEncarte(encarte.id)">Remover</a>&nbsp;
                            <a nz-popconfirm nzPopconfirmTitle="Deseja arquivar esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="arquivar(encarte)">Arquivar</a>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </ng-container>
</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Nome do template" (nzOnCancel)="handleCancel()" (nzOnOk)="save()">
    <ng-container *nzModalContent>
        <form [formGroup] = "form" style="width: 100%;">
            <nz-form-item style="margin-bottom: 3px;">
                <nz-form-label [nzSpan]="24" nzFor="stpNome" style="margin-bottom: -15px;">Nome do template</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="Informe o nome do template" nzSize="large" formControlName="stpNome" style="width: 100%;"/>
                    <ng-template #userErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">Campo obrigatório</ng-container>
                      </ng-template>
                </nz-form-control>
            </nz-form-item >   
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzFor="pagseSize" style="margin-bottom: -15px;">Tamanho do template em (mm)</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="Tamanho do template: 700x800" nzSize="large" formControlName="pageSize" style="width: 100%;"/>
                    <ng-template #userErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">Campo obrigatório</ng-container>
                      </ng-template>
                </nz-form-control>
            </nz-form-item >             
   
        </form>
    </ng-container>
</nz-modal>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<br>
<p align="right">
    <button class="form-button" nz-button nzType="primary" (click)="criarEncarte()">Novo</button>
</p>
