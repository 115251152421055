<div class="container">

    <div class="header">
        <img src="assets/InCart.png" width="65"/>
        <button 
            nz-popover 
            [nzPopoverContent]="contentFile" 
            nzPopoverTrigger="click"
            nzPopoverPlacement="bottom"
            class="button_background"
            nz-tooltip
            nzTooltipTitle="Gerenciar documento"
            nzTooltipPlacement="top"
            *ngIf="showElements"
        >
            <img src="assets/svg/new.svg" width="25"/>
        </button>
        
        <div class="vertical_divider divider_properties"></div>

        <button 
            *ngIf="showElements[6].element === 'mover_topo' && showElements[6].status"
            (click)="moveToTopSelected()" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Mover para topo"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/move_top.svg" width="25"/>
        </button>

        <button 
            *ngIf="showElements[7].element === 'mover_baixo' && showElements[7].status"
            (click)="moveToBottomSelected()" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Mover para baixo"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/move_bottom.svg" width="25"/>
        </button>

        <div class="vertical_divider divider_properties"></div>

        <button 
            *ngIf="showElements[8].element === 'apagar' && showElements[8].status"
            (click)="deleteElement()" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Apagar elemento(s)"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/delete.svg" width="25"/>
        </button>

        <button (click)="clearCanva()" 
            *ngIf="showElements[9].element === 'limpar' && showElements[9].status"
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Limpar tela"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/new.svg" width="25"/>
        </button>

        <div class="vertical_divider divider_properties"></div>

        <button 
            *ngIf="showElements[10].element === 'agrupar' && showElements[10].status"
            (click)="clickGroup()" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Agrupar"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/group_elements.svg" width="25"/>
        </button>

        <button 
            *ngIf="showElements[11].element === 'desagrupar' && showElements[11].status"
            (click)="clickUnGroup()" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Desagrupar"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/ungroup.svg" width="25"/>
        </button>

        <div class="vertical_divider divider_properties"></div>

        <button 
            (click)="createCustomButtom('amélia albaniza','purple', 'white', 'rect',150,65,20)" 
            class="button_background"
            nz-tooltip
            nzTooltipTitle="Customizar botão"
            nzTooltipPlacement="bottom"
            *ngIf="showElements[0].element === 'botao' && showElements[0].status"
        >
            Botão
        </button>

        <div class="vertical_divider divider_properties" *ngIf="showElements[0].element === 'botao' && showElements[0].status"></div>
        
        <button 
            *ngIf="showElements[12].element === 'refazer' && showElements[12].status"
            (click)="clickUndo()" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Desfazer"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/undo.svg" width="25"/>
        </button>

        <button 
            *ngIf="showElements[13].element === 'desfazer' && showElements[13].status"
            (click)="clickRedo()" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Refazer"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/redo.svg" width="25"/>
        </button>

        <div class="vertical_divider divider_properties"></div>

        <button 
            *ngIf="showElements[14].element === 'alinhamento' && showElements[14].status"
            nz-popover 
            [nzPopoverContent]="contentAlign" 
            nzPopoverTrigger="click"
            nzPopoverPlacement="bottom"
            class="button_background"
            nz-tooltip
            nzTooltipTitle="Ferramenta de alinhamento"
            nzTooltipPlacement="top"
        >
            <img src="assets/svg/align.svg" width="25"/>
        </button>

        <nz-select 
            *ngIf="showElements[15].element === 'objetos' && showElements[15].status"
            nzShowSearch 
            nzAllowClear 
            nzPlaceHolder="Escolha uma seleção para os objetos" 
            [(ngModel)]="alignReference"
            nz-tooltip
            nzTooltipTitle="Alinhar em relação ao tipo de elemento"
            nzTooltipPlacement="right"
        >
            <nz-option nzLabel="Objetos selecionados" nzValue="selected"></nz-option>
            <nz-option nzLabel="Menor objeto" nzValue="smalest"></nz-option>
            <nz-option nzLabel="Maior objeto" nzValue="largest"></nz-option>
            <nz-option nzLabel="Página" nzValue="page"></nz-option>
        </nz-select>

        <div class="vertical_divider divider_properties" style="margin: 0 5px;"></div>

        <nz-select 
            *ngIf="showElements[16].element === 'fonte' && showElements[16].status"
            nzShowSearch 
            nzPlaceHolder="Escolher fonte" 
            [(ngModel)]="fontFamily"
            nz-tooltip
            (ngModelChange)="setTextFont($event)"
            nzTooltipTitle="Escolher fonte"
            nzTooltipPlacement="right"
        >
            <nz-option nzLabel="Futura" nzValue="Futura"></nz-option>
            <nz-option nzLabel="Verdana" nzValue="Verdana"></nz-option>
            <nz-option nzLabel="Arial" nzValue="Arial"></nz-option>
            <nz-option nzLabel="Times" nzValue="Times"></nz-option>
            <nz-option nzLabel="Open Sans" nzValue="Open Sans"></nz-option>
            <nz-option nzLabel="Caladea" nzValue="Caladea"></nz-option>
            <nz-option nzLabel="Montserrat" nzValue="Montserrat"></nz-option>
        </nz-select>

        <div class="vertical_divider divider_properties" style="margin: 0 5px;" *ngIf="showElements[16].status"></div>

        <button 
            *ngIf="showElements[17].element === 'texto_esquerda' && showElements[17].status"
            (click)="setTextAlign('alignment-baseline','left')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Alinhar Texto à Esquerda"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/textalign-justifyleft-svgrepo-com.svg" width="25"/>
        </button>

        <button 
            *ngIf="showElements[18].element === 'texto_centro' && showElements[18].status"
            (click)="setTextAlign('alignment-baseline','middle')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Alinhar Texto ao Centro"
            nzTooltipPlacement="bottom"
            style="color:yellow"
        >
            <img src="assets/svg/textalign-center-svgrepo-com.svg" width="25" style="color:yellow"/>
        </button>

        <button 
            *ngIf="showElements[19].element === 'texto_direita' && showElements[19].status"
            (click)="setTextAlign('alignment-baseline','right')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Alinhar Texto à Direita"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/textalign-justifyright-svgrepo-com.svg" width="25"/>
        </button>

        <div 
            class="vertical_divider divider_properties" style="margin: 0 5px;"
            *ngIf="showElements[17].status && showElements[18].status && showElements[19].status"
        ></div>

        <nz-input-number 
            style="width: 60px;"
            *ngIf="showElements[20].element === 'tamanho_fonte' && showElements[20].status"
            [(ngModel)]="fontSize" 
            [nzMin]="1" 
            [nzStep]="1" 
            (ngModelChange)="changeFontSize($event)"
        ></nz-input-number> 

        <div class="vertical_divider divider_properties" style="margin: 0 5px;"></div>

        <nz-select 
            *ngIf="showElements[21].element === 'densidade_fonte' && showElements[21].status"
            nzShowSearch 
            nzPlaceHolder="Densidade fonte" 
            [(ngModel)]="textWeight"
            nz-tooltip
            (ngModelChange)="setTextWeight($event)"
            nzTooltipTitle="Densindade fonte"
            nzTooltipPlacement="right"
        >
            <nz-option nzLabel="Normal" nzValue="normal"></nz-option>
            <nz-option nzLabel="Bold" nzValue="bold"></nz-option>
            <nz-option nzLabel="Bolder" nzValue="bolder"></nz-option>
            <nz-option nzLabel="Lighter" nzValue="lighter"></nz-option>
        </nz-select>

        <div class="vertical_divider divider_properties" style="margin: 0 5px;"></div>

        <input
            *ngIf="showElements[35].element === 'page_size' && showElements[35].status"
            nz-input 
            placeholder="Tamanho" 
            [(ngModel)]="setPageSize" 
            style="font-size: '12px'; width: '70px';"
            nz-tooltip
            nzTooltipTitle="Defina o tamanho da página em mm."
            (blur)="definePegeSize()"
        />

        <div class="vertical_divider divider_properties" style="margin: 0 5px;" *ngIf="show_width_height"></div>

        <span style="font-size: 12px; color:white; font-weight: 700;" *ngIf="show_width_height">
            L: {{this.element_width}} x A: {{this.element_height}}
        </span>

        <div class="vertical_divider divider_properties" style="margin: 0 5px;" *ngIf="show_posicao_xy"></div>
        
        <span style="font-size: 12px; color:white; font-weight: 700;" *ngIf="show_posicao_xy">
            X: {{this.element_x}} Y: {{this.element_y}}
        </span>
        
    
    </div>

    <div class="body" id="body">

        <div class="leftpanel" id="leftpanel">

            <div class="leftMenuPanel" id="leftmenupanel">
                <div class="horizontal_divider divider_properties" *ngIf="showElements[22].element === 'escolher_marcas' && showElements[22].status"></div>

                <button 
                    *ngIf="showElements[22].element === 'escolher_marcas' && showElements[22].status"
                    (click)="showModal()" 
                    class="button_background"
                    nz-tooltip
                    nzTooltipTitle="Escolher marcas padrão"
                    nzTooltipPlacement="bottom"
                >
                    <img src="assets/svg/checklist_list.svg" width="25"/>
                </button>

                <div class="horizontal_divider divider_properties"></div>

                <button 
                    *ngIf="showElements[23].element === 'ferramenta_quadrado' && showElements[23].status"
                    nz-popover 
                    [nzPopoverContent]="contentSquare" 
                    nzPopoverTrigger="click"
                    nzPopoverPlacement="right"
                    class="button_background"
                    nz-tooltip
                    nzTooltipTitle="Ferramenta quadrado/retângulo"
                    nzTooltipPlacement="right"
                >
                    <img src="assets/svg/square.svg" width="25"/>
                </button>

                <button 
                    *ngIf="showElements[24].element === 'ferramenta_circulo' && showElements[24].status"
                    nz-popover 
                    [nzPopoverContent]="contentCircle" 
                    nzPopoverTrigger="click"
                    nzPopoverPlacement="right"
                    nz-tooltip
                    nzTooltipPlacement="bottomLeft"
                    class="button_background"
                    nz-tooltip
                    nzTooltipTitle="Ferramenta círculo/elípse"
                    nzTooltipPlacement="right"
                >
                    <img src="assets/svg/circle.svg" width="25"/>
                </button>
            
                <div class="horizontal_divider divider_properties"></div>
            
                <button 
                    *ngIf="showElements[25].element === 'ferramenta_path' && showElements[25].status"
                    (click)="createSvgElement('fhpath')" 
                    class="button_background" 
                    nz-tooltip
                    nzTooltipTitle="Lápis"
                    nzTooltipPlacement="right"
                >
                    <img src="assets/svg/pencil.svg" width="25"/>
                </button>

                <button 
                    *ngIf="showElements[26].element === 'ferramenta_linha' && showElements[26].status"
                    (click)="createSvgElement('line')" 
                    class="button_background" 
                    nz-tooltip
                    nzTooltipTitle="Linha"
                    nzTooltipPlacement="right"
                >
                    <img src="assets/svg/pen.svg" width="25"/>
                </button>
            
                <button 
                    *ngIf="showElements[27].element === 'ferramenta_contorno' && showElements[27].status"
                    (click)="createSvgElement('path')" 
                    class="button_background" 
                    nz-tooltip
                    nzTooltipTitle="Contorno"
                    nzTooltipPlacement="right"
                >
                    <img src="assets/svg/path.svg" width="25"/>
                </button>

                <button 
                    *ngIf="showElements[28].element === 'ferramenta_clone' && showElements[28].status"
                    (click)="clone()" 
                    class="button_background" 
                    nz-tooltip
                    nzTooltipTitle="Clonar"
                    nzTooltipPlacement="right"
                >
                    <img src="assets/svg/clone.svg" width="25"/>
                </button>

                <button 
                    *ngIf="showElements[29].element === 'ferramenta_texto' && showElements[29].status"
                    (click)="createSvgElement('text')" 
                    class="button_background" 
                    nz-tooltip
                    nzTooltipTitle="Texto"
                    nzTooltipPlacement="right"
                >
                    <img src="assets/svg/text.svg" width="25"/>
                </button>

                <div class="horizontal_divider divider_properties"></div>

                <button 
                    *ngIf="showElements[30].element === 'ferramenta_imagem' && showElements[30].status"
                    (click)="importImage($event)" 
                    class="button_background" 
                    nz-tooltip
                    nzTooltipTitle="Imagem"
                    nzTooltipPlacement="right"
                >
                    <img src="assets/svg/image.svg" width="25"/>
                </button>

                <button 
                    *ngIf="showElements[31].element === 'add_page' && showElements[31].status"
                    (click)="addPage()" 
                    class="button_background" 
                    nz-tooltip
                    nzTooltipTitle="Imagem"
                    nzTooltipPlacement="right"
                >
                    addpage
                </button>

            </div>

            <div class="productsPanel" *ngIf="isProductBar">
                <div class="templatePanel">
                    Template
                    <nz-select 
                        nzShowSearch 
                        nzAllowClear 
                        nzPlaceHolder="Escolher modelo" 
                        [(ngModel)]="idCard"
                        (ngModelChange)="getTemplate($event)"
                        nz-tooltip
                        nzTooltipTitle="Escolha um modelo padrão"
                        nzTooltipPlacement="bottom"
                        style="width: 160px;"
                    >
                        <nz-option *ngFor="let option of listaTemplates" [nzLabel]="option.stpNome" [nzValue]="option.id" onchange="getTemplate(option.id)"></nz-option>
                    </nz-select>
                </div>
                <div class="products">
                    <div class="headproducttitle">
                        Produtos disponíveis
                        <nz-input-group nzSuffix="suffixIconSearch">
                            <input 
                                type="text" 
                                [(ngModel)] = "productTextFilter"
                                style="width: 160px; color:black" 
                                nz-input placeholder="Pesquisar produtos"
                                (keyup)="findProduct()"
                            />
                        </nz-input-group>
                    </div>
                    <div class="containerProduct">

                        <div class="productbox" *ngFor="let product of produto_formatado" (click)="addProduct(product)">
                            <div class="productimage" *ngIf="false">
                                <img src="{{product.image}}" />
                            </div>
                            <div class="productdescription">{{labelItem(product)}}</div>
                        </div>

                    </div>
                    
                </div>
            </div>

        </div>

        <div class="page" style="padding-top: 35px;">
            <div id="workarea">
                <div #canvas class="canvas" id="myCanvas"></div>
            </div>

            <div class="pagination">
                <nz-pagination 
                [nzPageIndex]="1" 
                [nzTotal]="pageQuantity" 
                [nzPageSize]="1"
                nzShowQuickJumper 
                (nzPageIndexChange)="goToPage($event)"
                ></nz-pagination>

                <nz-divider nzType="vertical"></nz-divider>

                <button 
                    *ngIf="listOfPages && listOfPages.length > 0 && origem === 'encarte' "
                    nz-button nzType="primary" 
                    [nzSize]="buttomsize"
                    (click)="addPage()" 
                    nz-tooltip
                    nzTooltipTitle="Nova página"
                    nzTooltipPlacement="top"
                ><span style="font-size: 25px; margin-top: -8px; width: 5px; margin-left: -10px;">+</span></button>

                <div *ngIf="listOfPages.length > 1 && origem === 'encarte'" style="display: flex; flex-direction: row; gap: 2px; align-content: center; align-items: center;">
                    <nz-input-number 
                        [nzMin]="0" [nzStep]="1" 
                        style="width: 70px;"
                        nz-tooltip
                        [(ngModel)]="pagina_exclusao"
                        nzTooltipTitle="Número da página para exclusão"
                    ></nz-input-number>
                    <button 
                        nz-button nzType="primary" 
                        [nzSize]="buttomsize"
                        nzDanger
                        (click)="removePage()" 
                        nz-tooltip
                        nzTooltipTitle="Excluir página"
                        nzTooltipPlacement="top"
                    ><span style="font-size: 25px; margin-top: -9px; width: 5px; margin-left: -5px;">-</span></button>
                </div>
                

               
            </div>
            
        </div>
        
    </div>

    <div class="bottom">

        <ngx-colors 
            *ngIf="showElements[32].element === 'fill_color' && showElements[32].status"
            ngx-colors-trigger 
            [(ngModel)]="color" 
            (ngModelChange)="fillColor('fill')" 
            nz-tooltip
            nzTooltipTitle="Cor preenchimento elemento(s)"
            nzTooltipPlacement="right"></ngx-colors>
            
        <div class="vertical_divider divider_properties"></div>

        <ngx-colors 
            *ngIf="showElements[33].element === 'fill_stroke' && showElements[33].status"
            ngx-colors-trigger 
            [(ngModel)]="colorStroke" 
            (ngModelChange)="fillColor('stroke')" 
            nz-tooltip
            nzTooltipTitle="Cor borda elemento(s)"
            nzTooltipPlacement="right"></ngx-colors>

        <nz-input-number 
            *ngIf="showElements[34].element === 'set_stroke' && showElements[34].status"
            [(ngModel)]="defaultStroke" 
            [nzMin]="0" 
            [nzStep]="1" 
            (ngModelChange)="setStrokeWidth($event)"
            ></nz-input-number>

        <div class="vertical_divider divider_properties" style="margin: 0 5px;"></div>

        <nz-select 
            *ngIf="showElements[37].element === 'zoom' && showElements[37].status"
            nzShowSearch 
            nzPlaceHolder="Zoom" 
            [(ngModel)]="fatorZoom"
            nz-tooltip
            (ngModelChange)="zoom($event)"
            nzTooltipTitle="Densindade fonte"
            nzTooltipPlacement="right"
        >
            <nz-option nzLabel="10%" nzValue="10"></nz-option>
            <nz-option nzLabel="20%" nzValue="20"></nz-option>
            <nz-option nzLabel="30%" nzValue="30"></nz-option>
            <nz-option nzLabel="40%" nzValue="40"></nz-option>
            <nz-option nzLabel="50%" nzValue="50"></nz-option>
            <nz-option nzLabel="60%" nzValue="60"></nz-option>
            <nz-option nzLabel="70%" nzValue="70"></nz-option>
            <nz-option nzLabel="80%" nzValue="80"></nz-option>
            <nz-option nzLabel="90%" nzValue="90"></nz-option>
            <nz-option nzLabel="100%" nzValue="100"></nz-option>
            <nz-option nzLabel="115%" nzValue="115"></nz-option>
            <nz-option nzLabel="130%" nzValue="130"></nz-option>
            <nz-option nzLabel="145%" nzValue="145"></nz-option>
            <nz-option nzLabel="160%" nzValue="160"></nz-option>
            <nz-option nzLabel="175%" nzValue="175"></nz-option>
            <nz-option nzLabel="190%" nzValue="190"></nz-option>
            <nz-option nzLabel="200%" nzValue="200"></nz-option>
            <nz-option nzLabel="300%" nzValue="300"></nz-option>
            <nz-option nzLabel="400%" nzValue="400"></nz-option>
            <nz-option nzLabel="500%" nzValue="500"></nz-option>
            <nz-option nzLabel="600%" nzValue="600"></nz-option>
            <nz-option nzLabel="700%" nzValue="700"></nz-option>
            <nz-option nzLabel="800%" nzValue="800"></nz-option>
            <nz-option nzLabel="900%" nzValue="900"></nz-option>
            <nz-option nzLabel="1000%" nzValue="1000"></nz-option>
        </nz-select>

        

    </div>
</div>



<input id="text" size="35" hidden="true"  />


<ng-template #contentFile>
    <form nz-form [formGroup]="form">
        <div class="menu_vertical">
            <button 
                *ngIf="showElements[36].element === 'novo_arquivo' && showElements[36].status"
                (click)="createPage()" 
                class="button_background" 
                nz-tooltip
                nzTooltipTitle="Nova página"
                nzTooltipPlacement="right"
            >
                <img src="assets/svg/new.svg" width="25"/> Novo
            </button>
            <button 
                *ngIf="showElements[1].element === 'abrir' && showElements[1].status"
                (click)="openFile()" 
                class="button_background" 
                nz-tooltip
                nzTooltipTitle="Abrir"
                nzTooltipPlacement="right"
            >
                <img src="assets/svg/open.svg" width="25"/> Abrir
            </button>
            <button 
                *ngIf="showElements[3].element === 'salvar_servidor' && showElements[3].status"
                (click)="openModalSaveCard()" 
                class="button_background" 
                nz-tooltip
                nzTooltipTitle="Salvar"
                nzTooltipPlacement="right"
            >
                <img src="assets/svg/saveicon.svg" width="25" style="filter: invert(.5) sepia(1) saturate(9) hue-rotate(0deg);"/> Salvar
            </button>
            <button 
                *ngIf="showElements[5].element === 'salvar_local' && showElements[5].status && tipo === 'svg/encarte/:id'"
                (click)="export()" 
                class="button_background" 
                nz-tooltip
                nzTooltipTitle="Exportar"
                nzTooltipPlacement="right"
            >
                <img src="assets/svg/export.svg" width="25"/> Exportar
            </button>
            <button 
                *ngIf="showElements[40].element === 'salvar_pdf' && showElements[40].status && tipo === 'svg/encarte/:id'"
                (click)="exportarEncarte()" 
                class="button_background" 
                nz-tooltip
                nzTooltipTitle="Exportar PDF"
                nzTooltipPlacement="right"
            >
                <img src="assets/svg/export.svg" width="25"/> Exportar PDF
            </button>
            <input
                type="file"
                (change)="uploadArquivoXlx($event.target.files, $event)"
                style="margin-bottom: 1rem; border-style:none; display:none"
                id="excel"
                class="custom-file-input"
                accept=".csv">

            <label 
                *ngIf="!form.value.seaArquivo && tipo === 'svg/encarte/:id'" class="labelButtonUpload" for="excel" 
                nz-tooltip
                style="cursor: pointer; margin-left: 10px;"
                nzTooltipTitle="Enviar arquivo CSV. Arquivo deve ser criado com separação de ponto-e-vírgula. Arquivo deve ser exportado no Excel como CSV UTF-8"
                nzTooltipPlacement="right"
            >
                <img src="assets/svg/uploadicon.svg" width="18"/> Upload CSV
            </label>
            <label *ngIf="form.value.seaArquivo" class="labelButtonUploadUpdate" for="excel" style="margin-left: 10px;">
                {{nameFileUpload}}
            </label>
            <button 
                *ngIf="showElements[38].element === 'upload' && showElements[38].status && form.value.seaArquivo && tipo === 'svg/encarte/:id'"
                (click)="enviaArquivosUpload()" 
                class="button_background"
                style="margin-left: 4px;"
            >
                <img src="assets/svg/uploadicon.svg" width="18"/> Upload
            </button>
            <button 
                *ngIf="showElements[39].element === 'download' && showElements[39].status && tipo === 'svg/encarte/:id'"
                (click)="downloadData()" 
                class="button_background" 
                nz-tooltip
                nzTooltipTitle="Baixar arquivo CSV"
                nzTooltipPlacement="right"
                style="margin-left: 4px;"
            >
                <img src="assets/svg/downloadicon.svg" width="18"/> Baixar CSV
            </button>
            <button 
                *ngIf="showElements[2].element === 'importar' && showElements[2].status"
                (click)="importImage($event)" 
                class="button_background" 
                nz-tooltip
                nzTooltipTitle="Importar"
                nzTooltipPlacement="right"
            >
                <img src="assets/svg/importImg.svg" width="25"/> Importar
            </button>
            <button 
                *ngIf="showElements[4].element === 'exportar' && showElements[4].status && tipo === 'svg/encarte/:id'"
                (click)="clickExport($event)" 
                class="button_background" 
                nz-tooltip
                nzTooltipTitle="Exportar"
                nzTooltipPlacement="right"
            >
                <img src="assets/svg/export.svg" width="25"/> Exportar
            </button>
        </div>
    </form>
</ng-template>


<ng-template #contentAlign>
    <div class="menu_horizontal">
        <button 
            (click)="clickAlign('l')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Alinhar à esquerda"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/align_left.svg" width="25"/>
        </button>
        <button 
            (click)="clickAlign('r')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Alinhar à direita"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/align_right.svg" width="25"/>
        </button>
        <button 
            (click)="clickAlign('t')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Alinhar no topo"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/align_top.svg" width="25"/>
        </button>
        <button 
            (click)="clickAlign('b')" 
            class="button_background" 
            title="Alinha na base"
            nz-tooltip
            nzTooltipTitle="Alinha na base"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/align_bottom.svg" width="25"/>
        </button>
        <button 
            (click)="clickAlign('c')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Alinha no centro"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/align_center.svg" width="25"/>
        </button>
        <button 
            (click)="clickAlign('m')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Alinhar no meio"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/align_middle.svg" width="25"/>
        </button>
        <button 
            (click)="clickAlign('dh')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Distribuir na horizontalmente"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/align_distrib_horiz.svg" width="25"/>
        </button>
        <button 
            (click)="clickAlign('dv')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Distribuir na verticalmente"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/align_distrib_verti.svg" width="25"/>
        </button>
    </div>
</ng-template>


<ng-template #contentSquare>
    <div class="menu_horizontal">
        <button 
            (click)="createSvgElement('square')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Quadrado"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/square.svg" width="25"/>
        </button>
        <button 
            (click)="createSvgElement('rect')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Retângulo"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/rect.svg" width="25"/>
        </button>
        <button 
            (click)="createSvgElement('fhrect')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Retângulo mão livre"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/fh_rect.svg" width="25"/>
        </button>
    </div>
</ng-template>


<ng-template #contentCircle>
    <div class="menu_horizontal">
        <button 
            (click)="createSvgElement('circle')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Círculo"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/circle.svg" width="25"/>
        </button>
        <button 
            (click)="createSvgElement('ellipse')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Elípse"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/ellipse.svg" width="25"/>
        </button>
        <button 
            (click)="createSvgElement('fhellipse')" 
            class="button_background" 
            nz-tooltip
            nzTooltipTitle="Elípse mão livre"
            nzTooltipPlacement="bottom"
        >
            <img src="assets/svg/fh_ellipse.svg" width="25"/>
        </button>
    </div>
</ng-template>


<ng-template #contentPaint>
    <div class="menu_horizontal">
        <ngx-colors 
            ngx-colors-trigger 
            [(ngModel)]="color" 
            (ngModelChange)="fillColor('fill')" 
            nz-tooltip
            nzTooltipTitle="Cor preenchimento elemento(s)"
            nzTooltipPlacement="right"></ngx-colors>
    </div>
</ng-template>

<ng-template #contentStroke>
    <div class="menu_horizontal">
        <ngx-colors 
                ngx-colors-trigger 
                [(ngModel)]="colorStroke" 
                (ngModelChange)="fillColor('stroke')" 
                nz-tooltip
                nzTooltipTitle="Cor borda elemento(s)"
                nzTooltipPlacement="right"></ngx-colors>
        <nz-input-number 
            [(ngModel)]="defaultStroke" 
            [nzMin]="0" 
            [nzStep]="1" 
            (ngModelChange)="setStrokeWidth($event)"
        ></nz-input-number> 
    </div>
</ng-template>


<nz-modal [(nzVisible)]="isVisible" nzTitle="Escolher marcação padrão" (nzOnCancel)="handleCancel()" [nzFooter]="null">
    <ng-container *nzModalContent>
        <form [formGroup] = "marker">
            <nz-label>Marcador padrão sistema</nz-label>
            <nz-select class="selectMarker" nzShowSearch formControlName="selectedMarker" (ngModelChange)="defineMarker()" nzPlaceHolder="Escolher marcador padrão">
                <nz-option nzValue="#image" nzLabel="Imagem"></nz-option>
            </nz-select>
            <nz-divider *ngIf="false"></nz-divider>
            <div class="containerAdd" *ngIf="false">
                <input type="text" formControlName="textAreaMarker" nz-input placeholder="Àrea para texto" style="margin-right: 3px;"/>
                <button nz-button nzType="primary" (click)="defineMarker('combo')">
                    Adicionar marcação
                </button>
            </div>
            <nz-divider></nz-divider>
            <nz-alert 
                nzType="info" 
                nzMessage="Defina sua marcação customizada. Utilize o nome da mesma marcação utilizada no arquivo Excel. Utilize # antes da marcação para textos padrão. Se precisar alinhar textos no centro do card, utilize o @ antes da marcação. Para alinhar textos centralizados em qualquer parte do card, utilize o $ antes da marcação." 
                nzShowIcon
                style="margin: 0px 8px;"
            ></nz-alert>
            <div class="containerAdd">
                <input type="text" formControlName="customMarker" nz-input #inputElement nz-input placeholder="Informar marcação" style="margin-right: 3px;"/>
                <button nz-button nzType="primary" (click)="defineMarker('combo',12)">
                    Adicionar marcação
                </button>
            </div>
               
        </form>
        
    </ng-container>
</nz-modal>


<nz-modal [(nzVisible)]="cardNameVisible" nzTitle="Nome do template" (nzOnCancel)="handleCancel()" (nzOnOk)="(origem === 'card') ? saveCard() : saveEncarte()">
    <ng-container *nzModalContent>
        <form [formGroup] = "cardName" style="width: 100%;">
            <nz-form-item>
                <nz-form-label [nzSpan]="24" nzFor="stpNome" style="margin-bottom: -15px;">Nome do template</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="Informe o nome do template" nzSize="large" formControlName="stpNome" style="width: 100%;"/>
                    <ng-template #userErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">Campo obrigatório</ng-container>
                      </ng-template>
                </nz-form-control>
            </nz-form-item >             
            <ng-template *modalFooter>
                <span>Modal Footer:</span>
                <button nz-button nzType="default" (click)="handleCancel()">Custom Callback</button>
                <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isConfirmLoading">Custom Submit</button>
            </ng-template>    
        </form>
    </ng-container>
</nz-modal>


<nz-modal [(nzVisible)]="isVisiblePageSize" nzTitle="Escolher tamanho área" (nzOnOk)="definePegeSize()" (nzOnCancel)="cancelarCriacaoCard()">
    <ng-container *nzModalContent>
        <form [formGroup] = "pageSize"><nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="stpNome" style="margin-bottom: -15px;">Nome do template</nz-form-label>
                <nz-form-control>
                    <input nz-input placeholder="Informe o nome do template" nzSize="large" formControlName="stpNome" style="width: 100%;"/>
                    <ng-template #userErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">Campo obrigatório</ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item >
            <nz-alert 
                    nzType="info" 
                    nzMessage="Escolha um tamanho na listagem abaixo." 
                    nzShowIcon
                    style="margin: 0px 0px 8px 0px;"
                ></nz-alert>
            
            <nz-select class="selectMarker" nzShowSearch formControlName="pageSize" [nzDropdownRender]="renderTemplate">
                <nz-option *ngFor="let item of listOfSizes" [nzLabel]="item" [nzValue]="item"></nz-option>
            </nz-select>

            
        </form>
    </ng-container>
</nz-modal>

<ng-template #renderTemplate>
    <nz-divider></nz-divider>
    <nz-alert 
        nzType="info" 
        nzMessage="Utilize o formato largura x Altura para definir medidas. Exemplo: 300x400. Se apenas uma medida for informada, a outra medida será igual." 
        nzShowIcon
        style="margin: 0px 8px;"
    ></nz-alert>
    <div class="containerAdd">
        
        <input type="text" nz-input #inputElement />
        <a class="add-item" (click)="addSize(inputElement)">
        Adicionar tamanho
        </a>
    </div>
</ng-template>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
