import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const baseURL = `${environment.api_url}`;

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private httpClient: HttpClient) { }


  create(data): Observable<any> {
    return this.httpClient.post(`${baseURL}/public/empresa/v1/nova`, data);
  }

  getCurrentInfo(): Observable<any> {
    return this.httpClient.get(`${baseURL}/empresa-adm/empresa/v1/recupera`);
  }

  listAtivas(): Observable<any> {
    return this.httpClient.get(`${baseURL}/admin/empresa/v1/listaAtivas`);
  }

  listAll(): Observable<any> {
    return this.httpClient.get(`${baseURL}/admin/empresa/v1/listAll`);
  }

  listInativas(): Observable<any> {
    return this.httpClient.get(`${baseURL}/admin/empresa/v1/listaPendentes`);
  }

  read(id): Observable<any> {
    return this.httpClient.get(`${baseURL}/admin/empresa/v1/recupera/${id}`);
  }

  ativa(id): Observable<any> {
    return this.httpClient.put(`${baseURL}/admin/empresa/v1/ativa/${id}`,id);
  }

  inativa(id): Observable<any> {
    return this.httpClient.put(`${baseURL}/admin/empresa/v1/inativa/${id}`,id);
  }


  update(data): Observable<any> {
    return this.httpClient.put(`${baseURL}/empresa/v1/empresa/atualiza`, data);
  }

  salvarSimplus(data): Observable<any> {
    return this.httpClient.put(`${baseURL}/admin/empresa/v1/atualiza-simplus`, data);
  }


  /* delete(id): Observable<any> {
    return this.httpClient.delete(`${baseURL}/empresa/v1/empresa/remove/${id}`);
  } */







}
