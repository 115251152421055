import { PendenciaCampanha } from '@models/pendenciacampanha';
import { Component, OnInit } from '@angular/core';
import { CampanhaService } from 'src/app/services/campanha.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-campanha-pendencia-list',
  templateUrl: './campanha-pendencia-list.component.html',
  styleUrls: ['./campanha-pendencia-list.component.css']
})

export class CampanhaPendenciaListComponent implements OnInit {

  pendenciasCampanha: PendenciaCampanha[];

  public loading:boolean = false;

  constructor(
    private campanhaService: CampanhaService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') != undefined)
      this.readCampanhas(this.route.snapshot.paramMap.get('id'));
  }

  readCampanhas(id): void {

    this.loading = true;
    this.campanhaService.listPending(id)
      .subscribe(
        campanhas => {
          this.loading = false;
          this.pendenciasCampanha = campanhas;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao enviar!');
        });
  }

  descricaoStatus(id): string {
    if (id == 'P') return 'Produto';
    if (id == 'G') return 'Gerência';
    if (id == 'D') return 'Diretoria';
  }

  cancelar(): void {
    this.router.navigateByUrl('/campanha/list');
  }


}
