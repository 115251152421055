import { Component, OnInit } from '@angular/core';
import { EmpresaService } from 'src/app/services/empresa.service';
import { Empresa } from 'src/app/models/empresa';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormArray, FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-empresa-list',
  templateUrl: './admin-empresa-list.component.html',
  styleUrls: ['./admin-empresa-list.component.css']
})

export class EmpresaListComponent implements OnInit {
  empresasRest: Empresa[];
  empresas: Empresa[];
  modo = null;
  filtroForm: UntypedFormGroup;

  public loading:boolean = false;

  constructor(
    private empresaService: EmpresaService,
    private router: Router,
    private toastr: ToastrService) {
      this.filtroForm = new UntypedFormGroup({
        filtro: new UntypedFormControl(null, null)
      });
    }

  ngOnInit(): void {
    if (this.router.url.indexOf('/inativas') > 0) {
      this.modo = 'Empresas pendentes'
      this.readEmpresasInativas();
    } else {
      this.modo = 'Empresas'
      this.readEmpresasAtivas();
    }
  }

  executaFiltro(): void {
    if (this.filtroForm.get('filtro').value == "") {
      this.empresas = this.empresasRest;
    } else {
      this.empresas = [];
      this.empresasRest.forEach(element => {
        if (element.empNomeFantasia != null &&
          element.empNomeFantasia.toUpperCase().indexOf(this.filtroForm.get('filtro').value.toUpperCase()) >=0 ) {
          this.empresas.push(element);
        }
      });
    }
  }
  readEmpresasAtivas(): void {
    this.empresaService.listAll()
      .subscribe(
        empresas => {
          this.empresas = empresas;
          this.empresasRest = empresas;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao listar Empresas Ativas!');
        });
  }

  readEmpresasInativas(): void {
    this.empresaService.listInativas()
      .subscribe(
        empresas => {
          this.empresas = empresas;
          this.empresasRest = empresas;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao listar Empresas Inativas!');
        });
  }

  editCurrentEmpresa(empresa): void {
    this.router.navigateByUrl('/empresa/form/' + empresa.id);
  }

}
