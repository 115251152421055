<div style="width: 100%; height: 100%;" *ngIf="isLandingPage">
  <router-outlet></router-outlet>
</div>

<nz-layout *ngIf="!isLandingPage" class="app-layout">
  <nz-sider *ngIf="isUserLoggedIn()" class="menu-sidebar" nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed" [nzTrigger]="null">
    
    <div style="width:210px; margin-left: 24px;margin-top: 24px;" *ngIf="!isCollapsed">
      <p align="center">
        <a href="/campanha/list"><img src="assets/InCart.png" height="80"></a>
      </p>
    </div>

    <div style="width:60px;margin-top: 24px;" *ngIf="isCollapsed">
      <p align="center">
        <a href="/campanha/list"><img src="assets/InCart.png" height="40"></a>
      </p>
    </div>

  
    
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed" *ngIf="!isCollapsed">

      <li *ngIf="checkPermissao('ROLE_ADMIN')" nz-submenu nzTitle="Back-office">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/empresa/list/ativas">Empresas</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/layout/list">Layouts</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/paginas/list">Tipos de Páginas</a>
          </li>
        </ul>
      </li>

      <li
        *ngIf="checkPermissao('ROLE_EMPRESA_GRUPO') || checkPermissao('ROLE_EMPRESA_USUARIO') || checkPermissao('ROLE_EMPRESA_SETOR') || checkPermissao('ROLE_EMPRESA_LOJA')"
        nz-submenu nzTitle="Configurações">
        <ul>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_GRUPO')">
            <a routerLink="/grupousuario/list">Grupos de Usuários</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_USUARIO')">
            <a routerLink="/usuario/list">Usuários</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_SETOR')">
            <a routerLink="/setor/list">Setores</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_LOJA')">
            <a routerLink="/loja/list">Lojas</a>
          </li>
        </ul>
      </li>
      <li
        *ngIf="checkPermissao('ROLE_EMPRESA_GRUPO') || checkPermissao('ROLE_EMPRESA_USUARIO') || checkPermissao('ROLE_EMPRESA_SETOR') || checkPermissao('ROLE_EMPRESA_LOJA')"
        nz-submenu nzTitle="SVG">
        <ul>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_USUARIO')">
            <a routerLink="/svg/cards">Cards</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_USUARIO')">
            <a routerLink="/svg/encartes">Encarte</a>
          </li>
        </ul>
      </li>
      <li *ngIf="checkPermissao('ROLE_EMPRESA_IMAGEM') || checkPermissao('ROLE_EMPRESA_ENCARTE')" nz-submenu
        nzTitle="Operações">
        <ul>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_IMAGEM')">
            <a routerLink="/imagem/list">Banco de Imagens</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_ENCARTE')">
            <a routerLink="/encarte/list">Encartes</a>
          </li>
        </ul>
      </li>
      <li
        *ngIf="checkPermissao('ROLE_EMPRESA_CAMPANHA') || checkPermissao('ROLE_EMPRESA_ARQUIVO') || checkPermissao('ROLE_EMPRESA_GERENTE') || checkPermissao('ROLE_EMPRESA_DIRETOR') || checkPermissao('ROLE_EMPRESA_PDF')"
        nz-submenu nzTitle="Campanhas">
        <ul>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_CAMPANHA')">
            <a routerLink="/campanha/list">Cadastro</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_CAMPANHA')">
            <a routerLink="/campanha/acompanhamento">Acompanhamento</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_ARQUIVO')">
            <a routerLink="/campanha-prod/list">Diagramador</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_GERENTE')">
            <a routerLink="/campanha-ger/list">Aprovação Gerência</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_DIRETOR')">
            <a routerLink="/campanha-dir/list">Aprovação Diretoria</a>
          </li>
          <li nz-menu-item nzMatchRouter *ngIf="checkPermissao('ROLE_EMPRESA_PDF')">
            <a routerLink="/config/files">Gerar Encarte</a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzTitle="Acesso">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/alterar-senha">Alterar senha</a>
          </li>
        </ul>
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/login">Sair</a>
          </li>
        </ul>
      </li>
    </ul>
    <div style="margin-left:0px;">
      <button nz-button nzType="text" (click)="toggleCollapsed()" style="margin-left: 22px;" nzValue="small" >
        <span *ngIf="isCollapsed"><img src="../assets/unfold.png" width="20" title="Ver menu"/></span>
        <span *ngIf="!isCollapsed"><img src="../assets/fold.png" width="20" title="Ocultar menu"/></span>
      </button>
    </div>
  </nz-sider>


  <div *ngIf="!isUserLoggedIn()" class="login-header">
    <div style="width:130px; padding-bottom: 1rem; margin-left: auto; margin-right: auto;">
      <a href="/">
        <img src="assets/InCart.png" height="96">
      </a>
    </div>
  </div>

  <nz-layout *ngIf="!isUserLoggedIn()">
    <div class="inner-content">
      <router-outlet></router-outlet>
    </div>
  </nz-layout>

  <nz-layout *ngIf="isUserLoggedIn()">
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>

  </nz-layout>



</nz-layout>
