import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Layout } from '@models/layout';

const baseURLEmpresa = `${environment.api_url}/empresa/v1/layout`;
const baseURLAdmin= `${environment.api_url}/admin/empresa/v1/layout`;

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  constructor(private http: HttpClient){}

  listAll(): Observable<Layout[]> {
    return this.http.get<Layout[]>(`${baseURLEmpresa}/lista`);
  }

  listTipoLayout(id: number): Observable<Layout[]> {
    return this.http.get<Layout[]>(`${baseURLEmpresa}/lista-tipo-layout/${id}`);
  }

  read(id: string): Observable<Layout> {
    return this.http.get<Layout>(`${baseURLEmpresa}/recupera/${id}`);
  }

  listAllAdm(): Observable<Layout[]> {
    return this.http.get<Layout[]>(`${baseURLAdmin}/lista`);
  }

  readAdm(id: string): Observable<any> {
    return this.http.get<Layout>(`${baseURLAdmin}/recupera/${id}`);
  }

  createAdm(data: Omit<Layout, 'id'>): Observable<any> {
    return this.http.post(`${baseURLAdmin}/novo`, data);
  }

  updateAdm(data: Partial<Layout>): Observable<any> {
    return this.http.put(`${baseURLAdmin}/atualiza`, data);
  }

  deleteAdm(id: string): Observable<any> {
    return this.http.delete(`${baseURLAdmin}/remove/${id}`);
  }
}
