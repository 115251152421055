export class Registro {
    empRazaoSocial: string;
    empNomeFantasia: string;
    empCNPJMatriz: string;
    logId: string;
    empNumeroMatriz: string;
    empComplementoMatriz: string;
    empDDDMatriz: string;
    empNumTelefoneMatriz: string;
    emlLogotipo: string;
    usuNome: string;
    usuLogin: string;
    usuSenha: string;
    empRua: string;
    plaId: string;
}
