<nz-page-header class="site-page-header" nzTitle="Imagens" nzSubtitle="Imagens cadastradas">
</nz-page-header>
<div>
    <ng-container #customLoadingTemplate>
        <div>
          <form nz-form [formGroup]="imagemForm">
            <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
              <nz-form-label [nzSpan]="5">Buscar imagem</nz-form-label>
              <nz-form-control [nzSpan]="20">
                  <input
                          style="width: 50%;"
                          nz-input
                          formControlName="filtro"
                          name="filtro" />
              </nz-form-control>
              <button nz-button nzType="primary"
                  (click)="buscar()">
                      Buscar
              </button>
            </nz-form-item>
            <div style="text-align:center">
              <input
                  type="file"
                  (change)="uploadArquivoZip($event.target.files)"
                  style="margin-bottom: 1rem; border-style:none; display:none"
                  id="zip"
                  accept=".zip">
                  <label class="labelButtonUpload" for="zip">
                      Inserir Arquivo ZIP
                  </label>&nbsp;
                  <label nz-checkbox formControlName="removerFundo">Remover fundo</label> 

            </div>
          </form>
            <nz-table [nzData]="imagens" #rowSelectionTable>
                <thead>
                    <tr>
                        <th></th>
                        <th nzShowSort="true" [nzSortFn]="sortColunaImgNome">Arquivo</th>
                        <th>Tamanho (KB)</th>
                        <th width="150px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let imagem of rowSelectionTable.data;">
                        <td><img src="{{imagem.base64Zip}}" style="max-width:70px;max-height:70px"></td>

                        <td>{{imagem.imgNome}}</td>
                        <td align="right">{{   (imagem.imgTamanho/10.24).toFixed(2)   }}</td>
                        <td>
                            <a (click)="verCurrentImagem(imagem)">Ver</a>&nbsp;
                            <a nz-popconfirm nzPopconfirmTitle="Deseja remover esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="removeImagem(imagem)">Remove</a>&nbsp;
                        </td>
                    </tr>
                </tbody>
            </nz-table>


        </div>
    </ng-container>

</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
