<nz-page-header class="site-page-header" nzTitle="Aprovação da Diretoria" nzSubtitle="Campanhas pendentes de aprovação">
</nz-page-header>
<div>
    <ng-container #customLoadingTemplate>
        <div>
            <nz-table [nzData]="campanhasencarte" #rowSelectionTable>
                <thead>
                    <tr>
                        <th width="30%" nzShowSort="true" [nzSortFn]="sortColunaCmpNome">Campanha</th>
                        <th width="30%">Loja</th>
                        <th width="30%">Data/Hora Limite</th>
                        <th width="150px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let campanhaencarte of rowSelectionTable.data;">
                      <td>{{campanhaencarte.cmpNome}}</td>
                        <td>{{campanhaencarte.lojNomeFantasia}}</td>
                        <td>{{campanhaencarte.cmpDataDiretoria | date: 'dd/MM/yyyy HH:mm'}}</td>
                        <td>
                            <a (click)="verCurrentCampanhaEncarte(campanhaencarte)">Detalhes</a>&nbsp;
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </ng-container>
</div>
