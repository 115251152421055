import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { Imagem } from '@models/imagem';

const baseURL = `${environment.api_url}/empresa/v1/imagem`;


@Injectable({
  providedIn: 'root'
})
export class ImagemService {

  constructor(private httpClient: HttpClient) { }

  listAll(): Observable<Imagem[]> {
    return this.httpClient.get<Imagem[]>(`${baseURL}/lista`);
  }

  read(id: string, resize: number): Observable<Imagem> {
    return this.httpClient.get<Imagem>(`${baseURL}/recuperaBase64/${id}/${resize}`);
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${baseURL}/remove/${id}`);
  }

  upload(body:any, removerFundo:boolean): Observable<any> {
    return this.httpClient.post(`${baseURL}/upload/${removerFundo}`, body);
  }

}
