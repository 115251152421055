<nz-page-header class="site-page-header" nzTitle="Acompanhamento" nzSubtitle="Acompanhamento das campanhas">
</nz-page-header>
<div>
  <ng-container #customLoadingTemplate>
    <div>
      <nz-table [nzData]="campanhas" #rowSelectionTable>
        <thead>
          <tr>
            <th nzShowSort="true" [nzSortFn]="sortColunaCmpNome">Campanha</th>
            <th width="250px"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let campanha of rowSelectionTable.data;">
            <td>{{campanha.cmpNome}}</td>
            <td align="end">
              <a (click)="viewDetails(campanha)">Detalhes</a>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </ng-container>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<br>
