<nz-page-header class="site-page-header" nzTitle="Campanha" nzSubtitle="Campanhas cadastradas">
</nz-page-header>
<div>
    <ng-container #customLoadingTemplate>
        <form nz-form [formGroup]="form">
            <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
              <nz-form-label [nzSpan]="5">Buscar</nz-form-label>
              <nz-form-control [nzSpan]="20">
                  <input
                          style="width: 100%;"
                          nz-input
                          formControlName="filtro"
                          name="filtro"
                          (ngModelChange)="buscar()"
                          />
              </nz-form-control>
              <nz-form-control [nzSpan]="5">
                <label nz-checkbox (ngModelChange)="buscarArquivado()" formControlName="arquivado">
                    Arquivado
                  </label>
                </nz-form-control>
          </nz-form-item>
          </form>
        <div>
            <nz-table [nzData]="campanhas" #rowSelectionTable>
                <thead>
                    <tr>
                        <th nzShowSort="true" [nzSortFn]="sortColunaCmpNome">Campanha</th>
                        <th width="280px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let campanha of rowSelectionTable.data;">
                        <td>{{campanha.cmpNome}}</td>
                        <td>
                            <a (click)="pendenciasCurrentCampanha(campanha)">Pendências</a>&nbsp;
                            <a (click)="editCurrentCampanha(campanha)">Editar</a>&nbsp;
                            <a nz-popconfirm nzPopconfirmTitle="Deseja remover esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="delete(campanha)">Remover</a>&nbsp;
                            <a nz-popconfirm nzPopconfirmTitle="Deseja arquivar esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="arquivar(campanha)">Arquivar</a>&nbsp;
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </ng-container>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<br>
<p align="right">
    <button class="form-button" nz-button nzType="primary" (click)="createCampanha()">Novo</button>
</p>
