import { Component, OnInit } from '@angular/core';
import { CampanhaService } from 'src/app/services/campanha.service';
import { Campanha } from 'src/app/models/campanha';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-campanha-list',
  templateUrl: './campanha-list.component.html',
  styleUrls: ['./campanha-list.component.css']
})

export class CampanhaListComponent implements OnInit {

  campanhas: Campanha[];
  campanhasPreFiltro: Campanha[];
  public form: UntypedFormGroup;
  public loading:boolean = false;

  constructor(
    private campanhaService: CampanhaService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService) { 
      this.form = this.formBuilder.group({
        filtro:null,
        arquivado:false
      });

    }

  ngOnInit(): void {

    this.readCampanhas();

  }




  readCampanhas(): void {

    this.loading = true;
    if (!this.form.get("arquivado").value) {
      this.campanhaService.listAll()
        .subscribe(
          campanhas => {
            this.loading = false;
            this.campanhas = campanhas;
            this.campanhasPreFiltro = campanhas;
            this.buscar();
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(`${error.message}`, 'Erro ao enviar!');
          });
    } else {
      this.campanhaService.listArquivado()
        .subscribe(
          campanhas => {
            this.loading = false;
            this.campanhas = campanhas;
            this.campanhasPreFiltro = campanhas;
            this.buscar();
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(`${error.message}`, 'Erro ao enviar!');
          });

    }
  }

  buscar(): void {
    if (this.campanhas == null)
      this.campanhas = this.campanhasPreFiltro;

    var filtro = this.form.get('filtro').value;
    if (filtro == null)
      filtro = "";
    this.campanhas = this.campanhasPreFiltro.filter(item => item.cmpNome.toUpperCase().includes(filtro.toUpperCase()));
  }


  buscarArquivado(): void {
    this.readCampanhas();
  }

  refresh(): void {
    this.readCampanhas();
  }

  editCurrentCampanha(campanha): void {
    this.router.navigateByUrl('/campanha/form/' + campanha.id);
  }

  pendenciasCurrentCampanha(campanha): void {
    this.router.navigateByUrl('/campanha/list-pendencia/' + campanha.id);
  }

  delete(campanhas): void {
    this.deleteCurrentCampanha(campanhas);
    this.campanhas = this.campanhas.filter(item => item.id != campanhas.id);
  }

  deleteCurrentCampanha(campanha): void {

    this.loading = true;
    this.campanhaService.delete(campanha.id)
    .subscribe(
      retorno => {
        this.readCampanhas();
        this.toastr.success('Campanha Excluída!', 'Sucesso!');
      },
      error => {
        this.loading = false;
        console.log(error);
      });
  }


  arquivar(campanhas): void {
    this.arquivarCurrentCampanha(campanhas);
    this.campanhas = this.campanhas.filter(item => item.id != campanhas.id);
  }


  arquivarCurrentCampanha(campanha): void {

    this.loading = true;
    this.campanhaService.arquivar(campanha.id)
    .subscribe(
      retorno => {
        this.readCampanhas();
        this.toastr.success('Campanha Arquivada!', 'Sucesso!');
      },
      error => {
        this.loading = false;
        console.log(error);
      });
  }


  createCampanha(): void {
    this.router.navigateByUrl('/campanha/form');
  }
  sortColunaCmpNome(a : any, b : any) : boolean {
    return (a.cmpNome.localeCompare(b.cmpNome));
  }


}
