import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigFiles } from '@models/config_files';
import { Encarte } from '@models/encarte';
import { ConfigFilesService } from '@services/config-files.service';
import { EncarteService } from '@services/encarte.service';
import { ToastrService } from 'ngx-toastr';
import { CampanhaEncarte } from '@models/campanhaencarte';
import { CampanhaEncarteService } from '@services/campanhaencarte.service';
import { saveAs } from 'file-saver';
import { Campanha } from '@models/campanha';

@Component({
  selector: 'app-config-files',
  templateUrl: './config-files.component.html',
  styleUrls: ['./config-files.component.css']
})
export class ConfigFilesComponent implements OnInit {

  encartes:Encarte[];
  nameFileUpload:string = "";

  public configFilesForm: UntypedFormGroup;
  public loading:boolean = false;

  private httpClient: HttpClient;

  campanhasencarte: CampanhaEncarte[];
  campanhaencarte: CampanhaEncarte;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private encarteService: EncarteService,
    private campanhaEncarteService: CampanhaEncarteService,
    private toastr: ToastrService,
    private router: Router,
    http: HttpClient) {

      this.httpClient = http;

      this.configFilesForm = this.formBuilder.group({
        id: null,
        base64CSV: null,
        base64Zip: null
      });

     }

  ngOnInit(): void {
    this.readCampanhasEncarte();
  }



    readCampanhasEncarte(): void {

      this.loading = true;
      this.campanhaEncarteService.listaStatusImpressao()
        .subscribe(
          campanhasencarte => {
            this.loading = false;
            this.campanhasencarte = campanhasencarte;
          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(`${error.message}`, 'Erro ao enviar!');
          });
    }

    refresh(): void {
      this.readCampanhasEncarte();
    }

  processaArquivoBlob (encarte: CampanhaEncarte) {
    this.loading = true;

    this.encarteService.pdfBlob()
        .subscribe(
          response => {
            saveAs(response, (encarte.cmpNome+'_'+encarte.lojNomeFantasia)
            .replace('<','')
            .replace('>','')
            .replace(':','')
            .replace('"','')
            .replace('/','')
            .replace('\\','')
            .replace('|','')
            .replace('?','') +'.zip');
            this.loading = false;
          },
          error => {
            this.loading = false;
            this.toastr.error(error.message, 'Erro ao realizar o download.');
          });
  }

  finaliza (encarte: CampanhaEncarte) {
      this.loading = true;
      this.campanhaEncarteService.finaliza(encarte?.id)
      .subscribe(
        response => {
          this.loading = false;

          if (response?.error) {
            this.toastr.error(response?.message, 'Erro ao finalizar campanha!');
          } else {
            this.toastr.success('Campanha', 'Campanha finalizada com sucesso');
          }
          this.readCampanhasEncarte();
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Algo saiu errado!');
        });
    }

  enviaArquivosDownload (encarte: CampanhaEncarte) {
      this.loading = true;
      this.encarteService.pdfJson(encarte?.id)
        .subscribe(
          response => {
            this.loading = false;

            if (response?.error) {
              this.toastr.error(response?.message, 'Erro ao gerar arquivo!');
            } else {
              this.toastr.success('PDF Gerado!', 'Iniciando o download. Por favor, aguarde.');
              this.processaArquivoBlob(encarte);
            }

          },
          error => {
            this.loading = false;
            console.log(error);
            this.toastr.error(error.message, 'Algo saiu errado!');
          });
  }

  sortColunaCmpNome(a : any, b : any) : boolean {
    return (a.cmpNome.localeCompare(b.cmpNome));
  }



}
