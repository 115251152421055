import { Component, OnInit } from '@angular/core';
import { LojaService } from 'src/app/services/loja.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { CepService } from '@services/cep.service';
import { Loja } from 'src/app/models/loja';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-loja-form',
  templateUrl: './loja-form.component.html',
  styleUrls: ['./loja-form.component.css']
})

export class LojaFormComponent implements OnInit {

  cidadeUF = "Preencha o CEP";
  bairro = "Preencha o CEP";
  planosModel = null;
  dadosEnviados = false;
  lojId = null;

  public loading:boolean = false;

  constructor(
    private lojaService: LojaService,
    private cepService: CepService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') != undefined)
      this.getLoja(this.route.snapshot.paramMap.get('id'));

    for (var f in this.form.controls) {
      this.form.controls[f].markAsTouched();
    }
  }


  getLoja(id): void {

    this.loading = true;
    this.lojaService.read(id)
      .subscribe(
        loja => {
          this.loading = false;
          this.lojId = loja.id;

          this.form.get('lojCnpj').setValue(loja.lojCnpj);
          this.form.get('lojComplemento').setValue(loja.lojComplemento);
          this.form.get('lojNomeFantasia').setValue(loja.lojNomeFantasia);
          this.form.get('lojNumTelefone').setValue(loja.lojDdd+loja.lojTelefone);
          this.form.get('lojNumero').setValue(loja.lojNumero);
          this.form.get('lojRazaoSocial').setValue(loja.lojRazaoSocial);
          this.form.get('logId').setValue(String(loja.logId));
          this.form.get('lojRua').setValue(loja.lojRua);
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao enviar!');
        });
  }


  validaCep = (control: UntypedFormControl) =>
  new Observable((observer: Observer<ValidationErrors | null>) => {

    this.cidadeUF = 'Preencha o CEP';

    if (this.form.get('logId').value != "") {

      this.loading = true;
      this.cepService.consulta(this.form.get('logId').value.replace('-', '')).subscribe(
        endereco => {
          this.loading = false;
          if (endereco.id != null) {
            if (endereco.logTipo != null && endereco.logTipo != "")
              this.form.get('lojRua').setValue( endereco.logTipo + '  ' + endereco.logNome);
            this.cidadeUF = endereco.logCidade + ' - ' + endereco.logUF;
            observer.next(null);
            observer.complete();
          } else {
            observer.next({ cepInvalid: true, error: true});
            observer.complete();
          }
        }
      )
    } else {
      observer.next(null);
      observer.complete();

    }

});


  cancelar(): void {
    this.router.navigateByUrl('/loja/list');
  }


  salvarLoja(): void {
    this.loading = true;

    var loja = new Loja();
    loja.lojCnpj = this.form.get('lojCnpj').value;
    loja.lojComplemento = this.form.get('lojComplemento').value;
    loja.lojNomeFantasia = this.form.get('lojNomeFantasia').value;
    loja.lojDdd = String(this.form.get('lojNumTelefone').value).substring(0,2);
    loja.lojTelefone = String(this.form.get('lojNumTelefone').value).substring(2);
    loja.lojNumero = this.form.get('lojNumero').value;
    loja.lojRua = this.form.get('lojRua').value;
    loja.lojRazaoSocial = this.form.get('lojRazaoSocial').value;
    loja.logId = this.form.get('logId').value.replace("-", "");
    if (this.lojId != null) {
      loja.id = this.lojId;
      this.lojaService.update(loja)
        .subscribe(
          response => {
            this.loading = false;
            this.dadosEnviados = true;
            this.toastr.success('Loja salva!', 'Gravado com Sucesso!');
            this.router.navigateByUrl('/loja/list');
          },
          error => {
            this.loading = false;
            console.log(error);
          });
      } else {
        this.lojaService.create(loja)
          .subscribe(
            response => {
              this.loading = false;
              this.dadosEnviados = true;
              this.toastr.success('Loja salva!', 'Gravado com Sucesso!');
              this.router.navigateByUrl('/loja/list');
            },
            error => {
              this.loading = false;
              console.log(error);
            });

      }
  }


  requiredTelefone = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null && (this.form.get('lojNumTelefone').value == null || this.form.get('lojNumTelefone').value.length == 0)) {
      return { requiredTelefone: true, error: true };
    }
    return {};
  }


  patternTelefone = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null) {
      var formValue = this.form.get('lojNumTelefone').value;
      var isValid = /[0-9]{10,11}/.test(formValue);

      if (!isValid) {
        return { patternTelefone: true, error: true };
      }
    }
    return {};
  }

  checkCNPJ = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null && this.form.get('lojCnpj').value != null) {
      var formValue = this.form.get('lojCnpj').value;
      var isValid = this.validarCNPJ(formValue);

      if (!isValid) {
        return { invalidCnpj: true, error: true };
      }
    }
    return {};
  }


  validarCNPJ(cnpj:string) {
    if (cnpj.length < 14)
      return true;

    cnpj = cnpj.replace(/[^\d]+/g,'');

    if(cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    var tamanho:number = cnpj.length - 2
    var numeros:string = cnpj.substring(0,tamanho);
    var digitos:string = cnpj.substring(tamanho);
    var soma:number = 0;
    var pos:number = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    var resultado:number = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != Number(digitos.charAt(0)))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != Number(digitos.charAt(1)))
          return false;

    return true;

  }


  form = new UntypedFormGroup({
    lojRazaoSocial: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
    lojRua: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
    lojNomeFantasia: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
    lojCnpj: new UntypedFormControl(null, [Validators.required, Validators.maxLength(14),Validators.minLength(14), this.checkCNPJ]),
    logId: new UntypedFormControl(null, [Validators.required,  Validators.minLength(8), Validators.maxLength(8)], this.validaCep),
    lojNumero: new UntypedFormControl(null, [Validators.required, Validators.maxLength(32)]),
    lojComplemento: new UntypedFormControl(null, [Validators.maxLength(64)]),
    //empDDDMatriz: new FormControl(null, [this.requiredDDD, this.patternDDD]),
    lojNumTelefone: new UntypedFormControl("", [this.requiredTelefone, this.patternTelefone /*, this.requiredDDD, this.patternDDD*/]),

  });



}
