import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const baseURL = `${environment.api_url}/public/v1`;

@Injectable({
  providedIn: 'root'
})
export class CepService {

  constructor(private httpClient: HttpClient) { }

  consulta(id): Observable<any> {
    return this.httpClient.get(`${baseURL}/consultaLogradouro/` + id);
  }

}
