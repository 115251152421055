import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const baseURL = `${environment.api_url}/empresa/v1/usuario`;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private httpClient: HttpClient) { }

  listAll(): Observable<any> {
    return this.httpClient.get(`${baseURL}/listaAtivos`);
  }

  read(id): Observable<any> {
    return this.httpClient.get(`${baseURL}/recupera/${id}`);
  }

  create(data): Observable<any> {
    return this.httpClient.post(`${baseURL}/novo`, data);
  }

  update(data): Observable<any> {
    return this.httpClient.put(`${baseURL}/atualiza`, data);
  }

  delete(id): Observable<any> {
    return this.httpClient.delete(`${baseURL}/remove/${id}`);
  }

  check(email): Observable<any> {
    return this.httpClient.get(`${environment.api_url}/public/v1/usuario/checaEmail/${email}`);
  }


}
