import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Perfil } from '@models/perfil';
import { ConfigFiles } from '@models/config_files';
import { map, catchError } from 'rxjs/operators';

const baseURL = `${environment.api_url}/empresa/v1/usuario`;

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  constructor(private http: HttpClient){}

  listAll(): Observable<Perfil[]> {
    return this.http.get<Perfil[]>(`${baseURL}/perfil`);
  }

}
