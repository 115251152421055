import { CampanhaEncarte } from '@models/campanhaencarte';
import { CampanhaEncarteService } from '@services/campanhaencarte.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-campanha-produto-list',
  templateUrl: './campanha-produto-list.component.html',
  styleUrls: ['./campanha-produto-list.component.css']
})

export class CampanhaEncarteProdutoListComponent implements OnInit {

  campanhasencarte: CampanhaEncarte[];

  public loading:boolean = false;

  constructor(
    private campanhaEncarteService: CampanhaEncarteService,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {

    this.readCampanhasEncarte();

  }


  readCampanhasEncarte(): void {

    this.loading = true;
    this.campanhaEncarteService.listaStatusProduto()
      .subscribe(
        campanhasencarte => {
          this.loading = false;
          this.campanhasencarte = campanhasencarte;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao enviar!');
        });
  }

  refresh(): void {
    this.readCampanhasEncarte();
  }

  verCurrentCampanhaEncarte(campanhaencarte): void {
    this.router.navigateByUrl('/campanha-prod/arquivo/'+campanhaencarte.id);
  }

  sortColunaCmpNome(a : any, b : any) : boolean {
    return (a.cmpNome.localeCompare(b.cmpNome));
  }



}
