import { CampanhaEncarteLog } from './../../models/campanha-encarte-log';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CampanhaService } from '@services/campanha.service';
import { CampanhaEncarteService } from '@services/campanhaencarte.service';
import { CampanhaEncarteLogService } from '@services/campanhaencartelog.service';
import { EncarteService } from '@services/encarte.service';
import { ImagemService } from '@services/imagem.service';
import { LojaService } from '@services/loja.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { CampanhaEncarteArquivo } from '@models/campanha-encarte-arquivo';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-campanha-acompanhamento-view',
  templateUrl: './campanha-acompanhamento-view.component.html',
  styleUrls: ['./campanha-acompanhamento-view.component.css']
})
export class CampanhaAcompanhamentoViewComponent implements OnInit {
  public loading = false;
  public campanhaDetails;
  //public lojaDetails = [];
  //public encarteDetails = [];
  public campanhaLojaEncartes = [];
  public linhas = [];
  public campanhaLogs = [];
  public modalProductsIsVisible = false;
  public modalLogsIsVisible = false;
  public campanhaencarteId = "";

  public tipOperacaos = {'R':  "Reprovado", 'A': "Aprovado", 'I': "Reinício", 'G': "Arquivo Gerado" };

  constructor(
    private campanhaService: CampanhaService,
    private campanhaEncarteService: CampanhaEncarteService,
    private campanhaEncarteLogService: CampanhaEncarteLogService,
    //private lojaService: LojaService,
    private imagemService: ImagemService,
    //private encarteService: EncarteService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private modal: NzModalService,
  ) { }

  showModal(): void {
    this.modalProductsIsVisible = true;
  }
  showModalLog():void{
      this.modalLogsIsVisible = true;
  }
  handleOkLog(): void{
    this.modalLogsIsVisible=false;
  }

  handleOk(): void {
    this.modalProductsIsVisible = false;
  }

  handleSalvarArquivo(): void {
    const moveCabecalho = this.linhas[this.linhas.length - 1];
    this.linhas.pop();
    this.linhas.unshift(moveCabecalho);
    let submitFile = "";
    this.linhas.forEach(linha => {
      linha.colunas.forEach(coluna => {
          submitFile += coluna.conteudo + ";"
      });
      submitFile = submitFile.slice(0, -1);
      submitFile += "\r\n";
    });

    let campanhaencartearquivo = new CampanhaEncarteArquivo();
    campanhaencartearquivo.id = this.campanhaencarteId;
    campanhaencartearquivo.cmaArquivo = btoa(unescape(encodeURIComponent(submitFile)));

    if (campanhaencartearquivo.id != null) {

      this.loading = true;
      this.campanhaEncarteService.uploadCsv(campanhaencartearquivo)
          .subscribe(
            response => {
              this.loading = false;

              if (response.error) {
                this.toastr.error(response.message, 'Erro ao enviar Arquivo!');
              } else {
                this.toastr.success('Arquivo Enviado!', 'Iniciando o upload. Por favor, aguarde.');
              }
            },
            error => {
              this.loading = false;
              console.log(error);
              this.toastr.error(error.message, 'Algo saiu errado!');
            });
    }

    this.modalProductsIsVisible = false;
  }


  sobeLinha(idx : number) {
    const oldLinha = this.linhas[idx - 1];
    this.linhas[idx - 1] = this.linhas[idx];
    this.linhas[idx - 1].idx = idx - 1;
    this.linhas[idx] = oldLinha;
    this.linhas[idx].idx = idx;
  }

  desceLinha(idx : number) {
    const oldLinha = this.linhas[idx + 1];
    this.linhas[idx + 1] = this.linhas[idx];
    this.linhas[idx + 1].idx = idx + 1;
    this.linhas[idx] = oldLinha;
    this.linhas[idx].idx = idx;
  }

  cancelar(): void {
    this.router.navigateByUrl('/campanha/acompanhamento');
  }

  verCurrentImagem(imagem): void {
    this.loading = true;
    this.imagemService.read(imagem,0)
      .subscribe(
        response => {
          this.modal.info(
            {
              nzTitle: response.imgNome,
              nzContent: "<img class=\"imgContentCss\" src=\"" + response.imgConteudo + "\">",
              nzWidth: 700
            }
          );
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao buscar Imagem!');
        });
  }

  getCampanha(id): void {

    this.loading = true;
    this.campanhaService.read(id)
      .subscribe(
        campanha => {
          this.campanhaDetails = campanha;
          this.campanhaLojaEncartes = campanha.campanhasEncarte;
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao recuperar Campanha!');
        });
  }

/*
  readLoja() {
    return this.lojaService.listAtivas();
  }
*/

/*
  readEncarte() {
    return this.encarteService.listAll();
  }
*/

  getArquivoJson(id): void {
    this.loading = true;
    this.campanhaencarteId = id;
    this.campanhaEncarteService.visualizarCSVEncarte(id)
      .subscribe(
        arquivo => {
          this.loading = false;
          this.linhas = arquivo.linhas.filter(l => l.colunas.filter(c => c.conteudos));
          const moveCabecalho = this.linhas[0];
          this.linhas.shift();
          this.linhas.push(moveCabecalho);
          let i = 0;
          this.linhas.forEach(linha => {
            linha.idx = i;
            i++;
          });
          this.showModal();
        }, error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao buscar o Arquivo CSV!');
        });
  }


  generateCampanhasEncarte() {
    const campanhas: any[] = this.campanhaDetails.campanhasEncarte.map((current) => ({
      cmeQuantidade: current.cmeQuantidade,
      cmeId: current.id,
      //encarte: this.getEncarte(current.encId),
      //loja: this.getLoja(current.lojId),
    })).filter(campanha => campanha.loja);

    return campanhas;
  }

  readCampanhaLog(id): void{
    this.loading = false;
    this.campanhaEncarteLogService.listCampanhaLog(id)
    .subscribe(campanhalog =>{
      this.campanhaLogs = campanhalog;
      this.showModalLog();
    });
  }

/*
  getEncarte(id: string) {
    return this.encarteDetails.find(encarte => encarte.id === id);
  }
*/
/*
  getLoja(id: string) {
    return this.lojaDetails.find(loja => loja.id === id);
  }
*/

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
      if (id != undefined) {
      this.getCampanha(id);
    }
  }


}
