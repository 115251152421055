import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './components/index/index.component';
import { RegistroFormComponent } from './components/registro-form/registro-form.component';

import { SetorListComponent } from './components/setor-list/setor-list.component';
import { SetorFormComponent } from './components/setor-form/setor-form.component';

import { GrupoUsuarioListComponent } from './components/grupousuario-list/grupousuario-list.component';
import { GrupoUsuarioFormComponent } from './components/grupousuario-form/grupousuario-form.component';

import { LojaListComponent } from './components/loja-list/loja-list.component';
import { LojaFormComponent } from './components/loja-form/loja-form.component';

import { CampanhaListComponent } from './components/campanha-list/campanha-list.component';
import { CampanhaFormComponent } from './components/campanha-form/campanha-form.component';
import { CampanhaAcompanhamentoListComponent } from './components/campanha-acompanhamento-list/campanha-acompanhamento-list.component';

import { CampanhaPendenciaListComponent } from './components/campanha-pendencia-list/campanha-pendencia-list.component';

import { CampanhaEncarteProdutoListComponent } from './components/campanha-produto-list/campanha-produto-list.component';
import { CampanhaEncarteGerenciaListComponent } from './components/campanha-gerencia-list/campanha-gerencia-list.component';
import { CampanhaEncarteDiretoriaListComponent } from './components/campanha-diretoria-list/campanha-diretoria-list.component';
import { CampanhaEncarteProdutoArquivoComponent } from '@components/campanha-produto-arquivo/campanha-produto-arquivo.component';
import { CampanhaEncarteGerenciaArquivoComponent } from '@components/campanha-gerencia-arquivo/campanha-gerencia-arquivo.component';
import { CampanhaEncarteDiretoriaArquivoComponent } from '@components/campanha-diretoria-arquivo/campanha-diretoria-arquivo.component';

import { ImagemListComponent } from './components/imagem-list/imagem-list.component';

import { UsuarioListComponent } from './components/usuario-list/usuario-list.component';
import { UsuarioFormComponent } from './components/usuario-form/usuario-form.component';

import { EncarteListComponent } from './components/encarte-list/encarte-list.component';
import { EncarteFormComponent } from './components/encarte-form/encarte-form.component';

import { LoginComponent } from './components/login/login.component';
import { ResetComponent } from './components/reset/reset.component';
import { AlterarSenhaComponent } from './components/alterar-senha/alterar-senha.component';

import { EmpresaListComponent } from './components/admin-empresa-list/admin-empresa-list.component';
import { EmpresaFormComponent } from './components/admin-empresa-form/admin-empresa-form.component';

import { LayoutListComponent } from './components/layout-list/layout-list.component';
import { LayoutFormComponent } from './components/layout-form/layout-form.component';

import { TiposPaginaListComponent } from './components/tipos-pagina-list/tipos-pagina-list.component';
import { TiposPaginaFormComponent } from './components/tipos-pagina-form/tipos-pagina-form.component';

import { AuthGuard } from './helpers/auth.guard';
import { ConfigFilesComponent } from '@components/config-files/config-files.component';
import { CampanhaAcompanhamentoViewComponent } from '@components/campanha-acompanhamento-view/campanha-acompanhamento-view.component';

import { SVGComponent } from '@components/svg/svg.component';
import { CardSvgListComponent } from '@components/card-svg-list/card-svg-list.component';
import { EncartSvgListComponent } from '@components/encart-svg-list/encart-svg-list.component';




const routes: Routes = [
  { path: 'setor/list', component: SetorListComponent, canActivate: [AuthGuard] },
  { path: 'setor/form/:id', component: SetorFormComponent, canActivate: [AuthGuard] },
  { path: 'setor/form', component: SetorFormComponent, canActivate: [AuthGuard] },

  { path: 'grupousuario/list', component: GrupoUsuarioListComponent, canActivate: [AuthGuard] },
  { path: 'grupousuario/form/:id', component: GrupoUsuarioFormComponent, canActivate: [AuthGuard] },
  { path: 'grupousuario/form', component: GrupoUsuarioFormComponent, canActivate: [AuthGuard] },

  { path: 'loja/list', component: LojaListComponent, canActivate: [AuthGuard] },
  { path: 'loja/form/:id', component: LojaFormComponent, canActivate: [AuthGuard] },
  { path: 'loja/form', component: LojaFormComponent, canActivate: [AuthGuard] },

  { path: 'campanha/list', component: CampanhaListComponent, canActivate: [AuthGuard] },
  { path: 'campanha/form/:id', component: CampanhaFormComponent, canActivate: [AuthGuard] },
  { path: 'campanha/form', component: CampanhaFormComponent, canActivate: [AuthGuard] },
  { path: 'campanha/list-pendencia/:id', component: CampanhaPendenciaListComponent, canActivate: [AuthGuard] },
  { path: 'campanha/acompanhamento', component: CampanhaAcompanhamentoListComponent, canActivate: [AuthGuard] },
  { path: 'campanha/acompanhamento-detalhes/:id', component: CampanhaAcompanhamentoViewComponent, canActivate: [AuthGuard] },

  { path: 'campanha-prod/list', component: CampanhaEncarteProdutoListComponent, canActivate: [AuthGuard] },
  { path: 'campanha-ger/list', component: CampanhaEncarteGerenciaListComponent, canActivate: [AuthGuard] },
  { path: 'campanha-dir/list', component: CampanhaEncarteDiretoriaListComponent, canActivate: [AuthGuard] },
  { path: 'campanha-prod/arquivo/:id', component: CampanhaEncarteProdutoArquivoComponent, canActivate: [AuthGuard] },
  { path: 'campanha-ger/arquivo/:id', component: CampanhaEncarteGerenciaArquivoComponent, canActivate: [AuthGuard] },
  { path: 'campanha-dir/arquivo/:id', component: CampanhaEncarteDiretoriaArquivoComponent, canActivate: [AuthGuard] },

  { path: 'imagem/list', component: ImagemListComponent, canActivate: [AuthGuard] },

  { path: 'usuario/list', component: UsuarioListComponent, canActivate: [AuthGuard] },
  { path: 'usuario/form/:id', component: UsuarioFormComponent, canActivate: [AuthGuard] },
  { path: 'usuario/form', component: UsuarioFormComponent, canActivate: [AuthGuard] },

  { path: 'empresa/list/ativas', component: EmpresaListComponent, canActivate: [AuthGuard] },
  { path: 'empresa/list/inativas', component: EmpresaListComponent, canActivate: [AuthGuard] },
  { path: 'empresa/form/:id', component: EmpresaFormComponent, canActivate: [AuthGuard] },

  { path: 'layout/list', component: LayoutListComponent, canActivate: [AuthGuard] },
  { path: 'layout/form/:id', component: LayoutFormComponent, canActivate: [AuthGuard] },
  { path: 'layout/form', component: LayoutFormComponent, canActivate: [AuthGuard] },

  { path: 'paginas/list', component: TiposPaginaListComponent, canActivate: [AuthGuard] },
  { path: 'paginas/form/:id', component: TiposPaginaFormComponent, canActivate: [AuthGuard] },
  { path: 'paginas/form', component: TiposPaginaFormComponent, canActivate: [AuthGuard] },

  { path: 'encarte/list', component: EncarteListComponent, canActivate: [AuthGuard] },
  { path: 'encarte/form/:id', component: EncarteFormComponent, canActivate: [AuthGuard] },
  { path: 'encarte/form', component: EncarteFormComponent, canActivate: [AuthGuard] },

  { path: 'config/files', component: ConfigFilesComponent, canActivate: [AuthGuard] },

  { path: 'svg/card', component: SVGComponent},
  { path: 'svg/cards', component: CardSvgListComponent},
  { path: 'svg/card/:id', component: SVGComponent},
  { path: 'svg/encarte/:id', component: SVGComponent},
  { path: 'svg/encarte', component: SVGComponent},
  { path: 'svg/encartes', component: EncartSvgListComponent},
  

  { path: 'alterar-senha', component: AlterarSenhaComponent },
  { path: 'login', component: LoginComponent },
  { path: 'reset/:token', component: ResetComponent },
  { path: 'logout', component: LoginComponent },
  { path: 'registro', component: RegistroFormComponent },
  { path: '', pathMatch: 'full', component: IndexComponent },
  

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
