import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute,  Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SvgTemplate } from '@models/svg-template';
import { SvgService } from '@services/svg.service';

@Component({
  selector: 'app-card-svg-list',
  templateUrl: './card-svg-list.component.html',
  styleUrls: ['./card-svg-list.component.css']
})
export class CardSvgListComponent implements OnInit {

  listaTemplates:Array<SvgTemplate> = [];
  loading:boolean = false;
  isVisible:boolean = false;

  cardPreFiltro: any[];

  listOfSizes:Array<String> = ["100x300", "500x700", "350x600", "400x800", "500x500"];

  millimeters_in_an_inch:number = 25.4;
  default_dpi:number = 150;
  getScreenWidth: any;
  getScreenHeight: any;

  cardName: UntypedFormGroup;
  form: UntypedFormGroup;


  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private http: HttpClient,
    private SvgService:SvgService,
  ) { }

  ngOnInit(): void 
  {

    this.cardName = this.formBuilder.group({
      stpNome: new UntypedFormControl(null, [Validators.required]),
      cardSize: new UntypedFormControl(null, [Validators.required]),
    });

    this.form = this.formBuilder.group({
      filtro:null,
      arquivado:false
    });

    this.getTemplateList();
  }


  arquivar(card): void 
  {
    console.log(card)
    this.arquivarCurrentCard(card);
    this.listaTemplates = this.listaTemplates.filter(item => item.id != card.id);
  }


  arquivarCurrentCard(card): void 
  {

    this.loading = true;

    this.SvgService.arquivarCard(card.id)
    .subscribe(
      retorno => 
      {
        this.loading = false;
        if (retorno.error)
          this.toastr.error(retorno.message, 'Erro ao Arquivar!');
        else
          this.toastr.success('Encarte Arquivado!', 'Sucesso!');
        this.getTemplateList();
      },
      error => 
      {
        this.loading = false;
        this.toastr.error(error.message, 'Erro ao Arquivar!');
        this.getTemplateList();
      });

  }


  buscar(): void 
  {
    if (this.listaTemplates == null)
      this.listaTemplates = this.cardPreFiltro;

    var filtro = this.form.get('filtro').value;

    if (filtro == null)
      filtro = "";
      this.listaTemplates = this.cardPreFiltro.filter(item => item.stpNome.toUpperCase().includes(filtro.toUpperCase()));
  }


  buscarArquivado(): void 
  {
    this.getTemplateList();
  }

  
  duplicarCard(card_id:any):void
  {
    this.loading = true;
    this.SvgService.recuperaTemplate(card_id).subscribe(
    {
      next:(res) =>
      {
        if(!res.error)
        {
          let data = 
          {
            stpNome: `${res.stpNome} (cópia)`,
            stpConteudo: res.stpConteudo
          }
    
          let service  = this.SvgService.criaTemplate(data);
    
          service.subscribe(
          {
            next:(res) =>
            {
              let msg = 'Card duplicado com sucesso';
              this.toastr.success(msg);
              this.loading = false;
              if(res.id)
              {
                this.router.navigateByUrl(`/svg/card/${res.id}`);
              }
    
            },
            error:(error) =>
            {
              let msg = 'Erro ao criar card.';
              this.loading = false;
              this.toastr.error(msg);
            }
          })
          
        }
        else
        {
          this.toastr.error('Erro ao obter dados do card.');
          this.loading = false;
        }
      },
      error:(error) =>
      {
        this.toastr.error('Erro ao obter dados do card.');
        this.loading = false;
      }
    });
  }

  getTemplateList()
  {
    this.loading = true;
    if (!this.form.get("arquivado").value) {
      this.SvgService.listaTemplates().subscribe(
      {
        next:(res:any) => 
        {
          this.listaTemplates = res;
          this.cardPreFiltro = res;
          this.buscar();
          this.loading = false;
        },
        error:(error) =>
        {
          this.loading = false;
          this.toastr.error(error.message, 'Erro ao Listar!');
        }
      })
    }
    else
    {
      this.SvgService.listCardsArquivados().subscribe(
      {
        next:(res:any) => 
        {
          this.listaTemplates = res;
          this.cardPreFiltro = res;
          this.buscar();
          this.loading = false;
        },
        error:(error) =>
        {
          this.loading = false;
          this.toastr.error(error.message, 'Erro ao Listar!');
        }
      })
    }
  }




  editCard(id_card:string): void 
  {
    this.router.navigateByUrl('/svg/card/' + id_card);
  }

  deleteCard(id_card:string): void 
  {
    this.loading = true;
    this.SvgService.excluirTemplate(id_card).subscribe(
    {
      next:(res) =>
      {
        this.toastr.success('Card excluído com sucesso');
        this.getTemplateList();
      },
      error:(error) =>
      {
        this.toastr.error('Erro ao excluir card');
        this.loading = false;
      }
    })
  }

  criarCard(): void 
  {
    this.isVisible = true;
  }


  handleCancel(): void {
    this.isVisible = false;
  }

  index = 0;
  addSize(input: HTMLInputElement): void 
  {
    const value = input.value;
    if (this.listOfSizes.indexOf(value) === -1) {
      this.listOfSizes = [...this.listOfSizes, input.value || `Novo tamanho ${this.index++}`];
    }
  }


  calcDpi()
  {
    let dpi;
    if (window.matchMedia("(min-resolution: 10dpi)").matches) {
      for(var i=650; i>=1; i = (i-1)) {
        if (window.matchMedia("(min-resolution: " + i + "dpi)").matches) {
          dpi = i;
          break;
        }
      }
    }
    dpi = 150;
    return dpi;
  }


  conversorMedidasPxAndMm(value:number, type:string = 'px')
  {
    let converter = (type === 'px') ? Math.round(value*(this.calcDpi()/this.millimeters_in_an_inch)) : Math.round(value/(this.calcDpi()/this.millimeters_in_an_inch));
    //let converter = (type === 'px') ? Math.round(value*(this.default_dpi/this.millimeters_in_an_inch)) : Math.round(value/(this.default_dpi/this.millimeters_in_an_inch));
    return converter;
  }


  setSizes(width:number | null = null, height:number | null = null, convertSize:boolean = false):void
  {

    if(convertSize)
    {
      let w = this.conversorMedidasPxAndMm(width);
      let h = this.conversorMedidasPxAndMm(height);
      this.getScreenWidth = (w) ? w : h;
      this.getScreenHeight = (h) ? h : w;
    }
   
  }

  saveCard():void
  {
    if(this.cardName.valid)
    {
      //let sizes = this.cardName.get('cardSize').value.split('x');
      let sizes!:any;

      if(this.cardName.get('cardSize').value.toLowerCase().includes('x'))
      {
        sizes = this.cardName.get('cardSize').value.toLowerCase().split('x');

        if(sizes.length > 0)
        {
          let error = false;
          sizes.forEach((n =>
          {
            let medida = parseInt(n)
            if(isNaN(medida))
            {
              let msg = (n !== '') ? `O ${n} não é um valor de medida válido.` : 'Por favor, informe a medida que falta.'
              this.toastr.error(msg);
              error = true;
              return;
            }
          }))
          
          if(error)
            return;
        }
        else
        {
          this.toastr.error(`Por favor, informe a medida do encarte.`);
        }

      }
      else
      {
        this.toastr.error('Utilize o "x" para separar largura e altura');
        return;
      }
      this.setSizes(sizes[0], sizes[1], true);

      let card = `<svg width="${this.getScreenWidth}" height="${this.getScreenHeight}" dpi="${this.calcDpi()}"
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlns:svg="http://www.w3.org/2000/svg" 
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <!-- Created with SVG-edit - https://github.com/SVG-Edit/svgedit-->
                    <g class="layer">
                      <title>${this.cardName.get('stpNome').value}</title>
                    </g>
                  </svg>`;

      /*let card = `<svg width="${this.getScreenWidth}" height="${this.getScreenHeight}" dpi="${this.default_dpi}"
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlns:svg="http://www.w3.org/2000/svg" 
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <!-- Created with SVG-edit - https://github.com/SVG-Edit/svgedit-->
                  <g class="layer">
                    <title>${this.cardName.get('stpNome').value}</title>
                  </g>
                </svg>`;*/

      let cardName = this.cardName.get('stpNome').value;

      let data = 
      {
        stpNome: cardName,
        stpConteudo: card
      }

      let service  = this.SvgService.criaTemplate(data);

      service.subscribe(
      {
        next:(res) =>
        {
          let msg = 'Card criado com sucesso';
          this.toastr.success(msg);

          if(res.id)
          {
            this.router.navigateByUrl(`/svg/card/${res.id}`);
          }

        },
        error:(error) =>
        {
          let msg = 'Erro ao criar card.';
          this.toastr.error(msg);
        }
      })
    }
    else
    {
      let msg = 'Preencha todos os campos';
      this.toastr.error(msg);
      for (var f in this.cardName.controls) {
        this.cardName.controls[f].markAsTouched();
      }
    }
    
  }

  

}
