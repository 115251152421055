import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { SvgArquivo } from '@models/svg-arquivo';
import { SvgEncarte } from '@models/svg-encarte';
import { SvgTemplate } from '@models/svg-template';
import { CampanhaEncarteSVG } from '@models/campanha-encarte-svg';

const baseURL = `${environment.api_url}`;


@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class SvgService {

  constructor(private httpClient: HttpClient) { }

  //template
  listaTemplates(): Observable<SvgTemplate> {
    return this.httpClient.get<SvgTemplate>(`${baseURL}/empresa/v1/template/lista`);
  }

  recuperaTemplate(id: string): Observable<SvgTemplate> {
    return this.httpClient.get<SvgTemplate>(`${baseURL}/empresa/v1/template/recupera/${id}`);
  }

  criaTemplate(data: Partial<SvgTemplate>): Observable<any> {
    return this.httpClient.post(`${baseURL}/empresa/v1/template/novo`, data);
  }

  atualizaTemplate(data: Partial<SvgTemplate>): Observable<any> {
    return this.httpClient.put(`${baseURL}/empresa/v1/template/atualiza`, data);
  }

  excluirTemplate(id: string): Observable<any> {
    return this.httpClient.delete(`${baseURL}/empresa/v1/template/remove/${id}`);
  }


  //arquivo
  listaArquivos(): Observable<SvgArquivo> {
    return this.httpClient.get<SvgArquivo>(`${baseURL}/empresa/v1/svgArquivo/lista`);
  }

  recuperaArquivo(id: string): Observable<SvgArquivo> {
    return this.httpClient.get<SvgArquivo>(`${baseURL}/empresa/v1/svgArquivo/recupera/${id}`);
  }

  criaArquivo(data: Omit<SvgArquivo, 'empId'>): Observable<any> {
    return this.httpClient.post(`${baseURL}/empresa/v1/svgArquivo/novo`, data);
  }

  atualizaArquivo(data: Partial<SvgArquivo>): Observable<any> {
    return this.httpClient.put(`${baseURL}/empresa/v1/svgArquivo/atualiza`, data);
  }

  excluirArquivo(id: string): Observable<any> {
    return this.httpClient.delete(`${baseURL}/empresa/v1/svgArquivo/remove/${id}`);
  }


  //encarte
  listaEncartes(): Observable<SvgEncarte> {
    return this.httpClient.get<SvgEncarte>(`${baseURL}/empresa/v1/svgEncarte/lista`);
  }

  recuperaEncarte(id: string): Observable<SvgEncarte> {
    return this.httpClient.get<SvgEncarte>(`${baseURL}/empresa/v1/svgEncarte/recupera/${id}`);
  }

  criaEncarte(data: Partial<SvgEncarte>): Observable<any> {
    return this.httpClient.post(`${baseURL}/empresa/v1/svgEncarte/novo`, data);
  }

  uploadEncarte(data: Partial<CampanhaEncarteSVG>): Observable<any> {
    return this.httpClient.post(`${baseURL}/empresa/v1/sgvEncarte/upload`, data);
  }

  listagemProdutosEncarte(idArquivoSvg: string): Observable<any> {
    return this.httpClient.get<SvgEncarte>(`${baseURL}/empresa/v1/svgEncarte/visualizar-arquivo/${idArquivoSvg}`);
  }
  

  downloadArquivo(id:string): Observable<any> {
    return this.httpClient.get<SvgEncarte>(`${baseURL}/empresa/v1/svgEncarte/visualizar-arquivo/${id}`);
  }


  obterImagem(nome_arquivo:string)
  {
    return this.httpClient.get<SvgEncarte>(`${baseURL}/empresa/v1/imagem/recupera/${nome_arquivo}`);
  }


  atualizaEncarte(data: Partial<SvgEncarte>): Observable<any> {
    return this.httpClient.put(`${baseURL}/empresa/v1/svgEncarte/atualiza`, data);
  }

  exportaEncarte(data: Partial<SvgEncarte>): Observable<any> {
    return this.httpClient.put(`${baseURL}/empresa/v1/svgEncarte/exporta`, data, {responseType:'blob'});
  }

  excluirEncarte(id: string): Observable<any> {
    return this.httpClient.delete(`${baseURL}/empresa/v1/svgEncarte/remove/${id}`);
  }



  arquivarCard(id: string): Observable<any> {
    return this.httpClient.put(`${baseURL}/empresa/v1/template/arquivar/${id}`, null);
  }

  listCardsArquivados(): Observable<SvgEncarte[]> {
    return this.httpClient.get<SvgEncarte[]>(`${baseURL}/empresa/v1/template/lista-arquivado`);
  }

  arquivarEncarte(id: string): Observable<any> {
    return this.httpClient.put(`${baseURL}/empresa/v1/svgEncarte/arquivar/${id}`, null);
  }

  listEncartesArquivados(): Observable<SvgEncarte[]> {
    return this.httpClient.get<SvgEncarte[]>(`${baseURL}/empresa/v1/svgEncarte/lista-arquivado`);
  }
 

}
