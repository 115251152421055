<div>
  <nz-page-header class="site-page-header" nzTitle="Acompanhamento" nzSubtitle="Informações da campanha">
  </nz-page-header>

  <div class="acompanhamento">
    <div class="acompanhamento__header">
      <div class="acompanhamento__infos">
        <div class="acompanhamento__left">
          <p class="acompanhamento__title">Nome da Campanha: <span class="acompanhamento__description">{{
              campanhaDetails?.cmpNome }}</span></p>
          <p class="acompanhamento__title">Data de Início: <span class="acompanhamento__description">{{
              campanhaDetails?.cmpDataInicio | date: 'dd/MM/YYYY' }}</span></p>
          <p class="acompanhamento__title">Data de Término: <span class="acompanhamento__description">{{
              campanhaDetails?.cmpDataFim | date: 'dd/MM/YYYY' }}</span></p>
        </div>
        <div class="acompanhamento__right">
          <p class="acompanhamento__title">Seleção de Produtos: <span class="acompanhamento__description">{{
              campanhaDetails?.cmpDataProduto | date: 'dd/MM/YYYY HH:MM' }}</span></p>
          <p class="acompanhamento__title">Aprovação Gerentes: <span class="acompanhamento__description">{{
              campanhaDetails?.cmpDataGerencia | date: 'dd/MM/YYYY HH:MM' }}</span></p>
          <p class="acompanhamento__title">Aprovação Diretoria: <span class="acompanhamento__description">{{
              campanhaDetails?.cmpDataDiretoria | date: 'dd/MM/YYYY HH:MM' }}</span></p>
          <p class="acompanhamento__title">Envio para Impressão: <span class="acompanhamento__description">{{
              campanhaDetails?.cmpDataImpressao | date: 'dd/MM/YYYY HH:MM' }}</span></p>
        </div>
      </div>
    </div>
    <div class="overflow-scroll acompanhamento__body">
      <p class="acompanhamento__section">Lojas</p>
      <nz-card *ngFor="let current of campanhaLojaEncartes" style="background: #f6f6f6; padding: .5rem;">
        <p class="acompanhamento__title acompanhamento__title--lg">{{
          current.lojNomeFantasia }}</p>
        <p class="acompanhamento__title">Encarte: <span class="acompanhamento__description">{{
            current.encDescricao }}</span></p>
        <p class="acompanhamento__title">Quantidade de folhetos: <span class="acompanhamento__description">{{
            current.cmeQuantidade }}</span></p>
            <p class="acompanhamento__title">Data de Impressão: <span class="acompanhamento__description">{{
                current.cmeDataImpressao | date: 'dd/MM/YYYY HH:MM'}}</span></p>
        <button nz-button nzType="primary" class="button__custom" (click)="getArquivoJson(current?.id)">
          <span>Ver produtos</span>
        </button><br>
        <button nz-button nzType="primary"
                  class="button__custom button_logs"
                  (click)="readCampanhaLog(current?.id)">
              <span>Ver histórico</span>
        </button>
      </nz-card>
    </div>


    <nz-modal (nzOnCancel)="handleOk()" [nzClosable]="true" nzWidth="100%" [(nzVisible)]="modalProductsIsVisible"
      nzTitle="Produtos da campanha" (nzOnOk)="handleOk()">
      <ng-container *nzModalContent>
        <nz-table [nzData]="linhas" #rowSelectionTable [nzScroll]="{ x: 200 * linhas[0]?.colunas.length + 'px' }"
          id='table-select'>
          <thead *ngFor="let linha of linhas;">
            <th *ngFor="let coluna of linha?.colunas;" class="title">
              <b>
                {{coluna.conteudo.replace("_", " ")}}
              </b>
            </th>
          </thead>
          <tbody *ngFor="let linha of rowSelectionTable.data;let i = index;">
            <tr tabindex="1" *ngIf="linha.idx < linhas.length - 1">
              <td *ngFor="let coluna of linha?.colunas;" align="center">
                <span>
                  <button class="button__custom" style="margin-right: auto;" nz-button nzType="secondary"
                    *ngIf="coluna.conteudo.endsWith('.png')" (click)="verCurrentImagem(coluna.conteudo)">Ver</button>
                  <span *ngIf="!coluna.conteudo.endsWith('.png')">
                    {{coluna.conteudo}}
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </ng-container>
      <div *nzModalFooter>
        <button class="button__custom__modal" nz-button nzType="cancel" (click)="handleOk()">Fechar</button>
      </div>
    </nz-modal>
    <div class="button-group-footer">
      <button nz-button nzType="secondary" class="button__custom" (click)="cancelar()">
        <span>Voltar</span>
      </button>
    </div>
  </div>

      <nz-modal (nzOnCancel)="handleOk()" [nzClosable]="true" nzWidth="100%" [(nzVisible)]="modalLogsIsVisible"
            nzTitle="Histórico da Campanha" (nzOnOk)="handleOkLog()">
            <ng-container *nzModalContent>
              <nz-table [nzData]="campanhaLogs" #rowSelectionTable [nzScroll]="{ x: 95 * linhas[0]?.colunas.length + 'px' }"
                id='table-select'>
                <thead>
                  <th  class="title title_tm">
                    <b>
                     Data
                    </b>
                  </th>
                  <th  class="title title_tm">
                    <b>
                     Operação
                    </b>
                  </th>
                  <th  class="title title_desc">
                    <b>
                     Usuário
                    </b>
                  </th>
                  <th  class="title title_desc">
                    <b>
                     Comentários
                    </b>
                  </th>
                <tbody>
                  <tr *ngFor="let logs of rowSelectionTable.data" tabindex="1">
                    <td>{{logs.celData | date: 'dd/MM/YYYY HH:MM'}}</td>
                    <td>{{tipOperacaos[logs.celOperacao] }}</td>
                    <td style="text-align:left">{{logs.celUsuNome}}</td>
                    <td style="text-align:left;white-space: pre;">{{logs.celDetalhe}}</td>
                  </tr>
                </tbody>
              </nz-table>
            </ng-container>
            <div *nzModalFooter>
              <button class="button__custom" nz-button nzType="primary" (click)="handleOkLog()">OK</button>
            </div>
          </nz-modal>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>
