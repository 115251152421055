import { Setor } from "./setor";
import { Loja } from "./loja";

export class Usuario {
    id: string;
    usuLogin: string;
    usuNome: string;
    usuStatus: string;
    usuRole: string;
    gusId: string;
    setores: Array<Setor>;
    lojas: Array<Loja>;
}
