<div>
    <nz-page-header class="site-page-header" nzTitle="Encarte" nzSubtitle="Dados do Encarte">
    </nz-page-header>

    <ng-container class="edit-form">
        <div>
            <form nz-form [formGroup]="userForm">
                <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
                  <nz-form-label [nzSpan]="5">Nome do Encarte</nz-form-label>
                  <nz-form-control [nzSpan]="20" [nzErrorTip]="erroNome">
                      <input
                              style="width: 50%;"
                              nz-input
                              formControlName="encDescricao"
                              name="encDescricao" />
                      <ng-template #erroNome let-control>
                          <ng-container *ngIf="control.hasError('required')">
                              Campo obrigatório<br>
                          </ng-container>
                          <ng-container *ngIf="control.hasError('maxlength')">
                              Esse campo não permite mais que 64 caracteres<br>
                          </ng-container>
                      </ng-template>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
                    <nz-form-label [nzSpan]="5">Mídia</nz-form-label>
                      <nz-form-control [nzSpan]="20">
  
                      <nz-select
                          formControlName="slaId"
                          nzPlaceHolder="Tipo Midia"
                          style="width:50%"
                          name="slaId"
                          (ngModelChange)="verCurrentTipoLayout($event)"
                          >
                          <nz-option
                          *ngFor="let items of tipoMidia" [nzValue]="items.id"
                              [nzLabel]="items.descricao">
                          </nz-option>
                      </nz-select>
  
                        </nz-form-control>
                    </nz-form-item>
  
                <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
                  <nz-form-label [nzSpan]="5">Margem da Página</nz-form-label>
                  <nz-form-control [nzSpan]="20" [nzErrorTip]="erroMargem">
                      <input
                              style="width: 20%;"
                              nz-input
                              type="number"
                              formControlName="encMargem"
                              name="encMargem" /> {{unit}}
                      <ng-template #erroMargem let-control>
                          <ng-container *ngIf="control.hasError('required')">
                              Campo obrigatório<br>
                          </ng-container>
                      </ng-template>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
                  <nz-form-label [nzSpan]="5">Limite duplicação imagem</nz-form-label>
                  <nz-form-control [nzSpan]="20" [nzErrorTip]="erroLimite">
                      <input
                              style="width: 20%;"
                              nz-input
                              type="number"
                              formControlName="encLimiteDuplicar"
                              name="encLimiteDuplicar" />
                      <ng-template #erroLimite let-control>
                          <ng-container *ngIf="control.hasError('required')">
                              Campo obrigatório<br>
                          </ng-container>
                      </ng-template>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
                  <nz-form-label [nzSpan]="5">Pecentual duplicação imagem</nz-form-label>
                  <nz-form-control [nzSpan]="20" [nzErrorTip]="erroPercentual">
                      <input
                              style="width: 20%;"
                              nz-input
                              type="number"
                              formControlName="encPercentualDuplicacao"
                              name="encPercentualDuplicacao" /> %
                      <ng-template #erroPercentual let-control>
                          <ng-container *ngIf="control.hasError('required')">
                              Campo obrigatório<br>
                          </ng-container>
                      </ng-template>
                  </nz-form-control>
                </nz-form-item>


                <div *ngIf="pagesArray.controls.length > 0">

                      <div *ngFor="let page of pagesArray.controls; let i = index;" formArrayName="paginas">
                        <nz-card [formGroupName]='i'>

                            <i
                                (click)="invertePaginaOrdemEmCima(i)"
                                *ngIf="i > 0"
                                class="iconUpOrder"
                                nz-icon nzType="caret-up" nzTheme="outline"
                                nz-tooltip nzTooltipTitle="Enviar para cima" nzTooltipPlacement="bottomRight">
                            </i>
                            <i
                                (click)="invertePaginaOrdemEmBaixo(i)"
                                *ngIf=" i < pagesArray.controls.length - 1"
                                class="iconDownOrder"
                                nz-icon nzType="caret-down" nzTheme="outline"
                                nz-tooltip nzTooltipTitle="Enviar para baixo" nzTooltipPlacement="bottomRight">
                            </i>

                            <div nz-row [nzGutter]="24" class="flexDisplay centralizaVertical">
                                <div class="containerSelectsCards" nz-tooltip nzTooltipTitle="Tipo de Página">
                                    <nz-select
                                        (ngModelChange)="calculaDimensaoPaginaExemploPorId($event, i)"
                                        style="width: 200px;"
                                        formControlName="tpgId"
                                        nzPlaceHolder="Tipo de Página"
                                        name="tpgId">
                                        <nz-option
                                        *ngFor="let items of tipoPages" [nzValue]="items.id"
                                            [nzLabel]="items.tpgDescricao">
                                        </nz-option>
                                    </nz-select>
                                    <button nz-button nzType="link" style="color:red"
                                        (click)="deletePage(i)">Remover Página
                                    </button>
                                </div>


                                <div class="containerButtonsCard" nz-tooltip nzTooltipTitle="Adicionar Área">
                                    <div style="position: relative;"></div>
                                </div>
                            </div>

                            <div
                                *ngIf="pagesArray.controls[i].controls.areas.controls.length > 0"
                                formArrayName='areas'
                                class="containerDaAreaComFolhetoExemplo"
                                >
                        <div>
                            <div class="cartPrevisaoPagina">
                                    <div class="cartFolhetoCantainerAlign">
                                        <div
                                        *ngIf="page.value.tpgId"
                                        class="cardFolheto"
                                        [style.width]="larguraQuadroEncarte[i]"
                                        [style.height]="alturaQuadroEncarte">
                                        <img
                                            *ngIf="page.value.pagFundo"
                                            [src]="page.value.pagFundo"
                                            class="imgFundoFolheto"
                                            [style.width]="larguraQuadroEncarte[i]"
                                            [style.height]="alturaQuadroEncarte"/>
                                    </div>
                                    <div *ngIf="!!page.value.tpgId" class="buttonFolheto">
                                        <input
                                            type="file"
                                            (change)="carregarImagemFundo($event.target.files)"
                                            style="margin-bottom: 1rem; border-style:none; display:none"
                                            id="file"
                                            class="custom-file-input">
                                            <label (click)="setndexImagemFundo(i)" *ngIf="!!page.value.tpgId && !page.value.pagFundo" class="labelButtonUploadImage" for="file">
                                                Inserir Fundo
                                            </label>
                                            <label (click)="setndexImagemFundo(i)" *ngIf="!!page.value.tpgId && page.value.pagFundo" class="labelButtonUploadImage" for="file" >
                                                Alterar Fundo
                                            </label>
                                    </div>
                                </div>
                                    <div
                                        *ngIf="page.value.tpgId"
                                        class="cardFolheto"
                                        [style.width]="larguraQuadroEncarte[i]"
                                        [style.height]="alturaQuadroEncarte">
                                        <ng-container *ngFor="let img of imagensExampleLayoutArray[i];">
                                            <div
                                                [style.width]="larguraQuadroEncarte[i]"
                                                [style.height.px]="img.layAlturaPx"
                                                style="display: flex;">
                                                <img
                                                    [src]="img.base64"
                                                    class="imgLayoutFolheto"
                                                    [style.width]="larguraQuadroEncarte[i]"
                                                    [style.height.px]="img.layAlturaPx">
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div style="text-align:center">{{totalizadorPagina[i]}}</div>
                            </div>

                                <div class="cartFolhetoCantainerArea">
                                    <nz-card *ngFor="let area of page.controls.areas.controls; let idx = index;"
                                        [formGroupName]="idx"
                                        class="sizeAreaCard"
                                    >

                                        <div class="flexDisplay">
                                            <div class="alignCenterItemsFields">
                                                <div class="containerSelectsCards" nz-tooltip nzTooltipTitle="Setor">
                                                    <nz-select
                                                        nzPlaceHolder="Setor"
                                                        nzMode="multiple"
                                                        style="min-width: 100%; max-width: auto;"
                                                        formControlName="setoresString"
                                                        [compareWith]="compareFn"
                                                        >
                                                        <nz-option
                                                            *ngFor="let items of setores" [nzValue]="items"
                                                            [nzLabel]="items.depDescricao" [selected]="true"
                                                            >
                                                        </nz-option>
                                                    </nz-select>
                                                </div>
                                                <div class="containerSelectsCards" nz-tooltip nzTooltipTitle="Layout">
                                                    <div>
                                                            <nz-select
                                                                (ngModelChange)="extraiImagemExemploLayout($event, page.value.tpgId, i, idx)"
                                                                nzPlaceHolder="Layouts"
                                                                style="min-width: 100%; max-width: auto;"
                                                                formControlName="layId"
                                                                [nzDisabled]="!page.value.tpgId"
                                                                name="layId">
                                                                <nz-option
                                                                    *ngFor="let items of filterLayouts(layouts, page.value.tpgId)"
                                                                    [nzValue]="items.id"
                                                                    [nzLabel]="generateQtdDescr(items)"></nz-option>
                                                            </nz-select>
                                                    </div>
                                                </div>
                                                <label
                                                    nz-checkbox
                                                    formControlName="areAprovacao"
                                                    >
                                                    Necessita aprovação
                                                </label>
                                                <button nz-button nzType="link" style="color:red;width:150px" (click)="deleteArea(i, idx);">Remover Sessão</button>
                                            </div>
                                            <div class="alignCenterItemsEnd">
                                                <div>
                                                    <i
                                                        (click)="inverteAreaOrdemEmCima(area.value, i, idx)"
                                                        *ngIf="idx > 0"
                                                        class="iconUpOrder"
                                                        nz-icon nzType="caret-up"
                                                        nzTheme="outline"
                                                        nz-tooltip nzTooltipTitle="Enviar para cima"
                                                        nzTooltipPlacement="bottomRight">
                                                    </i>
                                                </div>
                                                <div>

                                                    <i
                                                        (click)="inverteAreaOrdemEmBaixo(area.value, i, idx)"
                                                        *ngIf="page.controls.areas.controls.length - 1 > idx"
                                                        class="iconDownOrder"
                                                        nz-icon nzType="caret-down"
                                                        nzTheme="outline"
                                                        nz-tooltip nzTooltipTitle="Enviar para baixo"
                                                        nzTooltipPlacement="bottomRight"
                                                        nzTooltipPlacement="bottomRight">
                                                    </i>
                                                </div>
                                                <div style="margin-left: 10px;">
                                                  <b>Sessão: {{idx+1}}</b>
                                                </div>
                                            </div>


                                        </div>
                                    </nz-card>
                                    <br>
                                    <p align="right">
                                    <button nz-button nzType="secondary" (click)="addArea(i)">Adicionar Sessão</button>
                                    </p>
                                </div>

                            </div>

                        </nz-card>
                      </div>
                </div>
                <br>
                <p align="right">
                <button nz-button nzType="secondary" (click)="addPage()">Adicionar Página</button>
                </p>

                <p align="right">
                    <button
                        class="form-button" nz-button nzType="link"
                        (click)="cancelar()">
                            Cancelar
                    </button>
                    <button nz-button nzType="primary"
                        (click)="salvarEncarte()">
                            Gravar
                    </button>
                </p>

            </form>
        </div>

    </ng-container>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>
