import { CampanhaEncarteLog } from './../../models/campanha-encarte-log';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CampanhaService } from '../../services/campanha.service';
import { Campanha } from 'src/app/models/campanha';
import { Encarte } from '../../models/encarte';
import { LojaService } from '../../services/loja.service';
import { Loja } from '../../models/loja';
import { CampanhaEncarteService } from '@services/campanhaencarte.service';
import { CampanhaEncarte } from '@models/campanhaencarte';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CampanhaEncarteArquivo } from '@models/campanha-encarte-arquivo';
import { CampanhaEncarteArquivoService } from '@services/campanha-encarte-arquivo.service';
import { EncarteService } from '@services/encarte.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { CampanhaEncarteLogService } from '@services/campanhaencartelog.service';
import { ImagemService } from '@services/imagem.service';
import { Linha } from '@models/linha';

@Component({
  selector: 'app-campanha-produto-arquivo',
  templateUrl: './campanha-produto-arquivo.component.html',
  styleUrls: ['./campanha-produto-arquivo.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CampanhaEncarteProdutoArquivoComponent implements OnInit {

  BOM: string = '\uFEFF';

  nameFileUpload:string = "";
  campanhaencartearquivo:CampanhaEncarteArquivo[];
  public form: UntypedFormGroup;
  public loading:boolean = false;
  campanhaencarteId = null;
  lojNomeFantasia = null;
  cmpNome = null;
  encDescricao = null;
  cmpDataProduto = null;
  cmpId = null;
  //public linhas = [];
  idCme = null;
  public modalProductsIsVisible = false;
  public modalLogsIsVisible = false;
  public campanhaLogs = [];


  public tipOperacaos = {'R':  "Reprovado", 'A': "Aprovado", 'I': "Reinício", 'G': "Arquivo Gerado" };

  encartes: Array<Object>;

  private httpClient: HttpClient;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private campanhaEncarteService: CampanhaEncarteService,
    private campanhaEncarteArquivoService: CampanhaEncarteArquivoService,
    private campanhaService:CampanhaService,
    private campanhaArquivoService: CampanhaEncarteArquivoService,
    private campanhaEncarteLogService: CampanhaEncarteLogService,
    private lojaService:LojaService,
    private encarteService:EncarteService,
    private toastr: ToastrService,
    private imagemService: ImagemService,
    private router: Router,
    private route: ActivatedRoute,
    private modal: NzModalService,
    http: HttpClient
    ) {

      this.httpClient = http;
      this.form = this.formBuilder.group({
        cmaArquivo: null,
        cmpDataProduto: null
      })

  }

  handleSalvarArquivo(): void {

    var submitFile : string = "";
    this.cabecalho.colunas.forEach(coluna => {
      submitFile += coluna.conteudo + ";"
    });
    submitFile = submitFile.slice(0, -1);
    submitFile += "\r\n";

    this.arquivoFormArray.controls.forEach( fb => {
      this.formGroupToArray(fb).forEach( c => {
        if (c != null)
          submitFile +=  c.value + ";"
        else
          submitFile +=  ";"
      })
      submitFile = submitFile.slice(0, -1);
      submitFile += "\r\n";
    });



    let campanhaencartearquivo = new CampanhaEncarteArquivo();
    campanhaencartearquivo.id = this.campanhaencarteId;
    campanhaencartearquivo.cmaArquivo = btoa(unescape(encodeURIComponent(submitFile)));

    if (campanhaencartearquivo.id != null) {

      this.loading = true;
      this.campanhaEncarteService.uploadCsv(campanhaencartearquivo)
          .subscribe(
            response => {
              this.loading = false;

              if (response.error) {
                this.toastr.error(response.message, 'Erro ao enviar Arquivo!');
              } else {
                this.toastr.success('Arquivo Enviado!', 'Iniciando o upload. Por favor, aguarde.');
              }
            },
            error => {
              this.loading = false;
              console.log(error);
              this.toastr.error(error.message, 'Algo saiu errado!');
            });
    }
    this.modalProductsIsVisible = false;
  }



  verCurrentImagemList(nome : string, idx : number): void {


    this.imagemService.read(nome, 1)
      .subscribe(
        response => {
          this.imagemArray[idx] = response.imgConteudo;
        },
        error => {
          console.log(error);
        });
    
  }




  showModal(): void {
    this.modalProductsIsVisible = true;
  }

  handleOk(): void {
    this.modalProductsIsVisible = false;
  }
  isEdit() {
    return this.form.get('id').value != null;
  }
  ngOnInit(): void{
    this.idCme = this.route.snapshot.paramMap.get('id')
    this.getCampanhaEncarte(this.idCme);
    this.getEncarte();
  }

  retornaNomeArquivoTratado (value) {

    this.nameFileUpload = value.substring(value.lastIndexOf('\\') + 1);;

  }
  uploadArquivoXlx (files:any, event) {

    this.loading = true;

    const uploaded = files[0];
    if (!uploaded) {
      return;
    }

    this.retornaNomeArquivoTratado(event.target.value)

    const reader = new FileReader();
    reader.onload = () => {
      this.form.get("cmaArquivo").setValue(reader.result)
    };

    reader.readAsDataURL(uploaded);
    this.loading = false;

    event.target.value = ''

  }

  getCampanhaEncarte(id): void {

    this.loading = true;
    this.campanhaEncarteService.read(id)
      .subscribe(
        campanhaencarte => {
          this.loading = false;
          this.campanhaencarteId = campanhaencarte.id;
          this.lojNomeFantasia = campanhaencarte.lojNomeFantasia;
          this.cmpNome = campanhaencarte.cmpNome;
          this.encDescricao = campanhaencarte.encDescricao;
          this.cmpDataProduto = campanhaencarte.cmpDataProduto;
          this.cmpId = campanhaencarte.cmpId;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(`${error.message}`, 'Erro ao recuperar Campanha!');
        });
  }

  enviaArquivosUpload () : void {

    var campanhaencartearquivo = new CampanhaEncarteArquivo();
    campanhaencartearquivo.id = this.campanhaencarteId;
    campanhaencartearquivo.cmaArquivo = this.form.get('cmaArquivo').value;

    if (campanhaencartearquivo.id != null) {

      this.loading = true;
      this.campanhaEncarteService.uploadCsv(campanhaencartearquivo)
          .subscribe(
            response => {
              this.loading = false;

              if (response.error) {

                this.toastr.error(response.message, 'Erro ao enviar Arquivo!');

              } else {

                this.toastr.success('Arquivo Enviado!', 'Iniciando o upload. Por favor, aguarde.');
                //this.router.navigateByUrl('campanha-prod/list');

              }

            },
            error => {
              this.loading = false;
              console.log(error);
              this.toastr.error(error.message, 'Algo saiu errado!');
            });
    }

  }

  cancelar(): void {
    this.router.navigateByUrl('/campanha-prod/list');
  }

  aprovar(): void {
    //this.campanhaEncarteService.aprovarArquivo(this.campanhaencarteId)
    var campanhaencartelog = new CampanhaEncarteLog();
    campanhaencartelog.id = this.campanhaencarteId;
    this.campanhaEncarteService.aprovarArquivo(campanhaencartelog)
      .subscribe(

        response => {
          this.loading = false;
          this.router.navigateByUrl('campanha-prod/list');
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao Aprovar Arquivo!');
        });
  }

  getEncarte(){
    this.loading=true;
    this.encarteService.listAll()
    .subscribe(encarte =>{
      this.encartes = encarte;
    });
  }

  arquivoFormArray : UntypedFormArray = this.formBuilder.array([]);
  cabecalho : any = null;
  imagemArray : string[] = []


  atualizaListaImagem(event : any, idx:number) {
    this.verCurrentImagemList(event.target.value, idx);
  }

  getArquivoJson(): void {
    const id = this.idCme;
    this.loading = true;
    this.arquivoFormArray = this.formBuilder.array([]);
    this.campanhaEncarteService.visualizarCsvTable(id)
      .subscribe(
        arquivo => {
          this.loading = false;
          var linhas = arquivo.linhas.filter(l => l.colunas.filter(c => c.conteudos));
          this.cabecalho = linhas[0];
          this.imagemArray = new Array<string>(linhas.length);

          linhas.shift();
          let i = 0;
          linhas.forEach(linha => {
            var fb = {};
            var j = 0;
            linha.colunas.forEach( (c:any) => {

              fb["col_" + j] = new UntypedFormControl(c.conteudo,null);
              if (this.cabecalho.colunas[j].conteudo.toUpperCase() == "FOTO")
                this.verCurrentImagemList(c.conteudo, i);

              j++;
            });

            i++;
            this.arquivoFormArray.push(
              this.formBuilder.group(
                fb
              )
            )

          });


          this.showModal();
        }, error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao buscar o Arquivo CSV!');
        });
  }

  atualizaImagens(): void {
    const id = this.idCme;
    this.loading = true;
    this.campanhaEncarteService.atualizaImagens(id)
      .subscribe(
        arquivo => {
          this.toastr.success("Imagens atualizadas.", 'Sucesso!');
          this.loading = false;
        }, error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao atualizar imagens!');
        });
  }

  formGroupToArray(linha: any) : UntypedFormControl[] {
    var cont : UntypedFormControl[] = [];
    for (var j = 0; j < this.cabecalho.colunas.length; j++) {
      cont.push(linha.get("col_" + j));
    }
    return cont;
  }


  sobeLinha(idx : number) {
    var current = this.arquivoFormArray.controls[idx];
    var prev = this.arquivoFormArray.controls[idx-1];
    this.arquivoFormArray.controls[idx-1] = current;
    this.arquivoFormArray.controls[idx] = prev;


    var currentIm = this.imagemArray[idx];
    var prevIm = this.imagemArray[idx-1];
    this.imagemArray[idx-1] = currentIm;
    this.imagemArray[idx] = prevIm;

  }

  desceLinha(idx : number) {
    var current = this.arquivoFormArray.controls[idx];
    var next = this.arquivoFormArray.controls[idx+1];
    this.arquivoFormArray.controls[idx+1] = current;
    this.arquivoFormArray.controls[idx] = next;

    var currentIm = this.imagemArray[idx];
    var prevIm = this.imagemArray[idx+1];
    this.imagemArray[idx+1] = currentIm;
    this.imagemArray[idx] = prevIm;

  }

/*
  verCurrentImagem(imagem): void {
    this.loading = true;
    this.imagemService.read(imagem,0)
      .subscribe(
        response => {
          this.modal.info(
            {
              nzTitle: response.imgNome,
              nzContent: "<img class=\"imgContentCss\" src=\"" + response.imgConteudo + "\">",
              nzWidth: 700
            }
          );
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao buscar Imagem!');
        });
  }
*/

  deleteCampanha(): void {

    this.loading = true;
    this.campanhaService.delete(this.cmpId)
    .subscribe(
      retorno => {
        this.toastr.success('Campanha Excluída!', 'Sucesso!');
        this.router.navigateByUrl('campanha-prod/list');
      },
      error => {
        this.loading = false;
        console.log(error);
      });
  }

  enviaArquivosDownload() {
    const id = this.idCme;
    this.loading = true;
    this.encarteService.pdfJsonPreview(id)
      .subscribe(
        response => {

          if (response.error) {

            this.toastr.error(response.message, 'Erro ao gerar arquivo!');
            this.loading = false;

          } else {

            this.loading = false;
            this.toastr.success('PDF Gerado!', 'Iniciando o download. Por favor, aguarde.');
            this.processaArquivoBlob();

          }

        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Algo saiu errado!');
        });

  }

  processaArquivoBlob() {
    this.loading = true;

    this.encarteService.pdfBlob()
      .subscribe(
        response => {
          saveAs(response, this.cmpNome+'_'+this.lojNomeFantasia+'.zip');
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao processar realizar o download.');
        });
  }

  readCampanhaLog(): void{
    this.loading = false;
    this.campanhaEncarteLogService.listCampanhaLog(this.idCme)
    .subscribe(campanhalog =>{
      this.campanhaLogs = campanhalog;
      this.showModalLog();
    });
  }
  showModalLog():void{
      this.modalLogsIsVisible = true;
  }
  handleOkLog(): void{
    this.modalLogsIsVisible=false;
  }

  downloadData(): void {
    const id = this.idCme;
    this.loading = true;
    this.arquivoFormArray = this.formBuilder.array([]);
    this.campanhaEncarteService.visualizarCsvTable(id)
      .subscribe(
        arquivo => {
          this.loading = false;
          var linhas = arquivo.linhas.filter(l => l.colunas.filter(c => c.conteudos));
          this.cabecalho = linhas[0];
          this.imagemArray = new Array<string>(linhas.length);

          linhas.shift();
          let i = 0;

          linhas.forEach(linha => {
            var fb = {};
            var j = 0;
            linha.colunas.forEach( (c:any) => {
              fb["col_" + j] = new UntypedFormControl(c.conteudo,null);
              j++;
            });

            i++;
            this.arquivoFormArray.push(
              this.formBuilder.group(
                fb
              )
            )

          });

          var submitFile : string = "";
          this.cabecalho.colunas.forEach(coluna => {
            submitFile += coluna.conteudo + ";"
          });
          submitFile = submitFile.slice(0, -1);
          submitFile += "\r\n";
      
          this.arquivoFormArray.controls.forEach( fb => {
            this.formGroupToArray(fb).forEach( c => {
              if (c != null)
                submitFile +=  c.value + ";"
              else
                submitFile +=  ";"
            })

            submitFile = submitFile.slice(0, -1);
            submitFile += "\r\n";
          });
      


          let blob = new Blob([this.BOM + submitFile], { type: "text/csv;charset=utf-8" });
          saveAs(
            blob,
            `${this.cmpNome}.csv`
          );

        }, error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao buscar o Arquivo CSV!');
        });
  }



}
