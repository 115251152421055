<div>
    <nz-page-header class="site-page-header" nzTitle="Grupo de Usuários" nzSubtitle="Dados do Grupo de Usuários">
    </nz-page-header>

    <ng-container class="edit-form"  #customLoadingTemplate>
        <form nz-form [formGroup]="form">
<!-- COMEÇO -->
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Descrição</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroNome">
                    <input nz-input formControlName="gusNome" name="gusNome" size="64" />
                    <ng-template #erroNome let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 64 caracteres<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Funcionalidades</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <div *ngFor="let items of perfis">
                        <input type="checkbox" nz-input [formControlName]="items.name" /> {{ items.aenDescricao }}
                    </div>
                </nz-form-control>
            </nz-form-item>

<!-- FIM -->
            <p align="right">
                <button class="form-button" nz-button nzType="link" (click)="cancelar()">Cancelar</button>
                <button class="form-button" [disabled]="!form.valid" nz-button nzType="primary" (click)="salvarGrupoUsuario()">Gravar</button>
            </p>
        </form>

    </ng-container>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

</div>
