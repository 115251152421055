import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TipoPaginaService } from '@services/tipo-pagina.service';
import { TipoPagina } from 'src/app/models/tipoPagina';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tipos-pagina-list',
  templateUrl: './tipos-pagina-list.component.html',
  styleUrls: ['./tipos-pagina-list.component.css']
})
export class TiposPaginaListComponent implements OnInit {

  typePages: TipoPagina[];

  public loading:boolean = false;

  constructor(
    private typePageService:TipoPaginaService, 
    private router: Router,
    private toastr: ToastrService) {}

  ngOnInit(): void {
    this.readTipePage();
  }

  readTipePage(): void {

    this.loading = true;
    this.typePageService.listAllAdm()
      .subscribe(
        typePages => {
          this.loading = false;
          this.typePages = typePages;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao listar Tipo de Página!');
        });
  }

  createTipoPagina(): void {
    this.router.navigateByUrl('/paginas/form');
  }

  editTipoPagina(page): void {
    this.router.navigateByUrl('/paginas/form/' + page.id);
  }

  delete(typePages): void {
    this.deleteTipoPagina(typePages);
    this.typePages = this.typePages.filter(item => item.id != typePages.id);
  }

  deleteTipoPagina(page): void { //sem retorno

    this.loading = true;
    this.typePageService.deleteAdm(page.id).subscribe(
      response => {
        this.toastr.success('Tipo de Página Excluído!', 'Sucesso!');
        this.readTipePage()
      },
      error => {
        this.loading = false;
        console.log(error);
        if (error.status == 200) this.toastr.success('Tipo de Página Excluído!', 'Sucesso!');
        else this.toastr.error(error.message, 'Erro ao excluir Tipo de Página!');
      });
  }

}
