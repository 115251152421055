<div>
  <nz-page-header class="site-page-header" nzTitle="Aprovação da Gerência" nzSubtitle="Aprovar dados">
  </nz-page-header>
  <div class="acompanhamento">
    <div class="acompanhamento__header">
      <div class="acompanhamento__infos">
        <div class="acompanhamento__left">
          <p class="acompanhamento__title">Nome da Campanha: <span class="acompanhamento__description">{{ cmpNome }}</span></p>
          <p class="acompanhamento__title">Nome da Loja: <span class="acompanhamento__description">{{ lojNomeFantasia }}</span></p>
          <p class="acompanhamento__title">Nome do Encarte: <span class="acompanhamento__description">{{encDescricao}}</span></p>
          <p class="acompanhamento__title">Data/Hora Limite: <span class="acompanhamento__description">{{cmpDataGerencia | date: 'dd/MM/yyyy HH:mm'}}</span></p>
        </div>
      </div>
    </div>
  </div>
  <ng-container class="edit-form" #customLoadingTemplate>

    <form nz-form [formGroup]="form">
      <div>
        <nz-table [nzData]="linhas" #rowSelectionTable [nzScroll]="{ x: 200 * linhas[0]?.colunas.length + 'px' }"
          id='table-select'>
          <thead *ngFor="let linha of linhas;">
            <th *ngFor="let coluna of linha?.colunas;" class="title">
              <b>
                {{coluna.conteudo.replace("_", " ")}}
              </b>
            </th>
          </thead>
          <tbody *ngFor="let linha of rowSelectionTable.data;let i = index;">
            <tr tabindex="1" *ngIf="linha.idx < linhas.length - 1">
              <td *ngFor="let coluna of linha?.colunas;" align="center">
                <span>
                  <button nz-button nzType="secondary" *ngIf="coluna.conteudo.endsWith('.png')"
                    (click)="verCurrentImagem(coluna.conteudo)">Ver</button>
                  <span *ngIf="!coluna.conteudo.endsWith('.png')">
                    {{coluna.conteudo}}
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
      <nz-form-item>
          <nz-form-label [nzSpan]="5">Comentários</nz-form-label>
          <nz-form-control [nzSpan]="24" [nzErrorTip]="erroDescricao">
            <textarea rows="8" cols="80" formControlName="celDescricao" name="celDescricao">teste</textarea>
            <ng-template #erroDescricao let-control>
                <ng-container *ngIf="control.hasError('required')">
                    Campo obrigatório<br>
                </ng-container>
            </ng-template>
          </nz-form-control>
      </nz-form-item>

      <p align="right">
        <button class="form-button" nz-button nzType="link" (click)="cancelar()">Cancelar</button>
        <button class="form-button" nz-button nzType="link"
          (click)="enviaArquivosDownload(idCampanha)">Gerar&nbsp;Arquivo</button>&nbsp;
        <button class="form-button" nz-button nzType="primary" nzDanger
          (click)="reprovarCurrentGerencia()">Reprovar</button>&nbsp;
        <button class="form-button" nz-button nzType="primary"
          (click)="aprovarCurrentGerencia()">Aprovar</button>


      </p>
    </form>
  </ng-container>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>



</div>
