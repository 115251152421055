<nz-page-header
  class="site-page-header"
  nzTitle="Usuário"
  nzSubtitle="Usuários cadastrados"
>
</nz-page-header>
<ng-container #customLoadingTemplate>
  <nz-table  [nzData]="usuarios" #rowSelectionTable>
    <thead>
      <tr>
        <th>Nome do Usuário</th>
        <th>Perfil</th>
        <th width="150px"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let usuario of rowSelectionTable.data"
        class="usuario-status-{{ usuario.usuStatus }}"
      >
        <td>{{ usuario.usuNome }}</td>
        <td>
          {{ usuario.gusNome }}
        </td>
        <td>
          <a (click)="editCurrentUsuario(usuario)">Editar</a>&nbsp;
          <a
            nz-popconfirm
            nzPopconfirmTitle="Deseja remover esse item?"
            nzOkText="Sim"
            nzCancelText="Não"
            (nzOnConfirm)="delete(usuario)"
            >Remover</a
          >
        </td>
      </tr>
    </tbody>
  </nz-table>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</ng-container>
<br />
<p align="right">
  <button
    class="form-button"
    nz-button
    nzType="primary"
    (click)="createUsuario()"
  >
    Novo
  </button>
</p>
