<nz-page-header class="site-page-header" nzTitle="Campanhas Aprovadas" nzSubtitle="Gerar arquivo para impressão">
</nz-page-header>
<div>
    <ng-container #customLoadingTemplate>
        <div>
            <nz-table [nzData]="campanhasencarte" #rowSelectionTable>
                <thead>
                    <tr>
                        <th width="50%" nzShowSort="true" [nzSortFn]="sortColunaCmpNome">Campanha</th>
                        <th width="50%">Loja</th>
                        <th width="150px"></th>
                        <th width="150px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let campanhaencarte of rowSelectionTable.data;">
                      <td>{{campanhaencarte.cmpNome}}</td>
                        <td>{{campanhaencarte.lojNomeFantasia}}</td>
                        <td>
                            <a (click)="enviaArquivosDownload(campanhaencarte)">Gerar&nbsp;Arquivo</a>&nbsp;
                        </td>
                        <td>
                            <a (click)="finaliza(campanhaencarte)">Finalizar</a>&nbsp;
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </ng-container>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</div>
