<nz-page-header class="site-page-header" nzTitle="Grupo de Usuários" nzSubtitle="Grupo de Usuários cadastrados">
</nz-page-header>
<div>
    <ng-container #customLoadingTemplate>
        <div>
            <nz-table [nzData]="grupousuarios" #rowSelectionTable>
                <thead>
                    <tr>
                        <th>Nome do Grupo de Usuario</th>
                        <th width="150px"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let grupousuario of rowSelectionTable.data;">
                        <td>{{grupousuario.gusNome}}</td>
                        <td>
                            <a (click)="editCurrentGrupoUsuario(grupousuario)">Editar</a>&nbsp;
                            <a nz-popconfirm nzPopconfirmTitle="Deseja remover esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="delete(grupousuario)">Remover</a>
                        </td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
    </ng-container>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
<br>
<p align="right">
    <button class="form-button" nz-button nzType="primary" (click)="createGrupoUsuario()">Novo</button>
</p>
