import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {

  currentYear = new Date().getFullYear();
  menuIsOpen = false;
  planosModel = null;

  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    ) {

  }

  toggleMenu() {
    this.menuIsOpen = !this.menuIsOpen;
  }

  scrollSection({selectorLinks, containerSection, activeClass}){

    if ( typeof selectorLinks !== 'string' || !selectorLinks ){
      throw new Error("selectorLinks is not valid");
    }

    const links = document.querySelectorAll(selectorLinks);
    const containerSections = document.querySelector(containerSection);
    const activeClassName = (activeClass || 'active').replace('.', '');

    links.forEach( link => {

      link.addEventListener('click', (event) => {
        event.preventDefault();
        addActiveClass(event.target, activeClassName);
        const sectionId = (event.target as any).getAttribute('href');
        const section = (containerSections || document).querySelector(sectionId);
      });

    });

    function addActiveClass(element, className){
      links.forEach( el => el.classList.remove(className) );
      element.classList.add(className);
    }

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length > 0) {


      }
    });

    this.loginService.logout();
    this.router.navigateByUrl('/login');

    this.scrollSection({
      selectorLinks: ".smooth-link[href^='#']",
      containerSection: ".wrapper__article",
      activeClass: ".smooth-link--active"
    });
  }

}
