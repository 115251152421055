<div>
    <nz-page-header class="site-page-header" nzTitle="Registro da Empresa" ></nz-page-header>

    <ng-container class="edit-form"  #customLoadingTemplate style="width:95%">
        <form nz-form [formGroup]="form" *ngIf="!dadosEnviados">
            <nz-divider nzText="Dados da Empresa" nzOrientation="left"></nz-divider>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Razão Social</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroRazaoSocial">
                    <input nz-input formControlName="empRazaoSocial" name="empRazaoSocial" class="input-field" placeholder="Razão social da empresa"/>
                    <ng-template #erroRazaoSocial let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 256 caracteres<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Nome Fantasia</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroNome">
                    <input nz-input formControlName="empNomeFantasia" name="empNomeFantasia" class="input-field" placeholder="Nome fantasia da empresa"/>
                    <ng-template #erroNome let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 256 caracteres<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
             <nz-form-item>
                <nz-form-label [nzSpan]="5">CNPJ</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroCNPJ">
                    <input nz-input formControlName="empCNPJMatriz" name="empCNPJMatriz" size="24" placeholder="CNPJ da empresa" mask="00.000.000/0000-00" />
                    <ng-template #erroCNPJ let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 18 caracteres<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('minlength')">
                            Preencha o CNPJ completo<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('invalidCnpj')">
                            CNPJ inválido<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">CEP</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroCEP">
                    <input nz-input formControlName="logId" name="logId" size="15" placeholder="CEP" mask="00000-000"/>
                    <ng-template #erroCEP let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 9 caracteres<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('minlength')">
                            Preencha o CEP completo<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('cepInvalid')">
                            CEP inválido ou inexistente<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Endereço</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroRua">
                    <input nz-input formControlName="empRua" name="empRua" class="input-field" placeholder="Rua da empresa"/>
                    <ng-template #erroRua let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 255 caracteres<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Número</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroRua">
                    <input nz-input formControlName="empNumeroMatriz" name="empNumeroMatriz" placeholder="Número" style="width:100px"/>
                    <ng-template #erroRua let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 32 caracteres<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="5">Cidade</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <div  class="endereco">{{ cidadeUF }}</div>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Complemento</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroComplemento">
                    <input nz-input formControlName="empComplementoMatriz" name="empComplementoMatriz" class="input-field" placeholder="Complemento do endereço da empresa, se houver" />
                    <ng-template #erroComplemento let-control>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 64 caracteres<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Telefone</nz-form-label>
                  <nz-form-control [nzSpan]="12" [nzErrorTip]="erroTelefone" >
                      <input nz-input mask="(00) 00000-0000||(00) 0000-0000" formControlName="empNumTelefoneMatriz" name="empNumTelefoneMatriz" placeholder="Telefone"  />
                      <ng-template #erroTelefone let-control>
                          <ng-container *ngIf="control.hasError('requiredTelefone')">
                              Telefone é um campo obrigatório<br>
                          </ng-container>
                          <ng-container *ngIf="control.hasError('patternTelefone')">
                              Digite o telefone no formato correto<br>
                          </ng-container>
                      </ng-template>
                  </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Logomarca</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <div class="img-space">
                        <ng-container *ngIf="isImageSaved; else elseTemplate">
                            <img [src]="cardImageBase64" width="140" height="140" />
                        </ng-container>
                        <ng-template #elseTemplate>
                            <img src="" class="img-responsive">
                        </ng-template>
                    </div>

                    <input
                        style="margin-bottom: 1rem; border-style:none; display:none"
                        type="file"
                        (change)="fileChangeEvent($event)"
                        id="img"
                        class="custom-file-input">
                        <label class="labelButtonUploadImage" for="img">
                            Adicionar Imagem
                        </label>

                    <div><b>Dica: </b>Utilize imagem com fundo <b>branco ou transparente</b>. A imagem deve ter as mesmas dimensões de altura e largura (<b>quadrada</b>).</div>
                    <div style="color:#ff4d4f;width:80%">{{ imageError }}</div>
                </nz-form-control>
            </nz-form-item>
            <nz-divider nzText="Dados do Usuário Administrador" nzOrientation="left"></nz-divider>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Nome do Usuário</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroNome">
                    <input nz-input formControlName="usuNome" name="usuNome" class="input-field" placeholder="Nome do usuário"/>
                    <ng-template #erroNome let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 256 caracteres<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
           <nz-form-item>
                <nz-form-label [nzSpan]="5">Email</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroEmail">
                    <input nz-input formControlName="usuLogin" name="usuLogin" class="input-field" placeholder="Email do usuário"  />
                    <ng-template #erroEmail let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 128 caracteres<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('duplicated')">
                            Este email já está em uso por outro usuário<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('pattern')">
                            Este email não é válido<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="5">Senha</nz-form-label>
            <nz-form-control [nzSpan]="12" [nzErrorTip]="erroSenha">
                <input nz-input type="password" formControlName="usuSenha" name="usuSenha" size="32" placeholder="Senha do usuário" autocomplete="new-password"/>
                <ng-template #erroSenha let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        Campo obrigatório<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('complexity')">
                        A senha deve conter letras maiúsculas, miniscúlas, números e no mínimo 8 caracteres<br>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label [nzSpan]="5">Confirme a senha</nz-form-label>
            <nz-form-control [nzSpan]="12" [nzErrorTip]="erroSenha2">
                <input nz-input type="password" formControlName="usuSenha2" name="usuSenha2" size="32" placeholder="Confirmação da senha do usuário"   autocomplete="new-password"/>
                <ng-template #erroSenha2 let-control>
                    <ng-container *ngIf="control.hasError('required')">
                        Campo obrigatório<br>
                    </ng-container>
                    <ng-container *ngIf="control.hasError('confirm')">
                        Confirme com a mesma senha informada<br>
                    </ng-container>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
            <p align="right">
                <button class="form-button" [disabled]="!form.valid" nz-button nzType="primary" (click)="salvarRegistro()">Enviar</button>
            </p>
        </form>
        <nz-result
            *ngIf="dadosEnviados"
            nzStatus="success"
            nzTitle="Sua solicitação foi enviada com sucesso!"
            nzSubTitle="Nosso time de operações entrará em contato para validar suas informações em até 2 dias úteis.
            Após a validação das informações você receberá um email contendo todos as instruções para acessar nossa plataforma."
        >
        <div nz-result-extra>
            <b>Obrigado por nos ajudar a deixar o mundo mais limpo!</b>
          </div>
        </nz-result>
    </ng-container>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

</div>
