<nz-page-header class="site-page-header" nzTitle="Layouts" nzSubtitle="Layouts cadastrados">
</nz-page-header>

<ng-container  #customLoadingTemplate>
  <form nz-form [formGroup]="filtroForm">
    <nz-form-item>
      <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
        <nz-form-control  [nzSpan]="24" >
           <input nz-input type="text" formControlName="filtro" name="filtro"  size="64" />&nbsp;
           <button nz-button nzType="primary" (click)="executaFiltro()">Buscar</button>
        </nz-form-control>
    </nz-form-item>
  </form>

    <nz-table [nzData]="layouts" #rowSelectionTable>
        <thead>
            <tr>
                <th width="30%">Nome do Layout</th>
                <th width="30%">Empresa</th>
                <th width="15%">Mídia</th>
                <th width="10%">Altura (mm)</th>
                <th width="150px"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let layout of rowSelectionTable.data;">
                <td>{{layout.layDescricao}}</td>
                <td>{{layout.empNomeFantasia}}</td>
                <td>{{deParaTipo(layout.slaId)}}</td>
                <td align="right">{{layout.layAltura}}</td>
                <td>
                    <a (click)="editLayout(layout)">Editar</a>&nbsp;
                    <a nz-popconfirm nzPopconfirmTitle="Deseja remover esse item?" nzOkText="Sim" nzCancelText="Não" (nzOnConfirm)="delete(layout)">Remover</a>
                </td>
            </tr>
        </tbody>
    </nz-table>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
</ng-container>
<br>
<p align="right">
    <button class="form-button" nz-button nzType="primary" (click)="createLayout()">Novo</button>
</p>
