<nz-page-header class="site-page-header" nzTitle="Empresa" [nzSubtitle]="modo">
</nz-page-header>
<ng-container #customLoadingTemplate>
  <form nz-form [formGroup]="filtroForm">
    <nz-form-item>
      <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
        <nz-form-control  [nzSpan]="24" >
           <input nz-input type="text" formControlName="filtro" name="filtro"  size="64" />&nbsp;
           <button nz-button nzType="primary" (click)="executaFiltro()">Buscar</button>
        </nz-form-control>
    </nz-form-item>
  </form>

    <nz-table  [nzData]="empresas" #rowSelectionTable>
        <thead>
            <tr>
                <th>Razão Social</th>
                <th>CNPJ</th>
                <th>Status</th>
                <th width="150px"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let empresa of rowSelectionTable.data;">
                <td>{{empresa.empRazaoSocial}}</td>
                <td>{{empresa.empCNPJMatriz}}</td>
                <td>{{empresa.empStatus}}</td>
                <td>
                    <a (click)="editCurrentEmpresa(empresa)">Visualizar</a>&nbsp;
                </td>
            </tr>
        </tbody>
    </nz-table>
</ng-container>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>
