import { Component, OnInit } from '@angular/core';
import { SetorService } from 'src/app/services/setor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Setor } from 'src/app/models/setor';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-setor-form',
  templateUrl: './setor-form.component.html',
  styleUrls: ['./setor-form.component.css']
})
export class SetorFormComponent implements OnInit {

  setorId = null;
  form = new UntypedFormGroup({
    depDescricao: new UntypedFormControl(null, [Validators.required,  Validators.maxLength(64)]),
  });

  public loading:boolean = false;

  constructor(
    private setorService: SetorService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') != undefined)
      this.getSetor(this.route.snapshot.paramMap.get('id'));
  }

  getSetor(id): void {

    this.loading = true;
    this.setorService.read(id)
      .subscribe(
        setor => {
          this.loading = false;
          this.setorId = setor.id;
          this.form.get('depDescricao').setValue(setor.depDescricao);
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao Recuperar!');
           
        });
  }



  cancelar(): void {
    this.router.navigateByUrl('/setor/list');
  }


salvarSetor(): void {

  var str = new Setor();
  str.depDescricao = this.form.get('depDescricao').value;

  if (this.setorId != null) {
    str.id = this.setorId;

    this.loading = true;
    this.setorService.update(str)
      .subscribe(
        response => {
          this.loading = false;
          this.toastr.success('Setor Salvo!', 'Gravado com Sucesso!');
          this.router.navigateByUrl('/setor/list');
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao Salvar!');
           
        });
    } else {
      this.setorService.create(str)
      .subscribe(
        response => {
          this.loading = false;
          this.toastr.success('Setor Salvo!', 'Gravado com Sucesso!');
          this.router.navigateByUrl('/setor/list');
        },
        error => {
          this.loading = false;
          console.log(error);
          this.toastr.error(error.message, 'Erro ao Salvar!');
           
        });
    }

  }


}
