import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { LoginService } from '@services/login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private loginService: LoginService
    ) { }

    routeMap = {
      "ROLE_ADMIN":"empresa|layout|paginas",
      "ROLE_EMPRESA_CAMPANHA":"campanha",
      "ROLE_EMPRESA_ARQUIVO":"campanha-prod",
      "ROLE_EMPRESA_GERENTE":"campanha-ger",
      "ROLE_EMPRESA_DIRETOR":"campanha-dir",
      "ROLE_EMPRESA_PDF":"config",
      "ROLE_EMPRESA_ENCARTE":"encarte",
      "ROLE_EMPRESA_IMAGEM":"imagem",
      "ROLE_EMPRESA_LOJA":"loja",
      "ROLE_EMPRESA_SETOR":"setor",
      "ROLE_EMPRESA_USUARIO":"usuario",
      "ROLE_EMPRESA_GRUPO":"grupousuario"
    }



    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.loginService.currentUserValue;
        const currentUserRole = this.loginService.currentRole;


        let bAcesso = false;
        if (currentUser) {
            currentUserRole.forEach(role => {
              var roles = this.routeMap[role];

              if (roles.indexOf(route.url[0].path) >=0) {
                bAcesso = true;
              }
            });

            return bAcesso;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
