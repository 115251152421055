import { Component, OnInit } from '@angular/core';
import { EmpresaService } from 'src/app/services/empresa.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Registro } from 'src/app/models/registro';
import { UntypedFormControl, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Observable, Observer } from 'rxjs';
import { CepService } from '@services/cep.service';


@Component({
  selector: 'app-registro-form',
  templateUrl: './registro-form.component.html',
  styleUrls: ['./registro-form.component.css']
})

export class RegistroFormComponent implements OnInit {

  cidadeUF = "Preencha o CEP";
  bairro = "Preencha o CEP";
  planosModel = null;
  dadosEnviados = false;

  public loading:boolean = false;

  constructor(
    private empresaService: EmpresaService,
    private usuarioService: UsuarioService,
    private cepService: CepService,
    private router: Router) { }

  ngOnInit(): void {
    // this.readPlano();
    for (var f in this.form.controls) {
      this.form.controls[f].markAsTouched();
    }
  }

  validaCep = (control: UntypedFormControl) =>
  new Observable((observer: Observer<ValidationErrors | null>) => {
    this.cidadeUF = 'Preencha o CEP';

    if (this.form.get('logId').value != "") {

      this.loading = true;
      this.cepService.consulta(this.form.get('logId').value.replace('-', '')).subscribe(
        endereco => {
          this.loading = false;
          if (endereco.id != null) {
            if (endereco.logTipo != null && endereco.logTipo != "")
              this.form.get('empRua').setValue(endereco.logTipo + '  ' + endereco.logNome);
            this.cidadeUF = endereco.logCidade + ' - ' + endereco.logUF;
            observer.next(null);
            observer.complete();
          } else {
            observer.next({ cepInvalid: true, error: true});
            observer.complete();
          }
        }
      )
    } else {
      observer.next(null);
      observer.complete();

    }
});


  cancelar(): void {
    this.router.navigateByUrl('/registro/list');
  }


  salvarRegistro(): void {

    var reg = new Registro();
    reg.emlLogotipo = this.form.get('emlLogotipo').value;
    reg.empCNPJMatriz = this.form.get('empCNPJMatriz').value;
    reg.empComplementoMatriz = this.form.get('empComplementoMatriz').value;
    reg.empNomeFantasia = this.form.get('empNomeFantasia').value;
    reg.empDDDMatriz = this.form.get('empNumTelefoneMatriz').value.substring(0,2);
    reg.empNumTelefoneMatriz = this.form.get('empNumTelefoneMatriz').value.substring(2);
    reg.empNumeroMatriz = this.form.get('empNumeroMatriz').value;
    reg.empRua = this.form.get('empRua').value;
    reg.empRazaoSocial = this.form.get('empRazaoSocial').value;
    reg.logId = this.form.get('logId').value.replace("-", "");
    reg.usuLogin = this.form.get('usuLogin').value;
    reg.usuNome = this.form.get('usuNome').value;
    reg.usuSenha = this.form.get('usuSenha').value;
    reg.plaId = this.form.get('plaId').value;
    if (this.cardImageBase64 != null && this.cardImageBase64.length > 0)
      reg.emlLogotipo = this.cardImageBase64;

    this.loading = true;
    this.empresaService.create(reg)
      .subscribe(
        response => {
          this.loading = false;
          this.router.navigateByUrl('/login');
          this.dadosEnviados = true;
        },
        error => {
          this.loading = false;
          console.log(error);
        });
  }

  validaEmail = (control: UntypedFormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {

      this.loading = true;
      this.usuarioService.check(this.form.get('usuLogin').value).subscribe(
        usuario => {
          this.loading = false;
          if (usuario.disponivel == "N") {
            observer.next({ duplicated: true, error: true });
            observer.complete();
          } else
            observer.next(null);
          observer.complete();
        },
        error => {
          this.loading = false;
          observer.next(null);
          observer.complete();
        });


    });

  confirmationValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null && this.form.get('usuSenha2').value !== this.form.get('usuSenha').value) {
      return { confirm: true, error: true };
    }
    return {};
  };

/*
  requiredDDD = (control: FormControl): { [s: string]: boolean } => {
    if (this.form != null && (this.form.get('empDDDMatriz').value == null || this.form.get('empDDDMatriz').value.length == 0)) {
      return { requiredDDD: true, error: true };
    }
    return {};
  }
*/

  requiredTelefone = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null && (this.form.get('empNumTelefoneMatriz').value == null || this.form.get('empNumTelefoneMatriz').value.length == 0)) {
      return { requiredTelefone: true, error: true };
    }
    return {};
  }

/*
  patternDDD = (control: FormControl): { [s: string]: boolean } => {
    if (this.form != null) {
      var formValue = this.form.get('empDDDMatriz').value;
      var isValid = /[1-9]{2}/.test(formValue);

      if (!isValid) {
        return { patternDDD: true, error: true };
      }
    }
    return {};
  }
*/

  patternTelefone = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null) {
      var formValue = this.form.get('empNumTelefoneMatriz').value;
      var isValid = /[0-9]{10,11}/.test(formValue);
      if (!isValid) {
        return { patternTelefone: true, error: true };
      }
    }
    return {};
  }

  checkCNPJ = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null && this.form.get('empCNPJMatriz').value != null) {
      var formValue = this.form.get('empCNPJMatriz').value;
      var isValid = this.validarCNPJ(formValue);

      if (!isValid) {
        return { invalidCnpj: true, error: true };
      }
    }
    return {};
  }


  validarCNPJ(cnpj:string) {
    if (cnpj.length < 14)
      return true;

    cnpj = cnpj.replace(/[^\d]+/g,'');

    if(cnpj == '') return false;

    if (cnpj.length != 14)
        return false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;

    // Valida DVs
    var tamanho:number = cnpj.length - 2
    var numeros:string = cnpj.substring(0,tamanho);
    var digitos:string = cnpj.substring(tamanho);
    var soma:number = 0;
    var pos:number = tamanho - 7;
    for (var i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    var resultado:number = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != Number(digitos.charAt(0)))
        return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += Number(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != Number(digitos.charAt(1)))
          return false;

    return true;

  }


  complexityValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null) {
      var password = this.form.get('usuSenha').value;
      var hasLength = (password != null && password.length > 7);
      var hasUpperCase = /[A-Z]/.test(password);
      var hasLowerCase = /[a-z]/.test(password);
      var hasNumbers = /\d/.test(password);
      //var hasNonalphas = /\W/.test(password);
      if (!hasUpperCase || !hasLowerCase || !hasNumbers || !hasLength)
        return { complexity: true, error: true };
    }
    return {};
  };

  form = new UntypedFormGroup({
    empRazaoSocial: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
    empRua: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
    empNomeFantasia: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
    empCNPJMatriz: new UntypedFormControl(null, [Validators.required, Validators.maxLength(14),Validators.minLength(14), this.checkCNPJ]),
    logId: new UntypedFormControl(null, [Validators.required,  Validators.minLength(8), Validators.maxLength(8)], this.validaCep),
    empNumeroMatriz: new UntypedFormControl(null, [Validators.required, Validators.maxLength(32)]),
    empComplementoMatriz: new UntypedFormControl(null, [Validators.maxLength(64)]),
    //empDDDMatriz: new FormControl(null, [this.requiredDDD, this.patternDDD]),
    empNumTelefoneMatriz: new UntypedFormControl("", [this.requiredTelefone, this.patternTelefone /*, this.requiredDDD, this.patternDDD*/]),
    plaId: new UntypedFormControl(null, null),

    usuLogin: new UntypedFormControl(null, [Validators.required, Validators.maxLength(128), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")], [this.validaEmail]),
    usuNome: new UntypedFormControl(null, [Validators.required, Validators.maxLength(256)]),
    usuSenha: new UntypedFormControl(null, [Validators.required, Validators.maxLength(32), this.complexityValidator]),
    usuSenha2: new UntypedFormControl(null, [Validators.required, Validators.maxLength(32), this.confirmationValidator]),

    emlLogotipo: new UntypedFormControl(null, null),

  });





  // Upload
  imageError: string;
  cardImageBase64: string;
  isImageSaved: boolean = false;
  fileChangeEvent(fileInput: any) {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 3 * 1024 * 1024;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 1200;
      const max_width = 1200;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Tamanho máximo permitido é de ' + max_size / 1024 / 1024 + 'Mb';

        return false;
      }

      if (!allowed_types.includes(fileInput.target.files[0].type)) {
        this.imageError = 'Somente imagens nos formatos JPEG e PNG são permitidas';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];


          if (img_height > max_height && img_width > max_width) {
            this.imageError =
              'Imagem não possui as dimensões apropriadas. Dimensão máxima permitida é  ' +
              max_height +
              'px de largura por ' +
              max_width +
              'px de altura';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;

          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }


}
