import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ImagemService } from '@services/imagem.service';
import { ActivatedRoute,  Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import SVGCanvas from '@svgedit/svgcanvas';
import { isChrome } from '@svgedit/svgcanvas/common/browser.js';
import { fileOpen, fileSave } from 'browser-fs-access';
import { SvgService } from '@services/svg.service';
import { SvgTemplate } from '@models/svg-template';
import { SvgArquivo } from '@models/svg-arquivo';
import { SvgEncarte } from '@models/svg-encarte';
import { saveAs } from 'file-saver';
import { CampanhaEncarteService } from '@services/campanhaencarte.service';
import { CampanhaEncarteSVG } from '@models/campanha-encarte-svg';



interface iButtonSetting{
  name?:string;
  buttonColor?:string;
  textColor?:string;
  shape?:string;
  width?:number;
  height?:number;
  fontSize?:number;
}

//const { $qa, $id, $click, isValidUnit, getTypeMap, convertUnit } = SVGCanvas;

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.css']
})

export class SVGComponent implements OnInit, AfterViewInit 
{
  handle = null;
  svgCanvas: any;
  hiddenTextTag:any;
  getScreenWidth: any;
  getScreenHeight: any;
  tipo!:any;

  BOM: string = '\uFEFF';
  nameFileUpload:string = "";

  productTextFilter:string = '';
  fontFamily:string = 'Futura';
  buttomsize:string = 'normal';
  hiddenTextTagId:string = 'text';
  color:string = 'red';
  colorStroke:string = 'red';
  alignReference:string = 'selected';
  id:string | null = null;
  chooseCard: string = '';
  chooseEncarte: string = '';
  arquivo_id!:number | string;
  idCard: string = '';
  origem: string = '';
  private data:string = '';
  private squareColor: string = 'FF0000';
  setPageSize:string | null = null;
  imageId:string | null = null;

  fontSize:number | string = 8;
  pagina_exclusao!:number;
  textWeight:number | string = 'Normal';
  xWidth:number = window.innerWidth-750;
  yHeight:number = window.innerHeight-100;
  defaultStroke:number = 1;
  private count:number = 0;
  paginaAtual:number = 0;
  pageQuantity!:number;
  svgWidth!:number;
  svgHeight!:number;
  fatorZoom:number = 1;
  dpi!:number;
  original_width!:number;
  original_height!:number;
  original_dpi!:number;

  element_width!:number;
  element_height!:number;
  element_fill!:string;
  element_stroke!:string;
  element_x!:number | string;
  element_y!:number | string;

 
  isProductBar:boolean = false;
  cardNameVisible:boolean = false;
  loading:boolean = false;
  isVisible:boolean = false;
  isVisiblePageSize:boolean = false;
  show_posicao_xy:boolean = false;
  show_width_height:boolean = false;
  private customExportPDF:boolean = false;

  listaTemplates:Array<SvgTemplate> = [];
  listaArquivos:Array<SvgArquivo> = [];
  productList!:any;
  listOfPages:Array<String> = [];
  listOfSizes:Array<String> = ["100x300", "500x700", "350x600", "400x800", "500x500"];
  originalProductList!:Array<{}>;
  showElements: Array<{element:string, status:boolean, id:number}> = [];

  millimeters_in_an_inch:number = 25.4;
  default_dpi:number = 100;

  campanhaencarteId = null;
  cmpNome = null;
  encDescricao = null;
  cmpDataProduto = null;
  cmpId = null;
  //public linhas = [];
  idCme = null;

  atributos!:any;
  produto_formatado!:any;
  tagPosicao!:any;
  

  private httpClient: HttpClient;
  form: UntypedFormGroup;
  
  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private campanhaEncarteService: CampanhaEncarteService,
    private toastr: ToastrService,
    private http: HttpClient,
    private renderer: Renderer2,
    private SvgService:SvgService,
    private route: ActivatedRoute,
    private imagemService: ImagemService) 
  {
    this.httpClient = http;
    this.form = this.formBuilder.group({
      id:null,
      seaArquivo:null,
    })
   }


  @ViewChild('canvas')
  public containerEl!: ElementRef<HTMLDivElement>;


  private readonly config = 
  {
    initFill: { color: this.squareColor, opacity: 1 },
    initStroke: { color: '000000', opacity: 1, width: 8 },
    text: { stroke_width: 0, font_size: 10, font_family: 'Open Sans' },
    initOpacity: 1,
    imgPath: '/assets/svg',
    dimensions: [this.xWidth, this.yHeight],
    baseUnit: 'px',
    showRulers: true,
    show_outside_canvas:true,

  };


  @HostListener('window:resize', ['$event'])
  onWindowResize() 
  {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }


  /*UPLOAD E DOWNLOAD DE ARQUIVOS*/

  arquivoFormArray : UntypedFormArray = this.formBuilder.array([]);
  cabecalho : any = null;
  imagemArray : string[] = []

  retornaNomeArquivoTratado (value) 
  {
    this.nameFileUpload = value.substring(value.lastIndexOf('\\') + 1);;
  }

  uploadArquivoXlx (files:any, event) 
  {
    this.loading = true;

    const uploaded = files[0];
    if (!uploaded) {
      return;
    }

    this.retornaNomeArquivoTratado(event.target.value)

    const reader = new FileReader();
    reader.onload = () => {
      this.form.get("seaArquivo").setValue(reader.result)
    };

    reader.readAsDataURL(uploaded);
    this.loading = false;

    event.target.value = ''

  }

  enviaArquivosUpload () : void {

    var campanhaencartearquivo = new CampanhaEncarteSVG();
    campanhaencartearquivo.id = this.id;
    campanhaencartearquivo.seaArquivo = this.form.get('seaArquivo').value;

    if (campanhaencartearquivo.id != null) {

      this.loading = true;
      this.SvgService.uploadEncarte(campanhaencartearquivo)
        .subscribe(
          response => {
            this.loading = false;

            if (response.error) {

              this.toastr.error(response.message, 'Erro ao enviar Arquivo!');

            } else {

              this.toastr.success('Arquivo Enviado!', 'Iniciando o upload. Por favor, aguarde.');
              this.listaProdutosEncarte(this.id);
              //this.router.navigateByUrl('campanha-prod/list');
            }

          },
          error => 
          {
            this.loading = false;
            this.toastr.error(error.message, 'Algo saiu errado!');
          });
    }

  }


  formGroupToArray(linha: any) : UntypedFormControl[] {
    var cont : UntypedFormControl[] = [];
    for (var j = 0; j < this.cabecalho.colunas.length; j++) {
      cont.push(linha.get("col_" + j));
    }
    return cont;
  }


  downloadData(): void {
    const id = this.id;
    this.loading = true;
    this.arquivoFormArray = this.formBuilder.array([]);
    this.SvgService.downloadArquivo(id)
      .subscribe(
        arquivo => {
          this.loading = false;
          var linhas = arquivo.linhas.filter(l => l.colunas.filter(c => c.conteudos));
          this.cabecalho = linhas[0];
          this.imagemArray = new Array<string>(linhas.length);

          linhas.shift();
          let i = 0;

          linhas.forEach(linha => {
            var fb = {};
            var j = 0;
            linha.colunas.forEach( (c:any) => {
              fb["col_" + j] = new UntypedFormControl(c.conteudo,null);
              j++;
            });

            i++;
            this.arquivoFormArray.push(
              this.formBuilder.group(
                fb
              )
            )

          });

          var submitFile : string = "";
          this.cabecalho.colunas.forEach(coluna => {
            submitFile += coluna.conteudo + ";"
          });
          submitFile = submitFile.slice(0, -1);
          submitFile += "\r\n";
      
          this.arquivoFormArray.controls.forEach( fb => {
            this.formGroupToArray(fb).forEach( c => {
              if (c != null)
                submitFile +=  c.value + ";"
              else
                submitFile +=  ";"
            })

            submitFile = submitFile.slice(0, -1);
            submitFile += "\r\n";
          });
      


          let blob = new Blob([this.BOM + submitFile], { type: "text/csv;charset=utf-8" });
          saveAs(
            blob,
            `${this.cardName.get('stpNome').value}.csv`
          );

        }, error => {
          this.loading = false;
          this.toastr.error(error.message, 'Erro ao buscar o Arquivo CSV!');
        });
  }


  labelItem(item:any){
    let resultado = '';
    let i = 0;
    for (const key in item) 
    {
      if (item.hasOwnProperty(key)) 
      {
        const value = item[key];
        resultado += key + ': '+ item[key] + ' \n';
      }
    }

    return resultado;
    
  }

  listaProdutosEncarte(id:string):void
  {
    this.SvgService.listagemProdutosEncarte(id).subscribe((res)=>
    {
      this.atributos = res.linhas[0];
      res.linhas.shift();
      this.productList = res.linhas;
    
      let i = 0;
      var productObject = {};
      let product_add = [];
      let product_temp = [];
      
      this.productList.map((p:any) =>
      {    
        this.atributos.colunas.map((a:any) =>
        {    
          productObject[a.conteudo] = p.colunas[i].conteudo;
          product_temp.push(productObject);

          if(product_temp.length === 1)
          {
            product_add.push(productObject);
          }
          
          i++
        })

        i = 0;
        productObject = {}
        product_temp = [];
      });
      
      this.produto_formatado = product_add;
      this.originalProductList = product_add;

    })
  }



  /*ARQUIVOS CSV*/


  findProduct()
  {
    let filteredList = this.originalProductList.filter((f:any) => 
    { 
      for (const [key] of Object.entries(f)) 
      {
        if((f[key].toLowerCase().includes(this.productTextFilter.toLocaleLowerCase())))
        {
          return f
        }
      }
      
    });

    this.produto_formatado = filteredList;
  }


  getTemplateList()
  {
    this.loading = true;
    this.SvgService.listaTemplates().subscribe(
    {
      next:(res:any) => 
      {
        this.listaTemplates = res;
        this.loading = false;
      },
      error:(error) =>
      {
        this.loading = false;
        this.toastr.error('Erro ao obter dados.');
      }
    })
  }


  setSVGSize(svgFile:any)
  {
    this.svgCanvas.setSvgString(svgFile);
    const parse = new DOMParser();
    const xmlDoc = parse.parseFromString(svgFile, "image/svg+xml");
    const svg = xmlDoc.getElementsByTagName('svg'); 

    let width = svg[0].width.baseVal.valueInSpecifiedUnits;
    let height =  svg[0].height.baseVal.valueInSpecifiedUnits;
    //this.dpi = Number(svg[0].attributes['dpi'].value);

    this.dpi=150;

    this.original_width = width;
    this.original_height = height;

    //exibir medidas em mm
    this.setPageSize = `${this.conversorMedidasPxAndMm(width, 'mm', this.dpi)}x${this.conversorMedidasPxAndMm(height,'mm', this.dpi)}`;
    //definir o tamanho da página em px
    this.setSizes(Number(width), Number(height), false);
  }


  getTemplate(id:any):void
  {
    this.loading = true;
    this.SvgService.recuperaTemplate(id).subscribe(
    {
      next:(res) =>
      {
        if(!res.error)
        {
          this.chooseCard = res.stpConteudo;

          if(this.origem == 'card' && this.chooseCard)
          {
            this.setSVGSize(this.chooseCard);
          }
        
          if(this.id)
          {
            if(this.tipo !== 'svg/encarte/:id')
              this.cardName.get('stpNome').setValue(res.stpNome);
          }
          this.loading = false;
        }
        else
        {
          this.toastr.error('Erro ao obter dados do card.');
          this.loading = false;
        }
      },
      error:(error) =>
      {
        this.toastr.error('Erro ao obter dados do card.');
        this.loading = false;
      }
    });
  }



  getEncarte(id_encarte:string):void
  {
    this.loading = true;
    this.SvgService.recuperaEncarte(id_encarte).subscribe(
    {
      next:(res) =>
      {
        if(!res.error)
        {
          this.chooseEncarte = res.senConteudo;
          this.arquivo_id = res.sarId;

          if(this.id && this.chooseEncarte)
          {
            this.cardName.get('stpNome').setValue(res.senDescricao);
            this.listOfPages = JSON.parse(this.chooseEncarte);
            this.pageQuantity = this.listOfPages.length;
            this.setSVGSize(this.listOfPages[0]);
            this.updateCanvasSize();
            //this.svgCanvas.setSvgString(this.chooseEncarte);
          }
        }
        else
        {
          this.toastr.error('Erro ao abrir encarte. Tente novamente mais tarde.');
        }

        this.loading = false;
      },
      error:(error) =>
      {
        this.toastr.error('Erro ao abrir encarte. Tente novamente mais tarde.');
        this.loading = false;
      }
    })
  }


  getArquivos()
  {
    this.loading = true;
    this.SvgService.listaArquivos().subscribe(
    {
      next:(res:any) =>
      {
        this.listaArquivos = res;
        this.loading = false;
      },
      error:(error) =>
      {
        this.toastr.error('Erro ao obter dados.');
        this.loading = false;
      }
    });
  }


  convertBase64WebpToPng(base64WepP, callback)
  {
    const img = new Image();

    img.onload = () =>
    {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const context = canvas.getContext('2d');
      context.drawImage(img, 0,0);
      const dataUrl = canvas.toDataURL('image/png');
      const base64PNG = dataUrl.split(',')[1];
      callback(base64PNG);
    };

    img.src = 'data:image/webp;base64' + base64WepP;
  }


  dataURItoBlob(dataURI) 
  {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  
  buscaArvore(treeData, chave, valor) 
  {
    if(treeData.children && treeData.children.length > 0)
    {
       for (var x = 0; x < treeData.children.length; x++) 
       {
        for (var y = 0; y < treeData.children[x].attributes.length; y++) 
        {
          if (treeData.children[x].attributes[y].name == chave && treeData.children[x].attributes[y].value == valor){
            this.tagPosicao = treeData.children[x];
          }
        }
        this.buscaArvore(treeData.children[x], chave, valor);
       }
    }
  }

  getImageAsBase64(imagem:any, produto:any)
  {
    let card:any = this.chooseCard;
    this.updateCanvasSize();

    //Para pegar as tags text dentro da string svg uma vez que são geradas dinamicamente, 
    //ou seja, não há tags padrões dentro do sistema. O usuário define na criação do card
    const parse = new DOMParser();
    const xmlDoc = parse.parseFromString(card, "image/svg+xml");
    const textTags = xmlDoc.getElementsByTagName("text");
    const rectElement = xmlDoc.getElementsByTagName("rect");
    const svg = xmlDoc.getElementsByTagName('svg');
    const imageElement = xmlDoc.getElementsByTagName('image');
    const use = xmlDoc.getElementsByName('use');


    if(textTags.length === 0)
    {
      this.toastr.error('O card escolhido não possui tags de texto disponíveis. Por favor, corrigir o card.', 'Card');
      return;
    }

    let width = svg[0].width.baseVal.valueInSpecifiedUnits;
    let height =  svg[0].height.baseVal.valueInSpecifiedUnits;
    
    if(rectElement.item(0))
    {
      let placeHolderWidth:number = Number(rectElement.item(0).getAttribute('width'));
      let placeholderHeight:number = Number(rectElement.item(0).getAttribute('height'));
      let image_width = rectElement[0].width.baseVal.valueInSpecifiedUnits;
    }
   
    let card_width:number = width;

    /*
      H = Horizontal
      N = Neutro
      V = Vertical
      */

    let placeholderPosition:string = (Number(width) > Number(height)) ? 'H' : (Number(width) === Number(height)) ? 'N':'V';
    const dimensions = this.getImageBase64Dimensions(imagem);

    for (const k in produto) 
    {
      if(k !== 'FOTO')
      {
        let originalValue = `#${k}`;
        let replacedValue = produto[k];
        let achou  = false;

        if(replacedValue.split)
        {
          let words = replacedValue.split(/\s+/).reverse();
          let line = [];
          let lines:Array<string> = [];
          let margens:Array<number> = [];
          let placeHoldText:number = 0;

          //let marginLeft:number | string = 0;
          let textTag;
          
          let centralizar = false;
          let centralizar_bullet_preco = false;

          for(let i=0; i < textTags.length; i++)
          {
            textTag = textTags[i];
            this.tagPosicao = null;
            this.buscaArvore(textTag.parentElement.parentElement.parentElement.parentElement,'xlink:href', '#'+textTag.parentElement.parentElement.id);
            this.buscaArvore(textTag.parentElement.parentElement.parentElement.parentElement,'xlink:href', '$'+textTag.parentElement.parentElement.id);
            
            if(textTag.textContent.trim().substring(1) === originalValue.trim().substring(1))
            {
              achou = true;
              
              if (textTag.textContent.trim().startsWith("@")) 
              {
                centralizar = true;
                centralizar_bullet_preco = false;
                placeHoldText = card_width;
                this.tagPosicao.x.baseVal.valueInSpecifiedUnits = 0;
              }
              else
              {
                centralizar_bullet_preco = false;
                placeHoldText = card_width - this.tagPosicao.x.baseVal.valueInSpecifiedUnits;
              }
              if(textTag.textContent.trim().startsWith("$"))
              {
                centralizar_bullet_preco = true;
                placeHoldText = this.tagPosicao.x.baseVal.valueInSpecifiedUnits;
                this.tagPosicao.x.baseVal.valueInSpecifiedUnits = 0;
              }

              textTag.innerHTML = null;
    
              break;
            }
           
          }

          if (!achou) continue;

          let ultimoTamanho = 0;

          let word;
          while(word = words.pop())
          {
            line.push(word);
            const txt = line.join(" ");
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext('2d');

            if(textTag.attributes && textTag.attributes['font-size'] && textTag.attributes['font-family'])
            {
              ctx.font = textTag.attributes['font-weight'].nodeValue + ' '+ textTag.attributes['font-size'].nodeValue + 'px ' +  textTag.attributes['font-family'].nodeValue;
            }
            else
            {
              ctx.font = 'normal 10px Futura ';
            }
              
            let text = ctx.measureText(txt);
            let textAlterado = line.join(" ");
        
            if(Number(text.width) > placeHoldText || words.length == 0)
            {
              
              if(words.length > 0)
              {
                line.pop();
                textAlterado = line.join(" ");
                line = [word];
                lines.push(textAlterado);
              }
              else
              {
                if(Number(text.width) > placeHoldText && words.length > 0)
                {
                  line.pop();
                  textAlterado = line.join(" ");
                  line = [];
                  words.push(word);
                  lines.push(textAlterado);
                }
                else
                {
                  textAlterado = line.join(" ");
                  lines.push(textAlterado);
                  ultimoTamanho = Number(text.width);
                }
              }

              if(centralizar)
              {
                margens.push(Math.round((placeHoldText-ultimoTamanho) / 2));
              }
              else if(centralizar_bullet_preco)
              {
                let correcao_posicao = lines[0].length > 5 ? 1.15 : 1;
                margens.push(placeHoldText - Math.round(ultimoTamanho*correcao_posicao/2));
              }
              else
              {
                margens.push(0);
              }

              ultimoTamanho = 0;
            }
            else
            {
              ultimoTamanho = Number(text.width);
            }
          }

          
          for (let i = 0; i < lines.length; i++) 
          {
            var tspan = xmlDoc.createElement('tspan');
            var attrX = xmlDoc.createAttribute('x');
            attrX.value = margens[i].toString();
            tspan.attributes.setNamedItem(attrX);

            if (i !=0 )
            {
              var attrDy = xmlDoc.createAttribute('dy');
              attrDy.value = '1.1em';
              tspan.attributes.setNamedItem(attrDy);
            }

            if(centralizar_bullet_preco)
            {

            }

            tspan.innerHTML= lines[i];

            textTag.appendChild(tspan);
          }
        }
      }
    }

    var s = new XMLSerializer();
    card = s.serializeToString(xmlDoc);
    card = card.replaceAll('xmlns=""','');

    //para alterar o placeholder da imagem
    card = card.replace(/<([a-z|A-Z]*)(.*?)\/>/g, "<$1$2></$1>");
    card = card.replace(/<rect (fill.*?) (.* ?)(id=\"image")(.* ?)><\/rect>/g, `<image #base64# $3 $2$4/>`);
    card = card.replace("#base64#", "xlink:href=\""+imagem+"\"");

    this.svgCanvas.importSvgString(card, true);
    this.updateCanvasSize();

  }


  getPageElements(){
    return this.svgCanvas.getSvgString();
  }

  getImageBase64Dimensions(base64data:string): Promise<{width:number, height:number}>
  {
    return new Promise((resolve, reject) => 
    {
      const image = new Image();
      image.onload = () =>
      {
        const width = image.width;
        const height = image.height;
        resolve({width, height});
      };
      image.onerror = (error) =>
      {
        reject(error);
      };

      image.src = base64data;
    })
  }


  addProduct(produto:any):void
  {
   
    if(!this.chooseCard || this.chooseCard === '')
    {
      this.toastr.error('Por favor, escolha um template antes de adicionar um card.', 'Escolher modelo');
      return;
    }


    this.loading = true;
    this.imagemService.read(produto.FOTO, 2).subscribe(
    {
      next:(res) =>
      {
        this.getImageAsBase64(res.imgConteudo, produto);
        this.loading = false;
      },
      error: (err) =>
      {
        this.toastr.error('Erro ao carregar card, tente novamente mais tarde.', 'Card');
        this.loading = false;
      }
    });

  }


  addPage()
  {
    let page:any = this.listOfPages[this.paginaAtual];
    this.listOfPages.push('');
    this.pageQuantity = this.listOfPages.length;
    this.pagina_exclusao = null;
    this.updateCanvasSize();
  }

  removePage():void
  {
    if(!this.pagina_exclusao)
    {
      this.toastr.error('Por favor, informe a página a ser excluída.');
      return;
    }

    if(this.pagina_exclusao < 1 || this.pagina_exclusao > this.listOfPages.length)
    {
      this.toastr.error('A página informada para a exclusão não existe.');
      return;
    }

    let index = this.pagina_exclusao -1;

    this.listOfPages.splice(index, 1);
    this.pageQuantity = this.listOfPages.length;
    this.pagina_exclusao = null;
    this.updateCanvasSize();
    
  }


  goToPage(id:number)
  {
    //para pegar a página atual e atualizar o valor da array de páginas
    this.updateCanvasSize();
    this.paginaAtual = id - 1;
    let page:any = this.listOfPages[this.paginaAtual];

    //carregar documento
    this.clearCanva();
    this.svgCanvas.setSvgString(page);
    //para atualizar a área de edição com os objetos do setSvgString
    this.updateCanvasSize();
  }

  
  private updateCanvasSize()
  {
    if(this.svgCanvas)
    {
      this.svgCanvas.updateCanvas(this.xWidth*this.fatorZoom, this.yHeight*this.fatorZoom);
      this.listOfPages[this.paginaAtual] = this.svgCanvas.getSvgString();
      
    }   
  }


  zoom(zoom:number)
  {
    this.fatorZoom = zoom/100;
    this.svgCanvas.setZoom(this.fatorZoom);
    this.updateCanvasSize();
  }


  getJsonFromSvgElements = (data) => 
  {
    // Text node
    if (data.nodeType === 3) return data.nodeValue
  
    const retval = {
      element: data.tagName,
      // namespace: nsMap[data.namespaceURI],
      attr: {},
      children: []
    }
      
    // Iterate attributes
    for (let i = 0, attr; (attr = data.attributes[i]); i++) 
    {
      retval.attr[attr.name] = attr.value
    }
      
    // Iterate children
    for (let i = 0, node; (node = data.childNodes[i]); i++) 
    {
      retval.children[i] = this.getJsonFromSvgElements(node)
    }

    return retval
  }



  createSvgElement(type:string):void
  {
    this.svgCanvas.setMode(type);
    this.updateCanvasSize();
  }


  createPage():void 
  {
    //this.svgCanvas.setSvgString(this.svgCanvas.createSVGElement(this.getJsonFromSvgElements(this.teste)).toString());
    //this.svgCanvas.setSvgString(this.svgCanvas.createSVGElement(this.teste).toString());
    this.svgCanvas.importSvgString(this.data)
    this.updateCanvasSize();
  }


  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => 
  {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) 
    {
      const slice = byteCharacters.slice(offset, offset + sliceSize)
      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) 
      {
        byteNumbers[i] = slice.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob
  }


  async openFile()
  {

    try 
    {
      const blob = await fileOpen({
        mimeTypes: ['image/*']
      });

      const svgContent = await blob.text();

      await this.svgCanvas.setSvgString(svgContent);
      this.updateCanvasSize();
      this.handle = blob.handle;

      this.svgCanvas.runExtensions('onOpenedDocument', {
        name: blob.name,
        lastModified: blob.lastModified,
        size: (blob.size) ? blob.size : 100,
        type: (blob.type) ? blob.type : 'svg'
      });

    } 
    catch (err) 
    {
      if (err.name !== 'AbortError') 
      {
        this.toastr.error('Erro ao abrir arquivo.');
      }
    }
  }


  async clickExport (e:any): Promise<void> 
  {
    //if (e?.detail?.trigger !== 'ok' || e?.detail?.imgType === undefined) { return }

    const imgType = (e?.detail?.imgType)  ? e?.detail?.imgType : 'PDF';
    const quality = e?.detail?.quality ? e?.detail?.quality / 100 : 1;

    // Open placeholder window (prevents popup)
    let exportWindowName: string = 'export';

    /**
     *
     * @returns {void}
     */
    const openExportWindow = (): void => {
      const loadingImage = 'carregando';

      if (this.svgCanvas.config.exportWindowType === 'new') 
      {
        this.svgCanvas.exportWindowCt++
      }

      this.svgCanvas.exportWindowName =
      this.svgCanvas.configObj.curConfig.canvasName + this.svgCanvas.exportWindowCt;
      let popHTML; let popURL;

      if (this.svgCanvas.loadingURL) 
      {
        popURL = this.svgCanvas.loadingURL;
      } 
      else 
      {
        popHTML = `<!DOCTYPE html><html>
          <head>
            <meta charset="utf-8">
            <title>${loadingImage}</title>
          </head>
          <body><h1>${loadingImage}</h1></body>
        <html>`;

        if (URL?.createObjectURL) 
        {
          const blob = new Blob([popHTML], { type: 'text/html' })
          popURL = URL.createObjectURL(blob)
        } 
        else 
        {
          popURL = 'data:text/html;base64;charset=utf-8,' + popHTML;
        }

        this.svgCanvas.loadingURL = popURL;
      }

      this.svgCanvas.exportWindow = window.open(
        popURL,
        this.svgCanvas.exportWindowName
      );
    }

    const chrome = isChrome();

    if (imgType === 'PDF') 
    {
      if (!this.customExportPDF && !chrome) 
      {
        openExportWindow();
      }

      this.svgCanvas.exportPDF(exportWindowName);

    } 
    else 
    {
      if (!this.svgCanvas.customExportImage) 
      {
        openExportWindow();
      }
      /* const results = */ await this.svgCanvas.rasterExport(
        imgType,
        quality,
        this.svgCanvas.exportWindowName
      );
    }

  }


  importImage = async (e:any): Promise<void> => 
  {

    e.stopPropagation();
    e.preventDefault();
    const blob = await fileOpen({
      mimeTypes: ['image/*'],
    });

   // const file = (e.type === 'drop') ? e.dataTransfer.files[0] : e.currentTarget.files[0]

    if (!blob.type.includes('image')) {
      return
    }
    // Detected an image
    // svg handling
    let reader
    if (blob.type.includes('svg')) 
    {
      reader = new FileReader();
      reader.onloadend = (ev) => 
      {
        // imgImport.shiftKey (shift key pressed or not) will determine if import should preserve dimension)
        const newElement = this.svgCanvas.importSvgString(ev.target.result, true);
        this.svgCanvas.alignSelectedElements('m', 'page');
        this.svgCanvas.alignSelectedElements('c', 'page');

        // highlight imported element, otherwise ;we get strange empty selectbox
        this.svgCanvas.selectOnly([newElement])
      }

      reader.readAsText(blob);
    } 
    else 
    {
      // bitmap handling
      reader = new FileReader();
      reader.onloadend = ({ target: { result } }) => 
      {
        /**
          * Insert the new image until we know its dimensions.
          * @param {Float} imageWidth
          * @param {Float} imageHeight
          * @returns {void}
          */
        const insertNewImage = (imageWidth, imageHeight) => 
        {
          let proporcaoX = 0;
          let proporcaoY = 0;
          proporcaoX =  (imageWidth > this.getScreenWidth) ? this.getScreenWidth/imageWidth  : imageWidth / this.getScreenWidth;
          proporcaoY =  (imageHeight > this.getScreenHeight) ? this.getScreenHeight / imageHeight :  imageHeight / this.getScreenHeight;
 
          const newImage = this.svgCanvas.addSVGElementsFromJson({
            element: 'image',
            attr: 
            {
              x: 0,
              y: 0,
              width: Math.min(imageWidth * proporcaoX),
              height: Math.min(imageHeight * proporcaoY),
              id: this.svgCanvas.getNextId(),
              style: 'pointer-events:inherit'
            }
          });

          this.svgCanvas.setHref(newImage, result);
          this.svgCanvas.selectOnly([newImage]);
          this.svgCanvas.alignSelectedElements('m', 'page');
          this.svgCanvas.alignSelectedElements('c', 'page');

        }

        // create dummy img so we know the default dimensions
        let imgWidth = 100
        let imgHeight = 100
        const img = new Image()
        img.addEventListener('load', () => 
        {
          imgWidth = img.offsetWidth || img.naturalWidth || img.width
          imgHeight = img.offsetHeight || img.naturalHeight || img.height
          insertNewImage(imgWidth, imgHeight)
        })
        img.src = result;
      }
      reader.readAsDataURL(blob);
    }
  }


  clickUndo(): void 
  {
    const { undoMgr, textActions } = this.svgCanvas
    if (undoMgr.getUndoStackSize() > 0) 
    {
      undoMgr.undo();
      if(this.svgCanvas.getMode() === 'textedit') 
      {
        textActions.clear();
      }

      this.updateCanvasSize();
    }
  }


  clickRedo(): void
  {
    const { undoMgr } = this.svgCanvas
    if (undoMgr.getRedoStackSize() > 0) 
    {
      undoMgr.redo();
      this.svgCanvas.layersPanel.populateLayers();

      this.updateCanvasSize();
    }
  }


  createCustomButtom(name:string, buttonColor:string = 'red', textColor:string = 'blue', shape:string = 'rect', width:number = 100, height:number = 100, fontSize:number = 24):void
  {
    //let svgA = {element: 'rect',attr: { x: '0', y: '1', width: '200', height: '100', text:'alguma coisa aqui', fillColor:'red' }}
    
    let item = `item${this.count = this.count + 1}`
    
    let svg=`<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
              <g>
                <rect x="0" y="0" rx="10" ry="10" width="${width}" height="${height}" fill="${buttonColor}"></rect>
                <text 
                  font-family="Verdana" 
                  font-size="${fontSize}" 
                  fill="${textColor}" 
                  x="50%" 
                  y="55%" 
                  alignment-baseline="middle" 
                  text-anchor="middle">
                  ${name}
                </text>
              </g>
            </svg>`;

  
    this.svgCanvas.importSvgString(svg);
    this.updateCanvasSize();
  }


  showModal(): void 
  {
    this.isVisible = true;
    this.marker.get('selectedMarker')?.setValue(null);
    this.marker.get('customMarker').setValue(null);
  }


  handleCancelModal(): void{
    this.isVisiblePageSize = false;
  }


  handleCancel(): void {
    this.isVisible = false;
    this.cardNameVisible = false;
  }

  cancelarCriacaoCard(): void{
    this.router.navigateByUrl(`/svg/cards`);
  }

  hexToRgb(hex:string)
  {
    hex = hex.replace(/^#/,'');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return {r,g,b};
  }

  rgbToCymk(r,g,b)
  {
    const normalizeR = r / 255;
    const normalizeG = g / 255;
    const normalizeB = b / 255;

    const c = 1 - normalizeR;
    const m = 1 - normalizeG;
    const y = 1 - normalizeB;

    const k = Math.min(c, m, y);

    const adjustedC = (((c-k) / (1-k)))*100;
    const adjustedM = (((m-k) / (1-k)))*100;
    const adjustedY = (((y-k) / (1-k)))*100;

    return {
      c: isNaN(adjustedC) ? 0 : Math.round(adjustedC), 
      m: isNaN(adjustedM) ? 0 : Math.round(adjustedM), 
      y: isNaN(adjustedY) ? 0 : Math.round(adjustedY), 
      k: Math.round(k*100)
    };
   }

   hexToCymk(hex:string)
   {
    const {r, g, b} = this.hexToRgb(hex);
    const {c, m, y, k} = this.rgbToCymk(r, g, b);

    return {c, m, y, k};
   }

   cmykToRgb(cmyk:any)
   {
    const {c,m,y,k} = cmyk;
    const rgbColor = 
    {
      r: Math.round((1-(c/100)) * (1-(k/100)) * 255),
      g: Math.round((1-(m/100)) * (1-(k/100)) * 255),
      b: Math.round((1-(y/100)) * (1-(k/100)) * 255)
    }

    return `rgb(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b})`;
   }


  defineMarker(origim:string = 'combo', fontSize:number = 20):void
  {
    
    let choose = this.marker.get('selectedMarker').value;
    let custom = this.marker.get('customMarker').value;
    let textArea = this.marker.get('textAreaMarker').value; 

   
    let svg='.';
    let item = this.count = this.count + 1;

    //let cymk_color = (textColor) ? this.hexToCymk(textColor) : this.hexToCymk('#000');

    if(choose && origim === 'combo')
    {
      let area_imagem_disponivel = document.getElementById('image');
      
      if(area_imagem_disponivel)
      {
        this.toastr.error('Não é possível criar mais de uma área para imagem.', 'Área imagem');
        return;
      }
        
      this.imageId = 'image'
      this.marker.get('selectedMarker').setValue(null);
      this.createSvgElement('square');

    }

    if(custom && origim === 'combo')
    {
      let id = this.fontFamily + this.count;
      console.log(1)

      if(custom !== '' && origim === 'combo')
      {
        let tags = custom.trim().split(',');

        if(tags.length > 0)
        {
          for (let t = 0; t < tags.length; t++) {

            let align_text = 'left';
            let x = 10;
            let y = 150;

            svg=`<svg xmlns="http://www.w3.org/2000/svg" id="${id}">
              <g>
                <!--tag ${tags[t]}-->
                <text 
                  x="${x}" 
                  y="${y}" 
                  font-family="Verdana"
                  font-weight="normal"
                  font-size="12"
                  xml:space="preserve" 
                  text-anchor="${align_text}">
                  ${tags[t]}
                </text>
              </g>
            </svg>`;

            this.svgCanvas.importSvgString(svg, true);
          }
        }
      }
      
    }

    if(textArea && origim === 'combo' && textArea !== '')
    {
      /*svg=`<svg xmlns="http://www.w3.org/2000/svg" id="${this.count+'-text-area'}">
              <g>
                <!--tag ${textArea}-->
                <rect 
                  id_area_texto="${textArea}" 
                  height="70%" 
                  width="70%" 
                  stroke-width="1" 
                  stroke-dasharray="5" 
                  fill="none" 
                  stroke="#00838f" 
                  rx="${textArea}"
                />
              </g>
            </svg>`;*/


        svg=`<svg xmlns="http://www.w3.org/2000/svg" id="${this.count+'-text-area'}">
            <g>
              <!--tag ${textArea}-->
              <path d="m243.82,135l-0.82,140l218,0l0,-140l-217.18,0z" stroke-dasharray="5" fill="none" id="svg_11" stroke="#00838f" name="${textArea}"/>
            </g>
          </svg>`;



      this.svgCanvas.importSvgString(svg);
      this.marker.get('textAreaMarker').setValue(null);
    }
    
    //this.svgCanvas.importSvgString(svg);
    this.updateCanvasSize();
    
    this.isVisible = false;
    
  }


  addCard():void{
    let item = this.count = this.count + 1;
    
    let card:any = this.chooseCard;
    card = card.replace('<defs>', '<defs><!-- item:'+item+' -->');

    this.svgCanvas.importSvgString(card);
    this.updateCanvasSize();
  }

  calcDpi()
  {
    let dpi;
    if (window.matchMedia("(min-resolution: 10dpi)").matches) {
      for(var i=650; i>=1; i = (i-1)) {
        if (window.matchMedia("(min-resolution: " + i + "dpi)").matches) {
          dpi = i;
          break;
        }
      }
    }
    dpi = 150;
    return dpi;
  }


  conversorMedidasPxAndMm(value:number, type:string = 'px', dpi:number | null = null)
  {
    let dpi_elemento =  (dpi && dpi !== this.calcDpi()) ? dpi : this.calcDpi();
    //let dpi_elemento =  (dpi && dpi !== this.default_dpi) ? dpi : this.default_dpi;
    let converter = (type === 'px') ? Math.round(value*(dpi_elemento/this.millimeters_in_an_inch)) : Math.round(value/(dpi_elemento/this.millimeters_in_an_inch));
    return converter;
  }


  setSizes(width:number | null = null, height:number | null = null, convertSize:boolean = false):void
  {

    if(convertSize)
    {
      let w = this.conversorMedidasPxAndMm(width);
      let h = this.conversorMedidasPxAndMm(height);
      this.getScreenWidth = (w) ? w : h;
      this.getScreenHeight = (h) ? h : w;
    }
    else
    {
      let tamanho_em_mm = this.conversorMedidasPxAndMm(width, 'mm', this.dpi);
      //fórmula para verificar em quantos dpis foi gerado o valor em pixels: largura ou altura em px * 25.4(milímetros em uma polegada) / largura ou altura em mm
      let dpi = Math.round((width * this.millimeters_in_an_inch)/tamanho_em_mm);

      if(dpi === this.calcDpi())
      {
        this.getScreenWidth = (width) ? width : height;
        this.getScreenHeight = (height) ? height : width;
      }
      else
      {
        //ajustar pixels gerados à partir de outra resolução (dpi) para a dpi do monitor em uso
        this.getScreenWidth = (width) ? Math.round((width * this.calcDpi()) / dpi) : Math.round((height * this.calcDpi()) / dpi);
        this.getScreenHeight = (height) ? Math.round((height * this.calcDpi()) / dpi) : Math.round((width * this.calcDpi()) / dpi);
      }
    }
    
    this.xWidth = this.getScreenWidth;
    this.yHeight = this.getScreenHeight;

    this.updateCanvasSize();
  }


  definePegeSize(convertSize:boolean = true) : void
  {
    let choose = this.setPageSize.toLowerCase();
    
    if(choose)
    {
      let sizes = choose.toLocaleLowerCase().split('x');
      this.setSizes(Number(sizes[0]), Number(sizes[1]), convertSize);
      this.isVisiblePageSize = false;
      this.updateCanvasSize();

      if(!this.id && this.tipo === 'svg/card')
        this.saveCard();

    }
    else
    {
      this.toastr.error('Por favor, escolha um tamanho de área.', 'Escolher tamanho');
    }
    
  }


  index = 0;
  addSize(input: HTMLInputElement): void 
  {
    const value = input.value;
    if (this.listOfSizes.indexOf(value) === -1) {
      this.listOfSizes = [...this.listOfSizes, input.value || `Novo tamanho ${this.index++}`];
    }
  }


  fillColor (type:string):void 
  {
    this.svgCanvas.getSelectedElements().forEach((el) => {
      el.setAttribute(type, (type === 'fill') ? this.color : this.colorStroke);
    });

  }


  strokeWidth(width:number) : void
  {
    this.svgCanvas.getSelectedElements().forEach((el) => 
    {
      el.setAttribute('stroke-width', width);
    });

    this.svgCanvas.getSelectedElements().forEach((el) => 
    {
      el.setAttribute('stroke-linejoin', "round");
    });

  }


  setStrokeWidth(event:any): void
  {
    this.strokeWidth(Number(event));
  }


  clone() 
  {
    this.svgCanvas.cloneSelectedElements(20, 20);
  }


  clearCanva():void
  {
    this.svgCanvas.clear(); 
    this.updateCanvasSize();
    this.count=0;
  }
  

  moveToTopSelected () 
  {
    this.svgCanvas.moveToTopSelectedElement();
  }


  moveToBottomSelected () 
  {
    this.svgCanvas.moveToBottomSelectedElement()
  }


  deleteElement():void
  {
    this.svgCanvas.deleteSelectedElements();
  }


  setTextFont(font:string):void
  {
    this.fontFamily = font;
    let selected = this.svgCanvas.getSelectedElements();
    const symbol = document.querySelectorAll('symbol');

    if(selected.length > 0)
    {
      for(let i = 0; i < selected.length; i++)
      {
        if(selected[i].nodeName === 'use')
        {
          let symbol_id = selected[i].href.baseVal.split('#')
          
          symbol.forEach((item:any) =>
          {
            if(item.id === symbol_id[1])
            {
              let text_element  = document.getElementById(item.id);
              let text_font = text_element.querySelector('text');
              text_font.setAttribute('font-family', font);
            }
          });
        }
          
      }

      this.updateCanvasSize();
      return;
    }
    this.toastr.error('Por favor, escolha um texto para alterar o tipo de fonte.', 'Texto');
  }


  setTextAlign(alignType:string, align:string):void
  {
    let selected = this.svgCanvas.getSelectedElements();
    const symbol = document.querySelectorAll('symbol');
    
    if(selected.length > 0)
    {
      for(let i = 0; i < selected.length; i++)
      {
        if(selected[i].nodeName === 'use')
        {
          let symbol_id = selected[i].href.baseVal.split('#')
          
          symbol.forEach((item:any) =>
          {
            if(item.id === symbol_id[1])
            {
              let text_element  = document.getElementById(item.id);
              let text_align = text_element.querySelector('text');
              text_align.setAttribute(alignType, align);
              text_align.setAttribute('text-anchor', align);

              if(align === 'middle')
                text_align.setAttribute('x','50%');
            }
          });
        } 
      }

      this.updateCanvasSize();
      return;
    }

    this.toastr.error('Por favor, escolha um texto para alterar o alinhamento.', 'Texto');
  }


  setTextWeight(weight:string | number):void
  {
    let selected = this.svgCanvas.getSelectedElements();
    const symbol = document.querySelectorAll('symbol');

    if(selected.length > 0)
    {
      for(let i = 0; i < selected.length; i++)
      {
        if(selected[i].nodeName === 'use')
        {
          this.textWeight = weight;
          let symbol_id = selected[i].href.baseVal.split('#');
          
          symbol.forEach((item:any) =>
          {
            if(item.id === symbol_id[1])
            {
              let text_element  = document.getElementById(item.id);
              let text_align = text_element.querySelector('text');
              text_align.setAttribute('font-weight', weight.toString());
            }
          });
        }
          
      }

      this.updateCanvasSize();
      return;
    }

    this.textWeight = 'Normal';
    this.toastr.error('Por favor, escolha um texto para alterar.', 'Texto');
  }


  changeFontSize (value:number):void 
  {
    let selected = this.svgCanvas.getSelectedElements();
    const symbol = document.querySelectorAll('symbol');

   

    if(selected.length > 0)
    {
      for(let i = 0; i < selected.length; i++)
      {
        if(selected[i].nodeName === 'use')
        {
          let symbol_id = selected[i].href.baseVal.split('#');
          
          symbol.forEach((item:any) =>
          {
            if(item.id === symbol_id[1])
            {
              let text_element  = document.getElementById(item.id);
              let text_align = text_element.querySelector('text');
              text_align.setAttribute('font-size', value.toString());
            }
          });
        } 
      }

      this.updateCanvasSize();
      return;
    }


    this.toastr.error('Por favor, escolha um texto para alterar o tipo de fonte.', 'Texto');
  }

  openModalSaveCard()
  {
    this.cardNameVisible = true;
  }


  getSvgFileSize(svg:string, width:number | null = null, height:number | null = null)
  {
    const modifiedSvgString = svg.replace(
      /<svg[^>]*width\s*=\s*['"]?(\d+)['"]?[^>]*>/,
      `<svg width="${(width) ? width : this.getScreenWidth}" height="${(height) ? height : this.getScreenHeight}" dpi="${this.dpi}"
      xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">`
    );

    return modifiedSvgString;
  }


  saveCard():void
  {

    this.definePegeSize();

    const modifiedSvgString = this.svgCanvas.getSvgString().replace(
      /<svg[^>]*width\s*=\s*['"]?(\d+)['"]?[^>]*>/,
      `<svg width="${this.getScreenWidth}" height="${this.getScreenHeight}" dpi="${this.calcDpi()}" 
      xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">`
    );

    let cardName = (this.cardName.get('stpNome').value) ? this.cardName.get('stpNome').value : this.pageSize.get('stpNome').value;

    let data = 
    {
      id: this.id,
      stpNome: cardName,
      stpConteudo: modifiedSvgString
    }

    let service  = (!this.id) ? this.SvgService.criaTemplate(data) : this.SvgService.atualizaTemplate(data);
    this.loading = true;

    if(!this.id)
      delete data.id;

    service.subscribe(
    {
      next:(res) =>
      {
        let msg = (this.id) ? 'Card atualizado com sucesso' : 'Card criado com sucesso';
        this.toastr.success(msg);

        if(!this.id)
        {
          this.id = res.id;
          this.cardName.get('stpNome').setValue(res.stpNome);
          this.router.navigateByUrl(`/svg/cards`);
        }

        this.cardNameVisible = false;
        this.loading = false;
      },
      error:(error) =>
      {
        let msg = (this.id) ? 'Erro ao atualizar card' : 'Erro ao criar card.';
        this.toastr.error(msg);
        this.loading = false;
      }
    })
  }

  exportarEncarte():void
  {
    this.definePegeSize();
    this.updateCanvasSize();
    this.listOfPages = this.listOfPages.map((item:any, i:number) =>
    {
      
      return item.replace(
        /<svg[^>]*width\s*=\s*['"]?(\d+)['"]?[^>]*>/,
        `<svg width="${this.xWidth}" height="${this.yHeight}" dpi="${this.calcDpi()}"
        xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">`
      );

    });

    let paginas = JSON.stringify(this.listOfPages);

    let data:SvgEncarte = 
    {
      id: this.id,
      senDescricao: this.cardName.get('stpNome').value,
      sarId:null, //implementar essa parte . 
      senConteudo:paginas
    }

    if(!this.id)
      delete data.id;

    this.loading = true;

    this.SvgService.exportaEncarte(data).subscribe(
      response => {
        saveAs(response, this.cardName.get('stpNome').value + '.zip');
        let msg = 'Encarte exportado com sucesso';
        this.toastr.success(msg);
        this.cardNameVisible = false;

        this.loading = false;
      },
      error => {
        this.loading = false;
        console.log(error);
        this.toastr.error(error.message, 'Erro ao processar realizar o download.');
      });
}

  

  saveEncarte():void
  {
    this.definePegeSize();
    this.updateCanvasSize();
    this.listOfPages = this.listOfPages.map((item:any, i:number) =>
    {
      
      return item.replace(
        /<svg[^>]*width\s*=\s*['"]?(\d+)['"]?[^>]*>/,
        `<svg width="${this.xWidth}" height="${this.yHeight}" dpi="${this.calcDpi()}"
        xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">`
      );

    });

    let paginas = JSON.stringify(this.listOfPages);

    let data:SvgEncarte = 
    {
      id: this.id,
      senDescricao: this.cardName.get('stpNome').value,
      sarId:null, //implementar essa parte . 
      senConteudo:paginas
    }

    let service = (!this.id) ? this.SvgService.criaEncarte(data) : this.SvgService.atualizaEncarte(data);

    if(!this.id)
      delete data.id;

    this.loading = true;

    service.subscribe(
    {
      next: (res) =>
      {
        let msg = (this.id) ? 'Encarte atualizado com sucesso' : 'Encarte criado com sucesso';
        
        if(!this.id)
        {
          this.id = res.id;
          this.cardName.get('stpNome').setValue(res.senDescricao);
        }
        
        this.toastr.success(msg);
        this.cardNameVisible = false;
        this.loading = false;
      },
      error: (error) =>
      {
        let msg = (this.id) ? 'Erro ao atualizar encarte' : 'Erro ao criar encarte.';
        this.toastr.error(msg);
        this.loading = false;
      }
    })
  }


  export = async (type:any) =>
  {
    const saveOpts = 
    {
      images:'img_save',
      round_digits: 2
    }

    // remove the selected outline before serializing
    this.svgCanvas.clearSelection();

    // Update save options if provided
    if (saveOpts) 
    {
      const saveOptions = this.svgCanvas.mergeDeep(this.svgCanvas.getSvgOption(), saveOpts);
      
      for (const [key, value] of Object.entries(saveOptions)) 
      {
        this.svgCanvas.setSvgOption(key, value);
      }
    }

    this.svgCanvas.setSvgOption('apply', true);

    // no need for doctype, see https://jwatt.org/svg/authoring/#doctype-declaration
    const svg = '<?xml version="1.0"?>\n' + this.svgCanvas.svgCanvasToString();
    const b64Data = this.svgCanvas.encode64(svg);
    const blob = this.b64toBlob(b64Data, 'image/svg+xml');


    try 
    {
      if (type === 'save' && this.handle !== null) 
      {
        const throwIfExistingHandleNotGood = false;
        this.handle = await fileSave(blob, 
        {
          fileName: 'untitled.svg',
          extensions: ['.svg', '.pdf']
        }, this.handle, throwIfExistingHandleNotGood);
      } 
      else 
      {
        this.handle = await fileSave(blob, 
        {
          fileName: 'untitled',
          extensions: ['.svg', '.pdf']
        });
      }
      
      this.svgCanvas.runExtensions('onSavedDocument', 
      {
        name: this.handle.name,
        kind: this.handle.kind
      });
    } 
    catch (err) 
    {
      if (err.name !== 'AbortError') 
      {
        this.toastr.error('Erro ao salvar arquivo');
      }
    }
    
  }


  clickGroup(): void 
  {
    this.svgCanvas.groupSelectedElements();
  }


  clickUnGroup (): void 
  {
    this.svgCanvas.ungroupSelectedElement();
  }


  clickAlignEle (evt:any) 
  {
    this.svgCanvas.alignSelectedElements(evt.detail.value, 'page');
  }

  
  clickAlign (pos:any) 
  {
    this.svgCanvas.alignSelectedElements(pos, this.alignReference);
  }


  getElementDomProperties()
  {
    this.svgCanvas.getSelectedElements().forEach((el) => 
    {
      console.log(el)
    });
  }

  resetTextProperties():void
  {
    this.textWeight = null;
    this.fontSize = 8 ;
    this.fontFamily = 'Normal';
  }

  resetWidthHeightProperties():void
  {
    this.element_width = null;
    this.element_height = null;
  }

  resetPositionProperties():void
  {
    this.element_x = null;
    this.element_y = null;
  }

  getElementProperties(window:any, element:any):void
  {
    if(element && element.length === 1)
    {
      for (let i = 0; i < element.length; i++) 
      {
        let object_color = element[i].getAttribute('fill');
        let object_stroke = element[i].getAttribute('stroke');
        let object_stroke_width = element[i].getAttribute('stroke-width');

        switch (element[i].tagName) 
        {
          case 'rect':
              this.element_width = this.conversorMedidasPxAndMm(Number(element[i].getAttribute('width')), 'mm', this.calcDpi());
              this.element_height = this.conversorMedidasPxAndMm(Number(element[i].getAttribute('height')), 'mm', this.calcDpi());
              this.element_x = Number(element[i].getAttribute('x')).toFixed(2);
              this.element_y = Number(element[i].getAttribute('y')).toFixed(2);
              this.color = element[i].getAttribute('fill');
              this.colorStroke = element[i].getAttribute('stroke');
              this.defaultStroke = element[i].getAttribute('stroke-width') ? element[i].getAttribute('stroke-width') : 0;
              this.resetTextProperties();
              this.show_posicao_xy = false;
              this.show_width_height = true;
        
            break;

          case 'circle' :
              this.element_width = this.conversorMedidasPxAndMm(Number(element[i].getAttribute('r')), 'mm', this.calcDpi());
              this.element_height = this.conversorMedidasPxAndMm(Number(element[i].getAttribute('r')), 'mm', this.calcDpi());
              this.element_x = Number(element[i].getAttribute('cx')).toFixed(2);
              this.element_y = Number(element[i].getAttribute('cy')).toFixed(2);
              this.color = element[i].getAttribute('fill');
              this.colorStroke = element[i].getAttribute('stroke');
              this.defaultStroke = element[i].getAttribute('stroke-width') ? element[i].getAttribute('stroke-width') : 0;
              this.resetTextProperties();
              this.show_posicao_xy = false;
              this.show_width_height = true;

            break;

          case 'ellipse':
            this.element_width = this.conversorMedidasPxAndMm(Number(element[i].getAttribute('rx')), 'mm', this.calcDpi());
            this.element_height = this.conversorMedidasPxAndMm(Number(element[i].getAttribute('ry')), 'mm', this.calcDpi());
            this.element_x = Number(element[i].getAttribute('cx')).toFixed(2);
            this.element_y = Number(element[i].getAttribute('cy')).toFixed(2);
            this.color = element[i].getAttribute('fill');
            this.colorStroke = element[i].getAttribute('stroke');
            this.defaultStroke = element[i].getAttribute('stroke-width') ? element[i].getAttribute('stroke-width') : 0;
            this.resetTextProperties();
            this.show_posicao_xy = false;
            this.show_width_height = true;

            break;

          case 'path':
            this.colorStroke = element[i].getAttribute('stroke');
            this.color = element[i].getAttribute('fill') ? element[i].getAttribute('fill') : 'red';
            this.defaultStroke = element[i].getAttribute('stroke-width') ? element[i].getAttribute('stroke-width') : 0;
            this.resetTextProperties();
            this.show_posicao_xy = false;
            this.show_width_height = true;

            break;

          case 'line':
            this.colorStroke = element[i].getAttribute('stroke');
            this.defaultStroke = element[i].getAttribute('stroke-width') ? element[i].getAttribute('stroke-width') : 0;
            this.resetTextProperties();
            this.show_posicao_xy = false;
            this.show_width_height = true;

            break;

          case 'image':
            this.element_width = this.conversorMedidasPxAndMm(Number(element[i].getAttribute('width')), 'mm', this.calcDpi());
            this.element_height = this.conversorMedidasPxAndMm(Number(element[i].getAttribute('height')), 'mm', this.calcDpi());
            this.element_x = Number(element[i].getAttribute('x')).toFixed(2);
            this.element_y = Number(element[i].getAttribute('y')).toFixed(2);
            this.resetTextProperties();
            this.show_posicao_xy = false;
            this.show_width_height = true;

            break;

          case 'use':
            this.element_x = Number(element[i].getAttribute('x')).toFixed(2);
            this.element_y = Number(element[i].getAttribute('y')).toFixed(2);
            let element_info = document.getElementById(`${element[i].getAttribute('xlink:href').split('#')[1]}`);
            if(element_info.getAttribute('width') && element_info.getAttribute('height'))
            {
              this.element_width = this.conversorMedidasPxAndMm(Number(element_info.getAttribute('width')), 'mm', this.calcDpi());
              this.element_height = this.conversorMedidasPxAndMm(Number(element_info.getAttribute('height')), 'mm', this.calcDpi());
              this.show_posicao_xy = false;
              this.show_width_height = true;
            }
            
            let element_children = element_info.children;

            for (let i = 0; i < element_children.length; i++) 
            {
              const element = element_children[i];
              let child = element.children;
    
              for (let x = 0; x < child.length; x++) 
              {
                if(child[x].tagName === 'text')
                {
                  this.colorStroke = object_stroke ? object_stroke : '';
                  this.color = object_color ? object_color : '#000';
                  this.defaultStroke = object_stroke_width ? Number(object_stroke_width) : 0;
                  this.textWeight = child[x].getAttribute('font-weight');
                  this.fontSize = child[x].getAttribute('font-size').toString();
                  this.fontFamily = child[x].getAttribute('font-family');
                  this.show_posicao_xy = false;
                  this.show_width_height = false;
                }
                child[x].getAttribute('width');
              }
            }

          break;
        }
      }
    }
    else
    {
      this.resetWidthHeightProperties();
      this.resetPositionProperties();
      this.resetTextProperties();
      this.show_posicao_xy = false;
      this.show_width_height = false;
    }

  }


  createRectImage(window:any, element:any):void
  {
    if(this.imageId)
    {
      element[0].setAttribute('id', 'image');
    }
    this.imageId = null;
    this.updateCanvasSize();
  }
  

  ngAfterViewInit(): void 
  {
    this.svgCanvas = new SVGCanvas(this.containerEl.nativeElement, this.config);
    this.svgCanvas.bind('selected',  this.getElementProperties.bind(this));
    this.svgCanvas.bind('changed', this.createRectImage.bind(this));
    
    this.hiddenTextTag = this.svgCanvas.$id(this.hiddenTextTagId);
    this.svgCanvas.textActions.setInputElem(this.hiddenTextTag);

    const addListenerMulti = (element, eventNames, listener) => 
    {
      eventNames.split(' ').forEach((eventName) => element.addEventListener(eventName, listener, false));
    }

    addListenerMulti(this.hiddenTextTag, 'keyup input', (evt) => 
    {
      this.svgCanvas.setTextContent(evt.currentTarget.value);
    });
  
  }


  ngOnInit(): void 
  {
    const imgImport = document.createElement('input');
    imgImport.type = 'file';
    imgImport.addEventListener('change', this.importImage);
    this.tipo = this.route.routeConfig.path;
    this.id = this.route.snapshot.params.id;

    if(this.tipo === 'svg/card/:id')
    {
      this.getTemplate(this.id);
    }

    if(this.tipo === 'svg/card')
    {
      this.isVisiblePageSize = true;
      this.isProductBar = false;
      this.pageQuantity = 0;
      this.origem = 'card';
    }
    else if(this.tipo === 'svg/card/:id' && this.id)
    {
      this.isVisiblePageSize = false;
      this.isProductBar = false;
      this.pageQuantity = 0;
      this.getTemplate(this.id);
      this.origem = 'card';
    }
    else if(this.tipo === 'svg/encarte/:id' && this.id)
    {
      this.isVisiblePageSize = false;
      this.isProductBar = true;
      this.getEncarte(this.id);
      this.origem = 'encarte';
      this.listaProdutosEncarte(this.id);
    }
    else
    {
      this.isVisiblePageSize = false;
      this.isProductBar = true;
      //this.pageQuantity = this.listOfPages.length;
      this.originalProductList = this.productList;
      this.origem = 'encarte';
      this.listOfPages.push('');
      this.pageQuantity = this.listOfPages.length;
    }

    this.showElements = [
      {
        element:'botao',
        status:false,
        id:0
      },
      {
        element:'abrir',
        status:false,
        id:1
      },
      {
        element:'importar',
        status:false,
        id:2
      },
      {
        element:'salvar_servidor',
        status:true,
        id:3
      },
      {
        element:'exportar',
        status:false,
        id:4
      },
      {
        element:'salvar_local',
        status:true,
        id:5
      },
      {
        element:'mover_topo',
        status:true,
        id:6
      },
      {
        element:'mover_baixo',
        status:true,
        id:7
      },
      {
        element:'apagar',
        status:true,
        id:8
      },
      {
        element:'limpar',
        status:true,
        id:9
      },
      {
        element:'agrupar',
        status:true,
        id:10
      },
      {
        element:'desagrupar',
        status:true,
        id:11
      },
      {
        element:'refazer',
        status:true,
        id:12
      },
      {
        element:'desfazer',
        status:true,
        id:13
      },
      {
        element:'alinhamento',
        status:true,
        id:14
      },
      {
        element:'objetos',
        status:true,
        id:15
      },
      {
        element:'fonte',
        status:true,
        id:16
      },
      {
        element:'texto_esquerda',
        status:false,
        id:17
      },
      {
        element:'texto_centro',
        status:false,
        id:18
      },
      {
        element:'texto_direita',
        status:false,
        id:19
      },
      {
        element:'tamanho_fonte',
        status:true,
        id:20
      },
      {
        element:'densidade_fonte',
        status:true,
        id:21
      },
      {
        element:'escolher_marcas',
        status:true,
        id:22
      },
      {
        element:'ferramenta_quadrado',
        status:true,
        id:23
      },
      {
        element:'ferramenta_circulo',
        status:true,
        id:24
      },
      {
        element:'ferramenta_path',
        status:true,
        id:25
      },
      {
        element:'ferramenta_linha',
        status:true,
        id:26
      },
      {
        element:'ferramenta_contorno',
        status:true,
        id:27
      },
      {
        element:'ferramenta_clone',
        status:true,
        id:28
      },
      {
        element:'ferramenta_texto',
        status:false,
        id:29
      },
      {
        element:'ferramenta_imagem',
        status:true,
        id:30
      },
      {
        element:'add_page',
        status:false,
        id:31
      },
      {
        element:'fill_color',
        status:true,
        id:32
      },
      {
        element:'fill_stroke',
        status:true,
        id:33
      },
      {
        element:'set_stroke',
        status:true,
        id:34
      },
      {
        element:'page_size',
        status:true,
        id:35
      },
      {
        element:'novo_arquivo',
        status:false,
        id:36
      },
      {
        element:'zoom',
        status:true,
        id:37
      },
      {
        element:'upload',
        status:true,
        id:38
      },
      {
        element:'download',
        status:true,
        id:39
      },
      {
        element:'salvar_pdf',
        status:true,
        id:40
      },

    ];

    /*document.addEventListener("DOMContentLoaded", () =>
    {
      var svg = document.getElementById('myCanvas');
      
      
      svg.addEventListener("click", (event) =>
      {
        var element = event.target;

        if(element instanceof SVGElement)
        {
          if(element.tagName !== 'svg')
          {
            let tipo_elemento = element.tagName;
            this.getElementProperties(element, tipo_elemento)
          }
          else
          {
            this.getElementProperties(element, null)
          } 
        }

        this.getElementDomProperties();
      })
    })*/
    //this.getElementsProperties();
    this.getArquivos();
    this.getTemplateList();
    this.updateCanvasSize();
    //this.createPage();
    //this.addPage();
  }


  marker = new UntypedFormGroup({
    selectedMarker: new UntypedFormControl(null),
    customMarker: new UntypedFormControl(null),
    textAreaMarker: new UntypedFormControl(null),
  });


  pageSize = new UntypedFormGroup({
    pageSize: new UntypedFormControl(null),
    setPageSize: new UntypedFormControl(null),
    stpNome: new UntypedFormControl(null, [Validators.required]),
  });
  

  cardName = new UntypedFormGroup({
    stpNome: new UntypedFormControl(null, [Validators.required]),
  });


}




