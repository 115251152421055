<div>
    <nz-page-header class="site-page-header" nzTitle="Usuário" nzSubtitle="Dados do usuário">
    </nz-page-header>

    <ng-container class="edit-form" #customLoadingTemplate>
        <form nz-form [formGroup]="form">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Nome</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroNome">
                    <input nz-input formControlName="usuNome" name="usuNome" size="64" />
                    <ng-template #erroNome let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 256 caracteres<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Email</nz-form-label>
                <nz-form-control [nzSpan]="12" [nzErrorTip]="erroEmail">
                    <input nz-input formControlName="usuLogin" name="usuLogin" size="64" />
                    <ng-template #erroEmail let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('maxlength')">
                            Esse campo não permite mais que 128 caracteres<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('duplicated')">
                            Este email já está em uso por outro usuário<br>
                        </ng-container>
                        <ng-container *ngIf="control.hasError('pattern')">
                            Este email não é válido<br>
                        </ng-container>
                    </ng-template>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5 ">Status</nz-form-label>
                <nz-form-control [nzSpan]="12">
                    <nz-radio-group formControlName="usuStatus" name="usuStatus">
                        <label nz-radio nzValue="A">Ativo</label>
                        <label nz-radio nzValue="I">Inativo</label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5 ">Grupo do Usuário</nz-form-label>
                <nz-form-control [nzSpan]="12">
                  <nz-select
                      style="width: 200px;"
                      formControlName="gusId"
                      nzPlaceHolder="Grupo"
                      name="gusId">
                      <nz-option
                      *ngFor="let items of grupousuario" [nzValue]="items.id"
                          [nzLabel]="items.gusNome">
                      </nz-option>
                      <ng-template #erroEmail let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            Campo obrigatório<br>
                        </ng-container>
                    </ng-template>
                  </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="5">Lojas</nz-form-label>
              <nz-form-control [nzSpan]="12">
                  <div *ngFor="let items of lojas">
                      <input type="checkbox" nz-input [formControlName]="items.name" /> {{ items.lojNomeFantasia }}
                  </div>
              </nz-form-control>
          </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="5">Setores</nz-form-label>
              <nz-form-control [nzSpan]="12">
                  <div *ngFor="let items of setores">
                      <input type="checkbox" nz-input [formControlName]="items.name" /> {{ items.depDescricao }}
                  </div>
              </nz-form-control>
          </nz-form-item>

            <p align="right">
                <button class="form-button" nz-button nzType="link" (click)="cancelar()">Cancelar</button>
                <button class="form-button" [disabled]="!form.valid" nz-button nzType="primary" (click)="salvarUsuario()">Gravar</button>
            </p>
        </form>
    </ng-container>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

</div>
