import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GrupoUsuario } from '@models/grupousuario';

const baseURLEmpresa = `${environment.api_url}/empresa/v1/grupo-usuario`;

@Injectable({
  providedIn: 'root'
})
export class GrupoUsuarioService {

  constructor(private httpClient: HttpClient) { }

  listAll(): Observable<GrupoUsuario[]> {
    return this.httpClient.get<GrupoUsuario[]>(`${baseURLEmpresa}/lista`);
  }

  read(id: string): Observable<GrupoUsuario> {
    return this.httpClient.get<GrupoUsuario>(`${baseURLEmpresa}/recupera/${id}`);
  }

  create(data: Omit<GrupoUsuario, 'id'>): Observable<any> {
    return this.httpClient.post(`${baseURLEmpresa}/novo`, data);
  }

  update(data: Partial<GrupoUsuario>): Observable<any> {
    return this.httpClient.put(`${baseURLEmpresa}/atualiza`, data);
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${baseURLEmpresa}/remove/${id}`);
  }
}
