<header class="menu menu--fixed">
  <div class="container menu__content">
    <p class="menu__brand">
      <span class="menu__brand-secondary">inPrice - </span>
      <span class="menu__brand-primary">InCart</span>
    </p>
    <nav (click)="toggleMenu()" class="menu__nav hamburguer" [ngClass]="{'menu__nav--open': menuIsOpen}">
      <!-- <ul class="menu__items">
        <li class="menu__item">
          <a href="#about" class="menu__link smooth-link">Sobre</a>
        </li>
        <li class="menu__item">
          <a href="#work" class="menu__link smooth-link">Como funciona</a>
        </li>
        <li class="menu__item">
          <a href="#plans" class="menu__link smooth-link">Planos</a>
        </li>
        <li class="menu__item">
          <a href="#contact" class="menu__link smooth-link">Fale Conosco</a>
        </li>
      </ul> -->
    </nav>
    <div class="menu__actions">
      <button class="button-encarte" routerLink="/login">Login</button>
    </div>
 </div>
</header>

<section class="hero">
  <div class="container hero__content">
    <div class="hero__desktop">
      <div class="hero__footer">
        <a routerLink="/registro" class="button-encarte smooth-link">Cadastrar Empresa</a>
      </div>
    </div>
    <div class="hero__mobile">
      <a routerLink="/registro" class="button-encarte smooth-link">Cadastrar Empresa</a>
    </div>
  </div>
</section>

<footer class="footer">
  <div class="container footer__content">
    <p class="footer__title">inPrice - inCart</p>
    <p class="footer__description">Copyright © {{ currentYear }} inPrice - inCart</p>
    <p class="footer__description">Todos os direitos reservados.</p>
  </div>
</footer>
