<div #customLoadingTemplate>
    <nz-page-header class="site-page-header" nzTitle="Alterar" nzSubtitle="Layout">
    </nz-page-header>
    <ng-container class="edit-form">
        <form nz-form [formGroup]="layoutForm">

          <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
            <nz-form-label [nzSpan]="5">Descrição</nz-form-label>
                <nz-form-control  [nzSpan]="20" >
                     <input class="inputDescricao" nz-input type="text" formControlName="layDescricao" name="layDescricao" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
                <nz-form-label [nzSpan]="5">Midia</nz-form-label>
                      <nz-form-control [nzSpan]="20" >
                        <nz-select
                            formControlName="slaId"
                            nzPlaceHolder="Tipo Midia"
                            style="width:50%"
                            name="slaId">
                            <nz-option
                            *ngFor="let items of tipoMidia" [nzValue]="items.id"
                                [nzLabel]="items.descricao">
                            </nz-option>
                        </nz-select>
                      </nz-form-control>
                  </nz-form-item>
            <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
              <nz-form-label [nzSpan]="5">Altura</nz-form-label>
                <nz-form-control [nzSpan]="20" >
                    <input class="inputAltura"  nz-input type="number" formControlName="layAltura" name="layAltura" /> {{layoutForm.get("slaId").value == 1 ? "mm" : "px"}}
                </nz-form-control>
          </nz-form-item>

              <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
                <nz-form-label [nzSpan]="5">Empresa</nz-form-label>
                      <nz-form-control [nzSpan]="20" >
                        <nz-select
                            formControlName="empId"
                            nzPlaceHolder="Empresa"
                            style="width:50%"
                            name="empId">
                            <nz-option
                            *ngFor="let items of empresas" [nzValue]="items.id"
                                [nzLabel]="items.empNomeFantasia">
                            </nz-option>
                        </nz-select>
                      </nz-form-control>
                  </nz-form-item>

              <nz-form-item nz-row [nzGutter]="24" class="flexDisplay">
                <nz-form-label [nzSpan]="5">Imagem exemplo</nz-form-label>
                <nz-form-control [nzSpan]="20">
                        <ng-container>
                            <img
                                *ngIf="layoutForm.value.layExemplo"
                                [src]="layoutForm.value.layExemplo"
                                width="auto"
                                height="80px" />
                        </ng-container>
                        <ng-template #elseTemplate>
                            <img src="" class="img-responsive">
                        </ng-template>
                        <input
                        style="margin-bottom: 1rem; border-style:none; display:none"
                        type="file"
                        (change)="fileChangeEvent($event.target.files)"
                        id="img"
                        class="custom-file-input">
                        <label class="labelImage" nz-button nzType="primary" for="img">Nova Imagem</label>
                  </nz-form-control>
                  <span>
                      <b>Dica: </b>Utilize imagem com fundo <b>branco ou transparente</b>.
                      A imagem deve ter as mesmas dimensões de altura e largura (<b>quadrada</b>).
                  </span>
                  <span style="color:#ff4d4f;width:80%">{{ imageError }}</span>
            </nz-form-item>


            <div *ngIf="combinacaoArray.controls.length > 0" style="background: #ECECEC;padding:8px;">

                <div *ngFor="let combinacao of combinacaoArray.controls; let i = index;" formArrayName="combinacoes">
                  <nz-card [formGroupName]='i'>

                      <div class="flexDisplay centralizaVertical">

                          <div class="containerOrder" >
                            <div class="containerOrder">
                                <nz-form-item>
                                    <nz-form-control [nzSpan]="24">
                                        Quantidade de Itens: <input nz-input type="number" formControlName="comQuantidadeItem" name="comQuantidadeItem" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                            <button nz-button nzType="link" style="color:red"
                                (click)="deleteCombinacao(i)">Remover Combinação
                            </button>


                          </div>
                      </div>
                      <div
                      class="containerDaAreaComFolhetoExemplo"
                  >
                      <div
                          style="background: #ECECEC;padding:30px;"
                      >
                          <nz-card>

                              <div class="flexDisplay">

                                  <div class="containerTextArea" nz-tooltip nzTooltipTitle="HTML">
                                    Html: <textarea class="textAreaHtml" nz-input type="text" formControlName="comHtml" name="comHtml"  nzMentionTrigger></textarea>
                                  </div>

                              </div>

                          </nz-card>
                      </div>

                  </div>

                  </nz-card>
                </div>
            </div>
            <p></p>
            <p align="center">
              <button nz-button nzType="secondary" (click)="addCombinacao()">Adicionar Combinação</button>
            </p>

            <p align="right">
                <button class="form-button" nz-button nzType="link" (click)="cancelar()">Cancelar</button>
                <button class="form-button" [disabled]="!layoutForm.valid" nz-button nzType="primary" (click)="salvarLayout()">Salvar</button>
            </p>
        </form>
        <nz-result
            *ngIf="sucesso"
            nzStatus="success"
            nzTitle="Senha atualizada com sucesso"
        >
        </nz-result>

    </ng-container>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

</div>
