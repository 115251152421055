import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {


  public passwordError:string;
  public sucesso:boolean = false;
  public loading:boolean = false;
 
  constructor(private loginService: LoginService, private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
  }


  atualiza(): void {

    var senhaAtual = this.form.get('senhaAtual').value;
    var novaSenha = this.form.get('password').value;


    this.loginService.changePassword(senhaAtual, novaSenha)
      .subscribe(
        response => {
          this.sucesso = true;
        },
        response_error => {
          this.passwordError = response_error.error.mensagem;
        });
  }

  confirmationValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null && this.form.get('password2').value !== this.form.get('password').value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  complexityValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (this.form != null) {
      var password = this.form.get('password').value;
      var hasLength = (password != null && password.length > 7);
      var hasUpperCase = /[A-Z]/.test(password);
      var hasLowerCase = /[a-z]/.test(password);
      var hasNumbers = /\d/.test(password);
      //var hasNonalphas = /\W/.test(password);
      if (!hasUpperCase || !hasLowerCase || !hasNumbers || !hasLength)
        return { complexity: true, error: true };
      }
      return {};
  };


  form = new UntypedFormGroup({
    senhaAtual: new UntypedFormControl(null, [Validators.required]),
    password: new UntypedFormControl(null, [Validators.required, this.complexityValidator]),
    password2: new UntypedFormControl(null, [Validators.required, this.confirmationValidator])
  });

}
