<div>
    <nz-page-header class="site-page-header" nzTitle="Empresa" nzSubtitle="Liberação de empresas">
    </nz-page-header>

    <ng-container class="edit-form"  #customLoadingTemplate>
        <form nz-form [formGroup]="form">
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Razão Social</nz-form-label>
                <nz-form-control [nzSpan]="20">
                    {{ empRazaoSocial }}
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Nome Fantasia</nz-form-label>
                <nz-form-control [nzSpan]="20">
                    {{ empNomeFantasia }}
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">CNPJ</nz-form-label>
                <nz-form-control [nzSpan]="20">
                    {{ empCNPJMatriz }}
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Endereço</nz-form-label>
                <nz-form-control [nzSpan]="20">
                    {{ empRua }}, {{ empNumeroMatriz }}<br>
                    {{ empComplementoMatriz }}<br>
                    {{ logId }}<br>
                    {{ logCidade }} - {{ logUF }}
            </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Telefone</nz-form-label>
                <nz-form-control [nzSpan]="20">
                    ({{ empDDDMatriz }}) {{ empNumTelefoneMatriz }}
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-label [nzSpan]="5">Status</nz-form-label>
                <nz-form-control [nzSpan]="20">
                    {{ empStatus }}
                </nz-form-control>
            </nz-form-item>

            <nz-form-item [nzGutter]="24">
                <nz-form-label [nzSpan]="5">Login Simplus</nz-form-label>
                <nz-form-control [nzSpan]="10" [nzErrorTip]="erroCmpNome">
                    <input nz-input formControlName="empLoginSimplus" name="empLoginSimplus" class="input-field" 
                      size="45" />
                    <ng-template #erroCmpNome let-control>
                      <ng-container *ngIf="control.hasError('maxlength')">
                        Esse campo não permite mais que 128 caracteres<br>
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
            </nz-form-item>
            <nz-form-item [nzGutter]="24">
                <nz-form-label [nzSpan]="5">Senha Simplus</nz-form-label>
                <nz-form-control  [nzSpan]="10" [nzErrorTip]="erroCmpNome">
                    <input nz-input formControlName="empSenhaSimplus" name="empSenhaSimplus" class="input-field" 
                      size="45" />
                    <ng-template #erroCmpNome let-control>
                      <ng-container *ngIf="control.hasError('maxlength')">
                        Esse campo não permite mais que 128 caracteres<br>
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
            </nz-form-item>


            <p align="right">
                <button class="form-button" nz-button nzType="link" (click)="cancelar()">Cancelar</button>&nbsp;
                <button *ngIf="empStatus != 'I'"  class="form-button" nz-button nzType="secondary" (click)="inativarEmpresa()">Inativar</button>&nbsp;
                <button *ngIf="empStatus != 'A'"  class="form-button" nz-button nzType="primary" (click)="ativarEmpresa()">Ativar</button>
                <button class="form-button" nz-button nzType="primary" (click)="salvar()">Salvar</button>&nbsp;
            </p>
        </form>
    </ng-container>
    <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '3px' }"></ngx-loading>

</div>
