import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of  } from 'rxjs';
import { map  } from 'rxjs/operators';

import { environment } from '../../environments/environment';


const baseURL = `${environment.api_url}`;

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  private currentUserSubject: string;
  private currentUserRole: Array<string>;
  private userLoggedIn: boolean = false;

  constructor(private http: HttpClient) {
      this.currentUserSubject = sessionStorage.getItem('currentUser');
      this.currentUserRole = JSON.parse(sessionStorage.getItem('role'));
      this.userLoggedIn = sessionStorage.getItem('access_token') != null;
  }

  public get currentUserValue(): string {
    return this.currentUserSubject;
  }

  public get currentRole(): Array<string> {
    return this.currentUserRole;
  }

  public get isLoggedIn(): boolean {
    return this.userLoggedIn;
  }


  login(username, password) {

    var data = {
      username: username,
      password: password
    };

    return this.http.post<any>(`${environment.api_url}/login`, data, {observe: 'response'}).pipe(map( (res:any) => {

        sessionStorage.setItem('access_token',res.headers.get('Authorization'));
        sessionStorage.setItem('refresh_token',res.headers.get('Refresh'));
        sessionStorage.setItem('currentUser', res.body.name);
        sessionStorage.setItem('role', JSON.stringify(res.body.roles));
        this.currentUserSubject = sessionStorage.getItem('currentUser');
        this.currentUserRole = JSON.parse(sessionStorage.getItem('role'));
        this.userLoggedIn = true;
    }));

  }

  logout() {
      // remove user from local storage to log user out
      sessionStorage.removeItem('role');
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('refresh_token');
      this.currentUserSubject= null;
      this.userLoggedIn = false;
  }

  forgotPassword(username): Observable<any> {
    var data = {
      username: username
    };
    return this.http.post(`${baseURL}/forgot`, data);
  }

  resetPassword(token, password): Observable<any> {
    var data = {
      password: password
    };
    return this.http.post(`${baseURL}/reset/` + token, data);
  }

  changePassword(password, newPassword): Observable<any> {
    var data = {
      password: password,
      newPassword: newPassword
    };
    return this.http.post(`${baseURL}/updatePassword`, data);
  }


}
