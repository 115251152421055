import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Campanha } from '@models/campanha';
import { PendenciaCampanha } from '@models/pendenciacampanha';

const baseURLEmpresa = `${environment.api_url}/empresa/v1/campanha`;

@Injectable({
  providedIn: 'root'
})
export class CampanhaService {

  constructor(private httpClient: HttpClient) { }

  listAll(): Observable<Campanha[]> {
    return this.httpClient.get<Campanha[]>(`${baseURLEmpresa}/lista`);
  }
  listArquivado(): Observable<Campanha[]> {
    return this.httpClient.get<Campanha[]>(`${baseURLEmpresa}/lista-arquivado`);
  }

  read(id: string): Observable<Campanha> {
    return this.httpClient.get<Campanha>(`${baseURLEmpresa}/recupera/${id}`);
  }

  create(data: Omit<Campanha, 'id'>): Observable<any> {
    return this.httpClient.post(`${baseURLEmpresa}/novo`, data);
  }

  update(data: Partial<Campanha>): Observable<any> {
    return this.httpClient.put(`${baseURLEmpresa}/atualiza`, data);
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${baseURLEmpresa}/remove/${id}`);
  }

  listPending(id: string): Observable<PendenciaCampanha[]> {
    return this.httpClient.get<PendenciaCampanha[]>(`${baseURLEmpresa}/pendencia/${id}`);
  }

  arquivar(id: string): Observable<any> {
    return this.httpClient.put(`${baseURLEmpresa}/arquivar/${id}`,null);
  }


}
