import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { TipoPagina } from '@models/tipoPagina';

const baseURLEmpresa = `${environment.api_url}/empresa/v1/tipopagina`;
const baseURLAdmin = `${environment.api_url}/admin/empresa/v1/tipopagina`;

@Injectable({
  providedIn: 'root'
})
export class TipoPaginaService {
  
  constructor(private http: HttpClient){}

  listAll(): Observable<TipoPagina[]> {
    return this.http.get<TipoPagina[]>(`${baseURLEmpresa}/lista`);
  }

  read(id: string): Observable<TipoPagina> {
    return this.http.get<TipoPagina>(`${baseURLEmpresa}/recupera/${id}`);
  }

  listAllAdm(): Observable<TipoPagina[]> {
    return this.http.get<TipoPagina[]>(`${baseURLAdmin}/lista`);
  }

  readAdm(id: string): Observable<TipoPagina> {
    return this.http.get<TipoPagina>(`${baseURLAdmin}/recupera/${id}`);
  }

  createAdm(data: Omit<TipoPagina, 'id'>): Observable<any> {
    return this.http.post(`${baseURLAdmin}/novo`, data);
  }

  updateAdm(data: Partial<TipoPagina>): Observable<any> {
    return this.http.put(`${baseURLAdmin}/atualiza`, data);
  }

  deleteAdm(id: string): Observable<any> {
    return this.http.delete(`${baseURLAdmin}/remove/` + id);
  }
}
